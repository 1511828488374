import { IconContainer } from './IconContainer';

export const IconLibArticles = () => (
  <IconContainer bgColor="#fe871a">
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14" height="14">
      <g>
        <defs>
          <path
            d="M 2.8 2.8 C 2.8 2.027 3.427 1.4 4.2 1.4 L 7.41 1.4 C 7.781 1.4 8.138 1.548 8.4 1.81 L 10.79 4.2 C 11.052 4.462 11.2 4.819 11.2 5.19 L 11.2 11.2 C 11.2 11.973 10.573 12.6 9.8 12.6 L 4.2 12.6 C 3.427 12.6 2.8 11.973 2.8 11.2 Z M 4.9 7.7 L 9.1 7.7 C 9.487 7.7 9.8 7.387 9.8 7 C 9.8 6.613 9.487 6.3 9.1 6.3 L 4.9 6.3 C 4.513 6.3 4.2 6.613 4.2 7 C 4.2 7.387 4.513 7.7 4.9 7.7 Z M 4.9 9.1 C 4.513 9.1 4.2 9.413 4.2 9.8 C 4.2 10.187 4.513 10.5 4.9 10.5 L 9.1 10.5 C 9.487 10.5 9.8 10.187 9.8 9.8 C 9.8 9.413 9.487 9.1 9.1 9.1 Z"
            id="a1027z"></path>
          <filter id="a1029z" x="-49.4%" y="-46.0%" width="197.6%" height="191.2%" filterUnits="objectBoundingBox">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="a1031z"></feOffset>
            <feGaussianBlur stdDeviation="1" in="a1031z" result="a1032z"></feGaussianBlur>
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
              type="matrix"
              in="a1032z"
              result="a1033z"></feColorMatrix>
          </filter>
        </defs>
        <g filter="url(#a1029z)">
          <use
            fill="black"
            fillOpacity="1"
            stroke="black"
            strokeOpacity="0"
            strokeWidth="0"
            xlinkHref="#a1027z"
            clipPath="url(#a1028z)"></use>
        </g>
        <use xlinkHref="#a1027z" fill="rgb(255, 255, 255)" clipPath="url(#a1028z)"></use>
      </g>
    </svg>
  </IconContainer>
);
