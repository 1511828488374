import { SVGProps } from 'react';

export function IconHashtag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="1 1 10 10" width={12} height={12} {...props}>
      <defs>
        <filter id="a" width="185.6%" height="221%" x="-43.3%" y="-61.1%" filterUnits="objectBoundingBox">
          <feOffset dy={1} in="SourceAlpha" result="a1004z" />
          <feGaussianBlur in="a1004z" result="a1005z" stdDeviation={1} />
          <feColorMatrix
            colorInterpolationFilters="sRGB"
            in="a1005z"
            result="a1006z"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
        </filter>
        <path
          id="b"
          d="M5.546 1.818a.6.6 0 0 1 .436.728L5.718 3.6h1.764l.336-1.346a.602.602 0 0 1 1.001-.288c.155.15.217.371.163.58L8.718 3.6H10.2a.6.6 0 0 1 0 1.2H8.418l-.6 2.4H9a.6.6 0 0 1 0 1.2H7.518l-.336 1.345a.601.601 0 0 1-1.004.294.601.601 0 0 1-.16-.585L6.282 8.4H4.518l-.336 1.345a.601.601 0 0 1-1.004.294.601.601 0 0 1-.16-.585L3.282 8.4H1.8a.6.6 0 0 1 0-1.2h1.782l.6-2.4H3a.6.6 0 0 1 0-1.2h1.482l.336-1.346a.6.6 0 0 1 .728-.436ZM5.418 4.8l-.6 2.4h1.763l.6-2.4H5.419Z"
        />
      </defs>
      <g filter="url(#a)">
        <use xlinkHref="#b" stroke="#000" strokeOpacity={0} strokeWidth={0} clipPath="url(#a1001z)" />
      </g>
      <use xlinkHref="#b" fill="#FFF" clipPath="url(#a1001z)" />
    </svg>
  );
}
