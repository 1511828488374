import { Checkbox, Input, Popover, Skeleton } from '@layerise/design-core';
import { IconPlus } from '@layerise/design-icon';
import { useContext, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Tag } from './TagList';
import { useMutation, useQuery } from '@apollo/client';
import { CreateTagDocument, SearchTagsDocument } from 'types/typed-document-nodes';
import ToastContext from 'contexts/ToastContext';
import { OrganisationContext } from 'contexts/OrganisationContext';

const TriggerContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 499;
  color: var(--color-N600);
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: left;
  margin-top: 0px;
  margin-left: 0;
  height: 23px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 1px 1px 0px rgba(0, 0, 0, 0.12),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 2px 5px 0px rgba(60, 66, 87, 0.08);
  border-radius: 50%;
  margin-top: 1px;
`;

const ContentContainer = styled.div`
  margin-left: 8px;
  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12), 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  overflow: hidden;
  min-width: 240px;
  margin-bottom: 5px;
`;

const InputContainer = styled.div`
  input {
    background-color: #fff;
    border: none;
    height: 32px;
    color: #140068;
    font-size: 14px;
    box-shadow: none;

    &:focus,
    &:active {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
`;

const TagList = styled.div`
  max-height: 530px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListItem = styled.div`
  padding: 9px 12px;
  color: #140068;
  font-weight: 499;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 14px;
  height: 32px;

  &:hover {
    background-color: #f7f7fa;
  }

  & label {
    font-weight: 499;
    color: #140068;
    font-size: 14px;

    & div {
      height: 14px;
      width: 14px;
      min-height: 14px;
      min-width: 14px;
      border-radius: 3px;
    }
  }
`;

const CreateListItem = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  background-color: #f7f7fa;
  padding: 12px 16px;
  color: #140068;
  font-weight: 499;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 14px;
  height: 32px;
  ${props =>
    !props.disabled &&
    css`
      cursor: pointer;
      &:hover {
        background-color: #efefef;
      }
    `};
  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
    `};
`;

const CreateListItemIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

const NewTagText = styled.div`
  margin-left: 8px;
  opacity: 0.54;
`;

const SkeletonContainer = styled.div`
  padding: 80px;
`;

type closeRef = { togglePopover(): void };

export type AddTagDirection = 'left' | 'right';

interface AddTagProps {
  existingTagIds: string[];
  tags: Tag[];
  direction?: AddTagDirection;
  onAddTags: (tags: Tag[]) => void;
}

const TAG_NAME_MAX_LENGTH = 80;

export const AddTag = ({ tags, direction, onAddTags, existingTagIds }: AddTagProps) => {
  const { workspace } = useContext(OrganisationContext);
  const closeRef = useRef<closeRef>(null);
  const [search, setSearch] = useState('');
  const [createTag] = useMutation(CreateTagDocument);
  const { setToast } = useContext(ToastContext);
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const focusInput = () => {
    setTimeout(() => {
      inputContainerRef.current?.querySelector('input')?.focus();
    }, 0);
  };

  const tagName = useMemo(() => {
    if (search.trim().length === 0) {
      return '';
    }
    return (search.length > TAG_NAME_MAX_LENGTH ? search.substring(0, TAG_NAME_MAX_LENGTH) : search).trim();
  }, [search]);

  const {
    data,
    loading: isTagListLoading,
    refetch,
  } = useQuery(SearchTagsDocument, {
    variables: {
      workspaceId: workspace.id,
      existingTagIds,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const handleOnChangeSearch: React.ChangeEventHandler<HTMLInputElement> = e => {
    setSearch(e.target.value.substring(0, TAG_NAME_MAX_LENGTH));
  };

  const handleOnCreateItem = async () => {
    if (tagName.length === 0) return;
    const existingTagIndex = tags.findIndex(i => i.title === tagName);
    const newState = [...tags];
    if (existingTagIndex === -1) {
      try {
        const newTag = await createTag({
          variables: {
            title: search,
            workspaceId: workspace.id,
          },
        });

        newState.push({
          id: newTag?.data?.createTag?.id || '',
          title: newTag?.data?.createTag?.title || '',
          checked: true,
        });
        setSearch('');
        onAddTags(newState);
        refetch();
        focusInput();
      } catch (e) {
        setToast({ type: 'warning', message: 'Could not create tag' });
      }
    } else {
      newState.splice(existingTagIndex, 1);
      setSearch('');
      onAddTags(newState);
      focusInput();
    }
  };

  const handleOnChangeCheckbox = (item: Tag): React.ChangeEventHandler<HTMLInputElement> => {
    return () => {
      const foundIndex = tags.findIndex(tag => tag.id === item.id);
      if (foundIndex >= 0) {
        const newState = [...tags];
        newState.splice(foundIndex, 1);
        onAddTags(newState);
        return;
      }

      const newState: Tag[] = [
        ...tags,
        {
          checked: true,
          id: item.id,
          title: item.title,
        },
      ];
      onAddTags(newState);
    };
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleOnCreateItem();
    }
  };

  const normalizedTags = useMemo(() => {
    return (
      data?.tags
        .filter(i => (search.length > 0 ? i.title.toLowerCase().startsWith(search.trim().toLowerCase()) : true))
        .map(tag => {
          const checked = tags.some(existingTag => existingTag.id === tag.id);
          return {
            ...tag,
            checked,
          };
        }) || []
    );
  }, [data?.tags, tags, search]);

  return (
    <Popover
      key={isTagListLoading ? 'loading' : 'loaded'}
      autoWidth
      ref={closeRef}
      right={direction === 'right'}
      left={direction === 'left'}
      center={true}
      trigger={
        <TriggerContainer onClick={focusInput}>
          <IconContainer>
            <IconPlus height="10px" width="10px" />
          </IconContainer>
          <span>Add Tag</span>
        </TriggerContainer>
      }>
      <ContentContainer>
        <InputContainer ref={inputContainerRef}>
          <Input placeholder="Add tag..." value={search} onChange={handleOnChangeSearch} onKeyDown={onKeyDown} />
        </InputContainer>
        {isTagListLoading ? (
          <SkeletonContainer>
            <Skeleton />
          </SkeletonContainer>
        ) : (
          <>
            {normalizedTags.length > 0 && (
              <TagList>
                {normalizedTags.map(item => {
                  return (
                    <ListItem key={item.id}>
                      <Checkbox
                        height={14}
                        name={item.title}
                        label={item.title}
                        checked={item.checked}
                        onChange={handleOnChangeCheckbox(item)}
                      />
                    </ListItem>
                  );
                })}
              </TagList>
            )}
            {normalizedTags.length === 0 && tagName.length > 0 ? (
              <CreateListItem disabled={tagName.length === 0} onClick={handleOnCreateItem}>
                <CreateListItemIcon>
                  <IconPlus width="10px" height="10px" />
                </CreateListItemIcon>
                <span>Create New Tag:</span>
                <NewTagText>&quot;{tagName}&quot;</NewTagText>
              </CreateListItem>
            ) : null}
          </>
        )}
      </ContentContainer>
    </Popover>
  );
};
