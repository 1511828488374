import { useMobileFlag } from 'hooks/useMobileBreakpoint';

export const useAnimationSettings = ({
  expanded,
  open,
  width,
}: {
  expanded: boolean;
  open?: boolean;
  width?: number;
}) => {
  const mobile = useMobileFlag();
  const animateState = expanded ? 'expand' : open ? 'open' : 'closed';
  const variants = {
    open: {
      x: 0,
      boxShadow: '0px 0px 0px 1px rgba(1, 0, 50, 0.1), 0px 2px 15px 4px rgba(1, 0, 50, 0.2)',
      opacity: 1,
      zIndex: 1051,
      width: mobile ? '100%' : width || 304,
    },
    closed: {
      x: width || 304,
      opacity: 0,
      boxShadow: '0px 0px 0px 1px rgba(1, 0, 50, 0.0), 0px 2px 4px -2px rgba(1, 0, 50, 0.0)',
      zIndex: 0,
      width: width || 304,
    },
    expand: {
      width: width || 304,
      x: 0,
      boxShadow: '0px 0px 0px 1px rgba(1, 0, 50, 0.1), 0px 2px 15px 4px rgba(1, 0, 50, 0.2)',
      opacity: 1,
      zIndex: 1051,
    },
  };

  return {
    variants,
    animate: animateState,
    initial: 'closed',
    exit: 'closed',
    transition: { duration: 0.15, damping: 100 },
  };
};
