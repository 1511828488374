import { IconContainer } from './IconContainer';

export const IconLibStories = () => (
  <IconContainer bgColor="#fe871a">
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={12} height={12}>
      <defs>
        <filter id="a" x="-60.4%" y="-50.5%" width="219.3%" height="200%" filterUnits="objectBoundingBox">
          <feOffset dy={1} in="SourceAlpha" result="a1004z" />
          <feGaussianBlur stdDeviation={1} in="a1004z" result="a1005z" />
          <feColorMatrix
            colorInterpolationFilters="sRGB"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            in="a1005z"
            result="a1006z"
          />
        </filter>
        <path
          d="M3.428 1.2h5.144c.477 0 .807.293.852.743l.004.107v8.5c0 .474-.295.801-.75.845l-.106.005H3.428c-.477 0-.807-.293-.852-.743l-.004-.107v-8.5c0-.474.295-.801.75-.845l.106-.005h5.143-5.143Z"
          id="b-4eeded6c"
        />
      </defs>
      <g filter="url(#a)">
        <use stroke="#000" strokeOpacity={0} strokeWidth={0} xlinkHref="#b-4eeded6c" clipPath="url(#a1001z)" />
      </g>
      <use xlinkHref="#b-4eeded6c" fill="#FFF" clipPath="url(#a1001z)" />
      <defs>
        <filter id="c" x="-231.5%" y="-75.4%" width="557.5%" height="249.3%" filterUnits="objectBoundingBox">
          <feOffset dy={1} in="SourceAlpha" result="a1011z" />
          <feGaussianBlur stdDeviation={1} in="a1011z" result="a1012z" />
          <feColorMatrix
            colorInterpolationFilters="sRGB"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            in="a1012z"
            result="a1013z"
          />
        </filter>
        <path
          d="M1.372 2.9a.341.341 0 0 1 .342.34v6.12a.341.341 0 0 1-.342.34H.342A.342.342 0 0 1 0 9.36V3.24a.342.342 0 0 1 .343-.34Z"
          id="d-4eeded6c"
        />
      </defs>
      <g filter="url(#c)">
        <use stroke="#000" strokeOpacity={0} strokeWidth={0} xlinkHref="#d-4eeded6c" clipPath="url(#a1008z)" />
      </g>
      <use xlinkHref="#d-4eeded6c" fill="hsla(0, 0%, 100%, 0.5)" clipPath="url(#a1008z)" />
      <defs>
        <filter id="e" x="-231.5%" y="-75.4%" width="557.5%" height="249.3%" filterUnits="objectBoundingBox">
          <feOffset dy={1} in="SourceAlpha" result="a1018z" />
          <feGaussianBlur stdDeviation={1} in="a1018z" result="a1019z" />
          <feColorMatrix
            colorInterpolationFilters="sRGB"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            in="a1019z"
            result="a1020z"
          />
        </filter>
        <path
          d="M11.657 2.9a.342.342 0 0 1 .343.34v6.12a.342.342 0 0 1-.343.34h-1.029a.341.341 0 0 1-.342-.34V3.24a.341.341 0 0 1 .342-.34Z"
          id="f"
        />
      </defs>
      <g filter="url(#e)">
        <use stroke="#000" strokeOpacity={0} strokeWidth={0} xlinkHref="#f" clipPath="url(#a1015z)" />
      </g>
      <use xlinkHref="#f" fill="hsla(0, 0%, 100%, 0.5)" clipPath="url(#a1015z)" />
    </svg>
  </IconContainer>
);
