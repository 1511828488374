import { useContext, useMemo } from 'react';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { useQuery } from '@apollo/client';
import { SearchTagsDocument, SearchTagsQuery } from 'types/typed-document-nodes';

export const useWorkspaceTags = (skip?: boolean): SearchTagsQuery['tags'] => {
  const { workspace } = useContext(OrganisationContext);
  const { data } = useQuery(SearchTagsDocument, {
    variables: {
      workspaceId: workspace.id,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip,
  });

  return useMemo(() => {
    return data?.tags || [];
  }, [data?.tags]);
};

export const useTagsFilterOptions = (skip?: boolean) => {
  const tags = useWorkspaceTags(skip);

  return useMemo(() => {
    return tags.map(tag => ({ id: tag.id, title: tag.title }));
  }, [tags]);
};
