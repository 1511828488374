import styled from 'styled-components';
import { Avatar, Video, Img } from '@layerise/design-core';
import { formatDistance } from 'date-fns';
import { motion } from 'framer-motion';
import { formatSeconds } from '@layerise/utils';
import { Supported } from 'pages/w/[id]/library/media';
import { useGetItemMetaData } from 'components/detail-view/hooks/useGetItemMetaData';
import { TagList } from '../tag/TagList';
import React from 'react';

type Props = {
  item: Supported;
  useUpdateDate?: boolean;
};

const Container = styled(motion.div)`
  background-color: #fff;
  padding: 16px;
  border-radius: 11px;
  width: 320px;
  max-height: 320px;
  z-index: 200;
  overflow: hidden;
`;

const PreviewContainer = styled.div`
  margin-top: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-items: center;
  border-radius: 7px;
  height: 164px;
  width: 288px;
`;

const MediaTags = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 8px;
`;

const Title = styled.h6`
  font-size: 16px;
  font-weight: 700;
  margin-top: 12px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 288px;
`;
const Meta = styled.p`
  font-size: 12px;
  color: var(--color-N400);
`;

const AvatarContainer = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
  gap: 8px;
  cursor: pointer;
`;
const AvatarTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
`;
const UploadDate = styled.div`
  color: var(--color-N400);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
`;

const PreviewElement = styled.div`
  border-radius: 7px;
  height: 162px;
  width: 286px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  img {
    object-fit: contain;
  }
`;

export const Preview: React.FC<Props> = ({ item, useUpdateDate }) => {
  const { createdBy, updatedBy, size: formatWeight } = useGetItemMetaData(item);
  const formatDuration = item.__typename === 'Video' && formatSeconds(item.duration);
  const formatSize = 'height' in item && item.height && item.width && `${item.width} x ${item.height}`;
  const meta = [formatSize, formatWeight, formatDuration].filter(Boolean).join(' · ');
  const src = 'dynamic' in item && item.dynamic;

  const user = useUpdateDate && updatedBy ? updatedBy : createdBy;
  const date = useUpdateDate && item.updatedAt ? item.updatedAt : item.createdAt;

  return (
    <Container
      key={item.id}
      aria-label="Media preview"
      initial={{ opacity: 0.8, boxShadow: 'rgba(0,0,0,0) 0 0px 0px' }}
      animate={{ opacity: 1, boxShadow: 'rgba(0,0,0,.2) 0 20px 48px', transition: { duration: 0.1 } }}>
      <AvatarContainer>
        <Avatar
          standalone
          height={32}
          src={user.imgSrc || undefined}
          title={user.label}
          subTitle={formatDistance(new Date(date), new Date())}
        />
        <div>
          <AvatarTitle>{user.label}</AvatarTitle>
          <UploadDate>{formatDistance(new Date(date), new Date())}</UploadDate>
        </div>
      </AvatarContainer>

      <PreviewContainer>
        {src && (
          <PreviewElement>
            {item.__typename === 'Video' ? (
              <Video src={src} />
            ) : (
              <Img src={src} alt={('name' in item && item.name) || ''} />
            )}
          </PreviewElement>
        )}
      </PreviewContainer>
      <Title>{('title' in item && item.title) || ('name' in item && item.name)}</Title>
      {meta && <Meta>{meta}</Meta>}
      {!!item.metaInfo?.tags.length && (
        <MediaTags>
          <TagList wrap viewOnly tags={item.metaInfo.tags.map(tag => ({ ...tag, checked: true }))} />
        </MediaTags>
      )}
    </Container>
  );
};
