import styled from 'styled-components';
import { Tag } from '@layerise/design-core';
import { MouseEventHandler, forwardRef } from 'react';

const Container = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    padding-left: 4px;
    max-width: 200px;
    display: inline-block;
    line-height: 16px;

    svg {
      width: 12px;
      height: 12px;
      margin: 0 4px 0 0;
    }
  }
`;

export type Props = {
  name: string;
  bgColor: string;
  color?: string;
  icon: JSX.Element;
  className?: string;
  onClick?: MouseEventHandler;
};

export const EntityTag = forwardRef<HTMLDivElement, Props>(function EntityTag(
  { name, icon, bgColor, color, className, onClick },
  ref
) {
  return (
    <Container title={name.length > 25 ? name : undefined} ref={ref} className={className} onClick={onClick}>
      <Tag variant="custom" fontColor={color} bgColor={bgColor}>
        {icon}
        {name}
      </Tag>
    </Container>
  );
});
