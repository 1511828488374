import { useContext } from 'react';
import { makeVar, useQuery, useReactiveVar } from '@apollo/client';
import ToastContext from 'contexts/ToastContext';
import { SerialNumberImportStatus, SerialNumberImportStatusDocument } from 'types/typed-document-nodes';

type QueueItem = {
  id: string;
  callback?(): void;
};

export const processQueue = makeVar<QueueItem[]>([]);

export function useSerialNumbersUploadNotifications() {
  const { setToast } = useContext(ToastContext);
  const queue = useReactiveVar(processQueue);
  const importIds = queue.map(i => i.id);

  useQuery(SerialNumberImportStatusDocument, {
    skip: importIds.length === 0,
    pollInterval: 1500,
    variables: {
      ids: importIds,
    },
    onCompleted(data) {
      const imports = data?.serialNumberImport;
      imports.forEach(i => {
        if (i.status === SerialNumberImportStatus.Success) {
          setToast({
            type: 'success',
            message: i.processed > 0 ? `${i.processed} serial numbers added` : 'No serial numbers added',
            timeout: 9999999999,
          });
        } else if (i.status === SerialNumberImportStatus.Error) {
          setToast({
            type: 'warning',
            message: 'Error occured while adding serial numbers',
            timeout: 9999999999,
          });
        }

        const queueItem = queue.find(q => q.id === i.id);
        if (queueItem?.callback) {
          queueItem.callback();
        }

        if (i.status !== SerialNumberImportStatus.InProgress) {
          processQueue(queue.filter(q => q.id !== i.id));
        }
      });
    },
  });
}
