import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { CountriesDocument, CountriesQuery, CountryOrderByInput } from 'types/typed-document-nodes';

export const useCountries = (): CountriesQuery['countries'] => {
  const { data } = useQuery(CountriesDocument, {
    variables: {
      orderBy: CountryOrderByInput.NameEnAsc,
    },
  });

  return useMemo(() => {
    return data?.countries || [];
  }, [data?.countries]);
};

export const useCountriesFilterOptions = () => {
  const countries = useCountries();

  return useMemo(() => {
    return countries?.map(country => ({
      id: country.id,
      title: country.nameEN,
    }));
  }, [countries]);
};
