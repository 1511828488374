import { MouseEventHandler, PropsWithChildren, ReactNode, useState } from 'react';
import styled from 'styled-components';

const Row = styled.tr<{
  $selected?: boolean;
  $disabled?: boolean;
  $noHover?: boolean;
}>`
  vertical-align: middle;
  position: relative;
  background-color: ${props => props.$selected && 'var(--color-B050)'};

  &:hover {
    cursor: ${props => !props.$disabled && !props.$noHover && 'pointer'};
    background-color: ${props => !props.$disabled && !props.$noHover && '#F7F7FE'};
  }

  &:last-child {
    border-bottom: none;

    td:first-child {
      border-radius: 0 0 0 7px;
    }
    td:last-child {
      border-radius: 0 0 7px 0;
    }

    td {
      border-bottom: none !important;
    }
  }

  td {
    word-break: break-word;
    color: ${props => props.$selected && 'var(--color-B500)'};
    opacity: ${props => (props.$disabled ? 0.4 : 1)};
  }
`;

const TooltipContainer = styled.div`
  max-width: 264px;
  display: block;
  box-shadow: 0px 4px 12px -2px rgba(1, 0, 50, 0.35), 0px 0px 0px 1px rgba(1, 0, 50, 0.1);
  background-color: #140038;
  position: absolute;
  border-radius: 7px;
  z-index: 1;
  padding: 13px 10px;
  width: 244px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0em;
  line-height: 17px;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;

  &,
  a {
    color: #ffffff;
  }
`;

type Props = {
  tooltip?: ReactNode;
  disabled?: boolean;
  selected?: boolean;
  noHover?: boolean;
  onClick?: MouseEventHandler;
};

export function TableBodyRow({ tooltip, disabled, selected, noHover, onClick, children }: PropsWithChildren<Props>) {
  const [showTooltip, setShowTooltip] = useState<{ x: number; y: number } | null>(null);
  return (
    <Row
      onMouseEnter={e => {
        const elementRect = e.currentTarget.getBoundingClientRect();
        setShowTooltip({
          x: Math.max(e.clientX - elementRect.left, 0),
          y: Math.max(e.clientY - elementRect.top, 0),
        });
      }}
      onMouseLeave={() => {
        setShowTooltip(null);
      }}
      onClick={onClick}
      $disabled={disabled}
      $selected={selected}
      $noHover={noHover}>
      {children}
      <TooltipContainer
        style={{ display: showTooltip && tooltip ? 'block' : 'none', left: showTooltip?.x, top: showTooltip?.y }}>
        {tooltip}
      </TooltipContainer>
    </Row>
  );
}
