import React from 'react';
import styled from 'styled-components';
import ItemColumn from './ItemColumn';
import { Skeleton } from '@layerise/design-core';
import { ConnectionsButton } from 'components/connections/ConnectionsButton';
import { WorkspaceFragment } from '../hooks/useData';
import { formatUpdatedBy } from 'lib/utils';
import { WorkspaceIcon } from 'components/navigation/sidebar/minibar/WorkspaceIcon';

interface ColumnProps {
  item: WorkspaceFragment;
  onClick: (itemId: string) => void;
  openConnectionsSidebar: React.Dispatch<React.SetStateAction<string>>;
}

const ImageContainer = styled.div`
  margin-right: 8px;
`;

export const workspacesLoadingView = [
  {
    id: 'skeleton',
    columns: [
      {
        id: '1',
        content: <Skeleton />,
      },
      {
        id: '2',
        content: <Skeleton />,
      },
      {
        id: '3',
        content: <Skeleton />,
      },
      {
        id: '4',
        content: <Skeleton />,
      },
    ],
  },
];

export const workspacesHeaderItems = [
  {
    id: '1',
    title: 'name',
  },
  {
    id: '2',
    title: 'products',
  },
  {
    id: '3',
    title: 'slug',
  },
  {
    id: '4',
    title: 'created',
  },
];

export const workspacesColumns = ({ item, onClick, openConnectionsSidebar }: ColumnProps) => {
  return [
    {
      id: '1',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          <ImageContainer>
            <WorkspaceIcon noBorder shadow={false} workspace={item} borderRadius={3} charCount={2} />
          </ImageContainer>
          {item.name}
        </ItemColumn>
      ),
      fontWeight: 600,
      fontColor: 'var(--color-N600)',
      fontSize: 14,
    },
    {
      id: '6',
      content: (
        <ConnectionsButton
          number={item.totalProducts || 0}
          onClick={() => {
            if (item.slug) {
              openConnectionsSidebar(prev => {
                return prev === item.id ? '' : item.id || '';
              });
            }
          }}
        />
      ),
    },
    {
      id: '7',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          {item.slug}
        </ItemColumn>
      ),
    },
    {
      id: '8',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          {formatUpdatedBy([], item.createdAt)}
        </ItemColumn>
      ),
    },
  ];
};
