import { useContext, useMemo } from 'react';
import { AssistantsListDocument, AssistantsListQuery } from 'types/typed-document-nodes';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { useQuery } from '@apollo/client';

type Assistant = NonNullable<AssistantsListQuery['assistants']>[number];

export const useWorkspaceAssistants = (): Assistant[] => {
  const { workspace } = useContext(OrganisationContext);

  const assistantsQuery = useQuery(AssistantsListDocument, {
    variables: {
      workspaceId: workspace.id,
    },
  });

  return assistantsQuery.data?.assistants || [];
};

export const useAssistantFilterOptions = () => {
  const assistants = useWorkspaceAssistants();

  return useMemo(() => {
    return assistants.map(assistant => ({
      id: assistant.id,
      title: assistant.name,
    }));
  }, [assistants]);
};
