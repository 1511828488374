import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  SearchProductCategoryDocument,
  SearchProductCategoryQuery,
  SearchProductCategoryQueryVariables,
} from 'types/typed-document-nodes';
import { isPresent } from 'ts-is-present';

type ProductCategory = NonNullable<SearchProductCategoryQuery['productCategories'][number]>;

export const useProductCategories = (
  options?: SearchProductCategoryQueryVariables['where'],
  skip?: boolean
): [ProductCategory[], { loading: boolean }] => {
  const { data, loading } = useQuery(SearchProductCategoryDocument, {
    variables: {
      where: options,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip,
  });

  return useMemo(() => {
    return [data?.productCategories.filter(isPresent) ?? [], { loading }];
  }, [data?.productCategories, loading]);
};

export const useProductCategoryFilterOptions = (skip?: boolean) => {
  const [categories] = useProductCategories(null, skip);

  return useMemo(() => {
    return categories.map(c => ({
      id: c.id,
      title: c.title,
    }));
  }, [categories]);
};
