import LibraryItemsContainer, {
  AllowedLibraryPickerItemType,
  LibraryItemsContainerProps,
} from './LibraryItemsContainer';
import { ModalContainer } from '../components/ModalContainer';
import { ITEMS_PER_PAGE } from '../constants';

export const LibraryPickerModal = <T extends AllowedLibraryPickerItemType | AllowedLibraryPickerItemType[]>({
  type,
  onRequestClose,
  selectedItems = [],
  multi,
  emptySelectionAllowed,
  ctaButtonLabel,
  onSubmit,
  onItemsSelect,
  modalProps,
}: Omit<LibraryItemsContainerProps<T>, 'itemsPerPage' | 'onItemsSelect'> & {
  modalProps?: { onRequestClose(): void };
  onItemsSelect?: LibraryItemsContainerProps<T>['onItemsSelect'];
  onSubmit?(item: Parameters<LibraryItemsContainerProps<T>['onItemsSelect']>[0][number]): void;
}) => (
  <ModalContainer onRequestClose={onRequestClose}>
    <LibraryItemsContainer
      type={type}
      multi={multi}
      itemsPerPage={ITEMS_PER_PAGE}
      selectedItems={selectedItems}
      emptySelectionAllowed={emptySelectionAllowed}
      ctaButtonLabel={ctaButtonLabel}
      onRequestClose={onRequestClose || modalProps?.onRequestClose}
      onItemsSelect={items => {
        const result = onItemsSelect?.(items);
        onSubmit?.(items[0]);
        return result;
      }}
    />
  </ModalContainer>
);
