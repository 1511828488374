import { Button, ModalActions } from '@layerise/design-core';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { ToastContext } from 'contexts/ToastContext';
import { EmptyTable } from 'components/emptyStates';
import { IconEmptyAssistantsList } from 'components/icons/IconEmptyAssistantsList';
import Filter from '../components/Filter';
import { ConnectionsSidebar } from 'components/connections/ConnectionsSidebar';
import { ConnectionList } from 'components/connections/ConnectionList';
import { ContentItemType } from 'components/connections/ConnectionHeader';
import { FilterType, getLibraryItemTitleByType, isMediaType, LibraryItemsType, useLibraryData } from '../hooks/useData';
import { FooterWrapper, Hero, ModalTitle, SelectedItemsCount, TableContainer } from '../components/styles';
import { capitalize } from '@layerise/utils';
import TableWrapper from '../components/TableWrapper';
import { LibraryItemType } from 'types/typed-document-nodes';
import { TableFooter } from 'components/table/TableFooter';

type ItemName = { name: string };
export type AllowedLibraryPickerItemType = Exclude<LibraryItemType, LibraryItemType.Assistant>;

export interface LibraryItemsContainerProps<T extends AllowedLibraryPickerItemType | AllowedLibraryPickerItemType[]> {
  type: T;
  selectedItems?: LibraryItemsType[];
  itemsPerPage: number;
  multi?: boolean;
  emptySelectionAllowed?: boolean;
  ctaButtonLabel?: string;
  onRequestClose(): void;
  onItemsSelect(
    items: T extends AllowedLibraryPickerItemType[]
      ? LibraryItemsType[]
      : Array<
          T extends LibraryItemType.Story
            ? Extract<LibraryItemsType, { __typename: 'Story' }> & ItemName
            : T extends LibraryItemType.Article
            ? Extract<LibraryItemsType, { __typename: 'Article' }> & ItemName
            : T extends LibraryItemType.Image
            ? Extract<LibraryItemsType, { __typename: 'Image' }> & ItemName
            : T extends LibraryItemType.Video
            ? Extract<LibraryItemsType, { __typename: 'Video' }> & ItemName
            : T extends LibraryItemType.Pdf
            ? Extract<LibraryItemsType, { __typename: 'Pdf' }> & ItemName
            : T extends LibraryItemType.Component
            ? Extract<LibraryItemsType, { __typename: 'Component' }> & ItemName
            : T extends LibraryItemType.Warranty
            ? Extract<LibraryItemsType, { __typename: 'Warranty' }> & ItemName
            : never
        >
  ): Promise<void | false> | void | false;
}

const getLibraryItemTitle = (types: AllowedLibraryPickerItemType[], capitalizeTitle: boolean, plural: boolean) => {
  return types
    .map(type => {
      let name = '';
      switch (type) {
        case LibraryItemType.Image:
          name = plural ? 'images' : 'image';
          break;
        case LibraryItemType.Pdf:
          name = plural ? 'PDFs' : 'PDF';
          break;
        case LibraryItemType.Video:
          name = plural ? 'videos' : 'video';
          break;
        case LibraryItemType.Article:
          name = plural ? 'articles' : 'article';
          break;
        case LibraryItemType.Warranty:
          name = plural ? 'warranties' : 'warranty';
          break;
        case LibraryItemType.Component:
          name = plural ? 'components' : 'component';
          break;
        case LibraryItemType.Story:
          name = plural ? 'stories' : 'story';
          break;
      }

      return capitalizeTitle ? capitalize(name) : name;
    })
    .join(' and ');
};

const getLibraryItemType = (type: AllowedLibraryPickerItemType) => {
  switch (type) {
    case LibraryItemType.Image:
    case LibraryItemType.Pdf:
    case LibraryItemType.Video:
      return ContentItemType.Media;
    case LibraryItemType.Article:
      return ContentItemType.Article;
    case LibraryItemType.Warranty:
      return ContentItemType.Warranty;
    case LibraryItemType.Component:
      return ContentItemType.Component;
    case LibraryItemType.Story:
      return ContentItemType.Story;
  }
};

const LibraryItemsContainer = <T extends AllowedLibraryPickerItemType | AllowedLibraryPickerItemType[]>({
  type,
  selectedItems = [],
  multi,
  itemsPerPage,
  emptySelectionAllowed,
  ctaButtonLabel,
  onRequestClose,
  onItemsSelect,
}: LibraryItemsContainerProps<T>) => {
  const { workspace } = useContext(OrganisationContext);
  const { setToast } = useContext(ToastContext);

  const [activeFilters, setActiveFilters] = useState<FilterType>({});
  const [page, setPage] = useState(0);
  const [connectionsSidebarRefId, setConnectionSidebarRefId] = useState('');
  const [selectedItemsList, setSelectedItemsList] = useState<typeof selectedItems>(selectedItems);
  const libraryItemTypes = [type].flat();

  const { data, loading, error } = useLibraryData({
    types: libraryItemTypes,
    page,
    itemsPerPage,
    filter: activeFilters,
  });

  if (error) {
    console.error(error);
    setToast({
      type: 'warning',
      message: `Sorry, we were not able to load ${getLibraryItemTitle(
        libraryItemTypes,
        false,
        true
      )}. Please try again later.`,
    });
  }

  const libraryItems = useMemo(() => {
    return (data?.library?.items || []).filter(
      (i): i is Exclude<NonNullable<typeof data>['library']['items'][number], { __typename: 'Assistant' }> => {
        return i.__typename !== 'Assistant';
      }
    );
  }, [data?.library?.items]);
  const totalItems = data?.library?.total ?? 0;

  const handleFiltersChange = useCallback(async (filters: FilterType) => {
    setActiveFilters(filters);
    setPage(0);
  }, []);

  const selectItem = (id: string) => {
    if (selectedItemsList.find(i => i.id === id)) {
      setSelectedItemsList([...selectedItemsList.filter(i => i.id !== id)]);
    } else {
      const selectedItem = libraryItems.find(i => i.id === id);
      if (!selectedItem) return;
      if (multi) {
        setSelectedItemsList([...selectedItemsList, selectedItem]);
      } else {
        setSelectedItemsList([selectedItem]);
      }
    }
  };

  const selectAllItems = (selected: boolean) => {
    if (!libraryItems.length) {
      return;
    }
    setSelectedItemsList(selected ? libraryItems : []);
  };

  const contentItemType = getLibraryItemType(libraryItemTypes[0]);
  const isMediaOnly = libraryItemTypes.every(isMediaType);
  const selectedAll = libraryItems.every(r => selectedItemsList.map(i => i.id).includes(r.id));

  return (
    <>
      <Hero>
        <ModalTitle>{getLibraryItemTitle(libraryItemTypes, true, true)}</ModalTitle>
        <Filter
          isMedia={isMediaOnly}
          showSearchInput={true}
          handleFiltersChange={handleFiltersChange}
          activeFilters={activeFilters}
          showLibraryFilters={contentItemType === ContentItemType.Article || contentItemType === ContentItemType.Story}
        />
      </Hero>
      <TableContainer>
        {!error && !loading && libraryItems.length === 0 && (
          <EmptyTable
            illustration={<IconEmptyAssistantsList />}
            title={`No ${getLibraryItemTitle(libraryItemTypes, true, true)} created`}
            description={`${getLibraryItemTitle(libraryItemTypes, true, true)} created will show up here`}
          />
        )}
        {loading ||
          (libraryItems.length > 0 && (
            <TableWrapper
              multi={multi}
              types={libraryItemTypes}
              selectedItemIds={selectedItemsList.map(i => i.id)}
              items={libraryItems}
              loading={loading}
              onItemSelect={selectItem}
              onAllItemsSelect={selectAllItems}
              selectedAll={selectedAll}
              openConnectionsSidebar={setConnectionSidebarRefId}
            />
          ))}
        <ConnectionsSidebar
          open={connectionsSidebarRefId !== ''}
          onClose={() => setConnectionSidebarRefId('')}
          contentItemType={contentItemType}>
          <ConnectionList
            workspaceId={workspace.id}
            reference={connectionsSidebarRefId}
            contentItemType={contentItemType}
          />
        </ConnectionsSidebar>
      </TableContainer>
      <ModalActions>
        <FooterWrapper>
          <TableFooter
            totalItems={totalItems}
            hideTotalItems
            page={page}
            itemsPerPage={itemsPerPage}
            setPage={setPage}
          />
          <SelectedItemsCount>{selectedItemsList.length} selected</SelectedItemsCount>
        </FooterWrapper>
        <Button type="button" size="lg" onClick={onRequestClose}>
          Cancel
        </Button>
        <Button
          size="lg"
          variant="primary"
          disabled={emptySelectionAllowed ? undefined : !selectedItemsList.length}
          onClick={() => {
            const castedItems = selectedItemsList.map(i => ({
              ...i,
              name: getLibraryItemTitleByType(i),
            })) as Parameters<typeof onItemsSelect>[0];
            const result = onItemsSelect(castedItems);
            if (result !== false) {
              onRequestClose();
            }
          }}
          type="submit">
          {ctaButtonLabel ??
            `Connect 
          ${
            libraryItemTypes.length === 1
              ? getLibraryItemTitle(libraryItemTypes, true, selectedItemsList.length > 1)
              : getLibraryItemTitle(
                  [...new Set(selectedItemsList.map(i => i.__typename.toLowerCase() as AllowedLibraryPickerItemType))],
                  true,
                  selectedItemsList.length > 1
                )
          }`}
        </Button>
      </ModalActions>
    </>
  );
};

export default LibraryItemsContainer;
