import { ComponentProps } from 'react';
import styled from 'styled-components';
import { Dropdown } from '@layerise/design-core';
import { AccessLevel } from 'types/typed-document-nodes';

type Props = Pick<ComponentProps<typeof Dropdown>, 'name' | 'value' | 'onChange' | 'disabled' | 'className'>;

function AccessLevelDropdownUnstyled({ className, name, value, onChange, disabled }: Props) {
  return (
    <Dropdown className={className} name={name} value={value} onChange={onChange} disabled={disabled}>
      <option value={AccessLevel.NoAccess}>No access</option>
      <option value={AccessLevel.Viewer}>Viewer</option>
      <option value={AccessLevel.Contributor}>Contributor</option>
    </Dropdown>
  );
}

export const AccessLevelDropdown = styled(AccessLevelDropdownUnstyled)``;
