import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import Link from 'next/link';
import { Button, Modal, ModalActions } from '@layerise/design-core';
import {
  CurrentMemberPhoneDocument,
  GenerateBackupCodesDocument,
  MarkBackupCodesShownDocument,
  RecoveryData,
} from 'types/typed-document-nodes';
import { useState } from 'react';
import format from 'date-fns/format';
import { useApiErrorHandler } from 'hooks/useApiErrorHandler';

const CodesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 110px);
  justify-content: space-evenly;
  margin: 18px 0 38px;
  gap: 4px;
`;

const RecoveryCode = styled.div`
  padding: 7px 9px;
  background-color: rgba(1, 0, 51, 0.07);
  white-space: nowrap;
  color: #010032e6;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 10px;
  text-align: center;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
`;

const UsageInfo = styled.div`
  margin-bottom: 38px;
`;

const GenerateLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 19px;
  margin-top: 10px;
  display: inline-block;
  cursor: pointer;
`;

const LearnMore = styled.div`
  margin-bottom: 16px;
  & > a {
    text-decoration: underline;
  }
`;

type Props = {
  onRequestClose(): void;
  recoveryData: RecoveryData;
  usage?: boolean;
  actionRequired?: boolean;
  zIndex?: number;
  initialDisplay?: boolean;
  onDownload?: () => void;
};

export const BackupCodesModal = ({
  recoveryData,
  usage,
  actionRequired,
  zIndex,
  initialDisplay,
  onRequestClose,
  onDownload,
}: Props) => {
  const [actionTaken, setActionTaken] = useState(false);
  const [markBackupCodesShown] = useMutation(MarkBackupCodesShownDocument, { onError: useApiErrorHandler() });
  const [generateBackupCodes, { data: generatedData, loading }] = useMutation(GenerateBackupCodesDocument, {
    onError: useApiErrorHandler(),
    refetchQueries: [{ query: CurrentMemberPhoneDocument }],
  });
  const { createdAt, codes } = generatedData?.generateRecoveryData || recoveryData;
  const unusedCodesCount = codes.filter(code => code.v).length;
  const usedCodesCount = codes.length - unusedCodesCount;

  const handleCtaClicked = async () => {
    try {
      await markBackupCodesShown();
    } finally {
      setActionTaken(true);
    }
  };

  const onPrimaryCtaClicked = async () => {
    await handleCtaClicked();
    onDownload?.();
  };

  return (
    <Modal
      header="Backup verification codes"
      modalContainerStyle={{ zIndex }}
      containerStyle={{ textAlign: 'center', zIndex: typeof zIndex === 'undefined' ? undefined : zIndex + 1 }}
      onClick={actionTaken || !actionRequired ? onRequestClose : undefined}>
      With 2FA enabled for your account, you’ll need these backup codes if you ever lose your device. Each code can be
      used only once. Without your device or a backup code, you’ll have to contact Layerise support to recover your
      account.
      <CodesGrid>
        {codes.map(({ c }) => (
          <RecoveryCode key={c}>{c}</RecoveryCode>
        ))}
      </CodesGrid>
      {usage && (
        <UsageInfo>
          <div>These codes were generated on {format(new Date(createdAt), 'MMMM dd, yyyy')}</div>
          <div>
            {usedCodesCount} have been used, {unusedCodesCount} are still available.
          </div>
          <GenerateLink
            onClick={() => {
              if (loading) return;
              generateBackupCodes();
            }}>
            Generate new codes
          </GenerateLink>
        </UsageInfo>
      )}
      <Actions style={{ marginBottom: initialDisplay ? 25 : actionRequired ? 42 : 0 }}>
        <Button
          type="button"
          size="lg"
          onClick={() => {
            handleCtaClicked();
            window.print();
          }}>
          Print
        </Button>
        {initialDisplay ? (
          <Button variant="primary" size="lg" onClick={onPrimaryCtaClicked}>
            Download later
          </Button>
        ) : (
          <Link download href="/api/recovery">
            <Button variant="primary" size="lg" onClick={onPrimaryCtaClicked}>
              Download
            </Button>
          </Link>
        )}
      </Actions>
      {initialDisplay && (
        <LearnMore>
          <a
            href={`${process.env.URL_WWW}/help/my-account/setting-up-your-account/require-two-factor-authentication`}
            rel="noreferrer"
            target="_blank">
            Learn more
          </a>
        </LearnMore>
      )}
      {actionRequired ? null : (
        <ModalActions>
          <Button size="lg" onClick={onRequestClose}>
            Cancel
          </Button>
        </ModalActions>
      )}
    </Modal>
  );
};
