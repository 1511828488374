import styled from 'styled-components';
import { ModalProvider, Modal, ModalRoot, ModalContext, Avatar, Button } from '@layerise/design-core';
import { EditMemberFragmentFragmentDoc } from 'types/typed-document-nodes';
import { getFullName } from 'lib/utils';
import { useContext, useState } from 'react';
import { StyledLink } from 'components/settings/elements';
import { PermissionsView } from './views/PermissionsView';
import { GeneralView } from './views/GeneralView';
import { AdvancedView } from './views/AdvancedView';
import { SecurityView } from './views/SecurityView';
import { NotificationsView } from './views/NotificationsView';
import { ApolloQueryResult, useFragment_experimental as useFragment } from '@apollo/client';
import { OrganisationContext } from 'contexts/OrganisationContext';

export enum ContentViewType {
  General,
  Permissions,
  Security,
  Advanced,
  Notifications,
}

const CONTENT_VIEWS: { type: ContentViewType; label: string }[] = [
  {
    type: ContentViewType.General,
    label: 'General',
  },
  {
    type: ContentViewType.Permissions,
    label: 'Permissions',
  },
  {
    type: ContentViewType.Notifications,
    label: 'Notifications',
  },
  {
    type: ContentViewType.Security,
    label: 'Security',
  },
  {
    type: ContentViewType.Advanced,
    label: 'Advanced',
  },
];

const Container = styled.div`
  display: grid;
  grid-template-rows: 52px 1fr;
  height: 100%;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px 22px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 1px 1px 0px rgba(0, 0, 0, 0.12),
    0px 0px 0px 1px rgba(60, 66, 87, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 2px 5px 0px rgba(60, 66, 87, 0.08);
  z-index: 1;
`;

const Content = styled.section`
  display: grid;
  grid-template-columns: 240px 1fr;
  min-height: 0;
  height: 100%;
`;

const Aside = styled.aside`
  padding: 19px 12px;
  border-right: 1px solid #dcdde2;
  ul {
    list-style-type: none;
  }
`;

const MemberName = styled.span`
  font-weight: 500;
  color: #010032;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 1;
`;

const ViewContainer = styled.section`
  background-color: #fff;
  display: grid;
  grid-template-rows: 1fr auto;
  border-radius: 0 0 var(--radius-extra-large) 0;
  height: 100%;
  min-height: 0;
`;

const ViewContent = styled.div`
  padding: 30px 32px;
  overflow: auto;
`;

const Actions = styled.div`
  padding: 20px 25px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dcdde2;
`;

type Props = {
  memberId: string;
  refetch(): Promise<ApolloQueryResult<unknown>[]> | Promise<ApolloQueryResult<unknown>>;
  onRequestClose(): void;
  readonly?: boolean;
  tabs?: ContentViewType[];
  activeTab?: ContentViewType;
};

function ManageMemberModalBase({
  memberId,
  refetch,
  readonly,
  onRequestClose,
  tabs,
  activeTab = ContentViewType.General,
}: Props) {
  const [view, setView] = useState(activeTab);
  const { data: member } = useFragment({
    from: {
      __typename: 'Member',
      id: memberId,
    },
    fragment: EditMemberFragmentFragmentDoc,
    fragmentName: 'EditMemberFragment',
  });

  if (!member) return null;

  return (
    <ModalProvider>
      <ModalRoot />
      <ModalContext.Consumer>
        {() => (
          <Modal
            width="80vw"
            height="90vh"
            modalBodyStyle={{ height: '100%', maxHeight: 'none' }}
            backgroundColor="#f7f7fa"
            padding="0"
            onClick={onRequestClose}>
            <Container>
              <Header>
                <Avatar height={28} src={member.avatarImg?.dynamic || undefined} />
                <MemberName>{getFullName(member)}</MemberName>
              </Header>
              <Content>
                <Aside>
                  <ul>
                    {CONTENT_VIEWS.filter(cv => (tabs ? tabs.includes(cv.type) : true)).map(c => (
                      <StyledLink as="li" activeLink={c.type === view} key={c.type} onClick={() => setView(c.type)}>
                        {c.label}
                      </StyledLink>
                    ))}
                  </ul>
                </Aside>
                <ViewContainer>
                  <ViewContent>
                    {view === ContentViewType.General && (
                      <GeneralView member={member} readonly={!!readonly} refetch={refetch} />
                    )}
                    {view === ContentViewType.Permissions && (
                      <PermissionsView member={member} readonly={!!readonly} refetch={refetch} />
                    )}
                    {view === ContentViewType.Security && (
                      <SecurityView member={member} readonly={!!readonly} refetch={refetch} />
                    )}
                    {view === ContentViewType.Advanced && (
                      <AdvancedView
                        member={member}
                        readonly={!!readonly}
                        refetch={refetch}
                        onRequestClose={onRequestClose}
                      />
                    )}
                    {view === ContentViewType.Notifications && (
                      <NotificationsView member={member} readonly={!!readonly} refetch={refetch} />
                    )}
                  </ViewContent>
                  <Actions>
                    <Button size="lg" onClick={onRequestClose}>
                      Cancel
                    </Button>
                  </Actions>
                </ViewContainer>
              </Content>
            </Container>
          </Modal>
        )}
      </ModalContext.Consumer>
    </ModalProvider>
  );
}

export function ManageMemberModal(props: Omit<Props, 'tabs'>) {
  return (
    <ManageMemberModalBase
      {...props}
      tabs={[ContentViewType.General, ContentViewType.Permissions, ContentViewType.Security, ContentViewType.Advanced]}
    />
  );
}

export function CurrentMemberProfileModal(props: Pick<Props, 'onRequestClose' | 'activeTab'>) {
  const { me, refetch } = useContext(OrganisationContext);
  return (
    <ManageMemberModalBase
      memberId={me.id}
      refetch={refetch}
      tabs={[ContentViewType.General, ContentViewType.Notifications, ContentViewType.Security]}
      {...props}
    />
  );
}
