import * as React from 'react';
import { SVGProps } from 'react';

const IconTeams = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      d="M7.2 4.8a2.4 2.4 0 1 1-4.801-.001A2.4 2.4 0 0 1 7.2 4.8Zm6.4 0a2.4 2.4 0 1 1-4.801-.001A2.4 2.4 0 0 1 13.6 4.8Zm-3.256 8.8c.037-.262.056-.528.056-.8a5.574 5.574 0 0 0-1.2-3.464 3.998 3.998 0 0 1 6 3.464v.8ZM4.8 8.8a4 4 0 0 1 4 4v.8h-8v-.8a4 4 0 0 1 4-4Z"
      fill="hsl(0, 0%, 100%)"
    />
  </svg>
);

export default IconTeams;
