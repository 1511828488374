import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, PropsWithChildren } from 'react';
import { Client, Conversation, Message, ConnectionState } from '@twilio/conversations';
import { MutationTuple, QueryResult, useMutation, useQuery } from '@apollo/client';
import { useChatClient, useConversations } from './hooks';
import { ChatArea, InternalConversation, Member, Team } from './types';
import { getLastCustomerMessage, isUnread } from './lib';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { ToastContext } from 'contexts/ToastContext';
import { MemberContext } from 'contexts/MemberContext';
import { AppSection } from 'types/sections';
import {
  WorkspaceTeamsDocument,
  WorkspaceActiveConversationsDocument,
  WorkspaceArchivedConversationsDocument,
  WorkspaceActiveConversationsQuery,
  WorkspaceArchivedConversationsQuery,
  SupportMembersDocument,
  MissingMessageAuthorsDocument,
  CreateChatAccessTokensDocument,
  CreateWorkspaceChatAccessTokenDocument,
  CreateMemberChatAccessTokenDocument,
  WorkspaceActiveConversationsQueryVariables,
  WorkspaceArchivedConversationsQueryVariables,
} from 'types/typed-document-nodes';
import { isPresent } from 'ts-is-present';

export type ChatContextType = {
  memberConvos: Conversation[];
  workspaceConvos: Conversation[];
  workspaceMessages: Record<Conversation['sid'], Message[]>;
  connectionState: ConnectionState;
  activeConversationsQuery: QueryResult<WorkspaceActiveConversationsQuery, WorkspaceActiveConversationsQueryVariables>;
  closedConversationsQuery: QueryResult<
    WorkspaceArchivedConversationsQuery,
    WorkspaceArchivedConversationsQueryVariables
  >;
  area: ChatArea | null;
  setArea: React.Dispatch<React.SetStateAction<ChatArea | null>>;
  hasUnreadMessages: boolean;
  hasUnreadUnassignedMessages: boolean;
  supportMembers: Member[];
  teams: Team[];
  sortedConversations: InternalConversation[];
  conversations: InternalConversation[];
  messageAuthors: Member[];
  sidebar: boolean;
  setSidebar: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  permissions: {
    canView: boolean;
    canContribute: boolean;
  };
  loadMoreConversations(): Promise<void>;
};

export const ChatContext = createContext<ChatContextType>(undefined!);

const PAGINATION_STEP = 30;

interface ChatProviderProps extends PropsWithChildren {}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const { me, workspace, organisation, plan } = useContext(OrganisationContext);
  const { canView, canContribute } = useContext(MemberContext);
  const canRead = canView(AppSection.CONVERSATIONS);
  const canWrite = canContribute(AppSection.CONVERSATIONS);
  const [area, setArea] = useState<ChatArea | null>(null);
  const [connectionState, setConnectionState] = useState<ConnectionState>('connecting');
  const [memberAccessToken, setMemberAccessToken] = useState<string | null>(null);
  const [workspaceAccessToken, setWorkspaceAccessToken] = useState<string | null>(null);
  const [createTokensMutation] = useMutation(CreateChatAccessTokensDocument, {
    variables: {
      workspaceId: workspace?.id,
    },
  });
  const [createWorkspaceTokenMutation] = useMutation(CreateWorkspaceChatAccessTokenDocument, {
    variables: {
      workspaceId: workspace?.id,
    },
  });
  const [createMemberTokenMutation] = useMutation(CreateMemberChatAccessTokenDocument, {
    variables: {
      workspaceId: workspace?.id,
    },
  });
  const [sidebar, setSidebar] = useState(false);
  const { setToast } = useContext(ToastContext);

  useEffect(() => {
    (async () => {
      if (!workspace?.id || !canRead || !plan.planLimit?.hasAccessToConversations) return;
      const { data } = await createTokensMutation();
      setMemberAccessToken(data?.createMemberAccessToken?.token || null);
      setWorkspaceAccessToken(data?.createWorkspaceAccessToken?.token || null);
    })();
  }, [canRead, workspace?.id]);

  const activeConversationsQuery = useQuery(WorkspaceActiveConversationsDocument, {
    skip: !workspace?.id || !canRead || area === 'CLOSED',
    variables: {
      workspaceId: workspace?.id,
      first: PAGINATION_STEP,
      skip: 0,
    },
  });

  const closedConversationsQuery = useQuery(WorkspaceArchivedConversationsDocument, {
    skip: !workspace?.id || !canRead || area !== 'CLOSED',
    variables: {
      workspaceId: workspace?.id,
      last: PAGINATION_STEP,
      skip: 0,
    },
  });

  const supportMembersQuery = useQuery(SupportMembersDocument, {
    skip: !workspace?.id || !canRead,
    variables: {
      workspaceId: workspace?.id,
    },
  });
  const supportMembers = supportMembersQuery.data?.supportMembers.filter(isPresent) || [];

  const teamsQuery = useQuery(WorkspaceTeamsDocument, {
    skip: !workspace?.id || !canRead,
    variables: { id: workspace?.id },
  });
  const teams = teamsQuery.data?.workspace?.teams || [];

  const { client: memberClient } = useChatClient(memberAccessToken);
  const { client: orgClient } = useChatClient(workspaceAccessToken);
  const errorMessage =
    closedConversationsQuery.error?.message ||
    activeConversationsQuery.error?.message ||
    supportMembersQuery.error?.message;
  const log = process.env.NODE_ENV === 'development';

  useEffect(() => {
    if (errorMessage) {
      setToast({
        type: 'warning',
        message:
          'An error occurred while loading. Please to try refresh. If error continues please reach out to support',
        timeout: 6000,
      });
    }
  }, [errorMessage]);

  const refreshToken = (client: Client, mutation: MutationTuple<any, any>[0], responseKey: string): void => {
    log && console.log(`[${responseKey}] Refreshing access token...`);
    mutation().then(({ data }) => {
      if (!data || !data[responseKey].token) {
        console.error(`[${responseKey}] Unable to fetch access token from backend`);
        return;
      }
      client
        .updateToken(data[responseKey].token)
        .then(() => {
          log && console.log(`[${responseKey}] Token was successfully updated`);
        })
        .catch(e => {
          console.error(`[${responseKey}] Unable to update token`, e);
          setToast({
            type: 'warning',
            message:
              'An error occurred while loading. Please to try refresh. If error continues please reach out to support',
            timeout: 6000,
          });
        });
    });
  };

  const [memberConversations] = useConversations(memberClient, { noMessages: true });

  const [workspaceConversations, workspaceMessages] = useConversations(
    orgClient,
    {
      onConversationAdded: async (conversation: Conversation) => {
        const participants = await conversation.getParticipants();
        if (!participants.some(p => p.identity?.startsWith('mem_'))) {
          await activeConversationsQuery.refetch();
        }
      },
    },
    log
  );

  useEffect(() => {
    if (!memberClient) return;

    memberClient.on('connectionStateChanged', (state: ConnectionState) => {
      setConnectionState(state);
    });

    memberClient.on('tokenAboutToExpire', () => {
      refreshToken(memberClient, createMemberTokenMutation, 'createMemberAccessToken');
    });

    return () => {
      memberClient && memberClient.shutdown();
    };
  }, [memberClient]);

  useEffect(() => {
    if (orgClient) {
      orgClient.on('conversationUpdated', ({ conversation, updateReasons }) => {
        const isStateUpdated = updateReasons.includes('state') && conversation.state?.current === 'active';
        const isAttributeUpdated = updateReasons.includes('attributes');
        if (isStateUpdated || isAttributeUpdated) {
          setTimeout(() => {
            activeConversationsQuery.refetch();
          }, 1500);
        }
      });

      orgClient.on('tokenAboutToExpire', () => {
        refreshToken(orgClient, createWorkspaceTokenMutation, 'createWorkspaceAccessToken');
      });
    }

    return () => {
      orgClient && orgClient.shutdown();
    };
  }, [orgClient]);

  const hasUnreadMessages = useMemo(() => {
    return workspaceConversations.some(convo => isUnread(convo, workspaceMessages));
  }, [workspaceConversations, workspaceMessages]);

  const hasUnreadUnassignedMessages = useMemo(() => {
    const activeConversations = activeConversationsQuery.data?.getWorkspaceActiveConversations || [];
    const areUnread = workspaceConversations.map(convo => {
      const activeConversation = activeConversations.find(i => i.twilioId === convo.sid);
      if (activeConversation?.member?.id === 'unknown' && isUnread(convo, workspaceMessages)) {
        return true;
      }
      return false;
    });
    const result = areUnread.some(val => val);
    return result;
  }, [activeConversationsQuery.data?.getWorkspaceActiveConversations, workspaceConversations, workspaceMessages]);

  const conversations = useMemo(() => {
    if (area === 'CLOSED') {
      return closedConversationsQuery.data?.getWorkspaceArchivedConversations || [];
    } else {
      const activeConversations = activeConversationsQuery.data?.getWorkspaceActiveConversations || [];

      if (area === 'INBOX') {
        return activeConversations;
      }

      if (area === 'FOR_YOU') {
        return activeConversations.filter(c => c.member?.id === me.id);
      }

      if (area === 'UNASSIGNED') {
        return activeConversations?.filter(c => c.member?.id === 'unknown');
      }
    }

    return [];
  }, [me.id, area, activeConversationsQuery.data, closedConversationsQuery.data]);

  const sortCallback = useCallback(
    (a: InternalConversation, b: InternalConversation) => {
      const twilioConvoA = workspaceConversations.find(c => c.sid === a.twilioId);
      const twilioConvoB = workspaceConversations.find(c => c.sid === b.twilioId);
      const lastCustomerMessageA = getLastCustomerMessage(twilioConvoA, workspaceMessages);
      const lastCustomerMessageB = getLastCustomerMessage(twilioConvoB, workspaceMessages);

      const dateA = lastCustomerMessageA?.dateCreated
        ? lastCustomerMessageA?.dateCreated
        : a.lastCustomerMessageCreatedAt;
      const dateB = lastCustomerMessageB?.dateCreated
        ? lastCustomerMessageB?.dateCreated
        : b.lastCustomerMessageCreatedAt;

      if (dateA && dateB) {
        return new Date(dateB).getTime() - new Date(dateA).getTime();
      } else if (!dateA && dateB) {
        return 1;
      } else if (dateA && !dateB) {
        return -1;
      }
      return 0;
    },
    [workspaceConversations, workspaceMessages]
  );

  const sortedConversations = useMemo(() => {
    return [...conversations].sort(sortCallback);
  }, [conversations, sortCallback]);

  const { fetchMore: fetchMoreActiveConversations } = activeConversationsQuery;
  const { fetchMore: fetchMoreClosedConversations } = closedConversationsQuery;
  const conversationsCount = sortedConversations.length;

  const loadMoreConversations = useCallback(async () => {
    const fetchMoreQuery = area === 'CLOSED' ? fetchMoreClosedConversations : fetchMoreActiveConversations;
    await fetchMoreQuery({
      variables: {
        first: PAGINATION_STEP,
        skip: conversationsCount,
      },
    });
  }, [conversationsCount, area, fetchMoreClosedConversations, fetchMoreActiveConversations]);

  const missingAuthorIds: string[] = [];
  for (const workspaceMessageList of Object.values(workspaceMessages)) {
    for (const workspaceMessage of workspaceMessageList) {
      const id = workspaceMessage.author?.substring(4);
      if (
        workspaceMessage.author?.startsWith('mem_') &&
        id &&
        !missingAuthorIds.includes(id) &&
        !supportMembers.find(supportMember => supportMember.id === id)
      ) {
        missingAuthorIds.push(id);
      }
    }
  }

  const missingAuthorsQuery = useQuery(MissingMessageAuthorsDocument, {
    skip: !canRead || missingAuthorIds.length === 0,
    variables: {
      organisationId: organisation.id,
      ids: missingAuthorIds,
    },
  });
  const messageAuthors = [
    ...(missingAuthorsQuery.data?.organisation?.members?.filter(isPresent) || []),
    ...supportMembers,
  ];

  return (
    <ChatContext.Provider
      value={{
        workspaceConvos: workspaceConversations,
        memberConvos: memberConversations,
        workspaceMessages,
        area,
        setArea,
        connectionState,
        activeConversationsQuery,
        closedConversationsQuery,
        hasUnreadMessages,
        hasUnreadUnassignedMessages,
        supportMembers,
        teams,
        sortedConversations,
        conversations,
        messageAuthors,
        sidebar,
        setSidebar,
        loadMoreConversations,
        permissions: {
          canView: canRead,
          canContribute: canWrite,
        },
      }}>
      {children}
    </ChatContext.Provider>
  );
};

export const ChatConsumer = ChatContext.Consumer;
export default ChatContext;
