import styled from 'styled-components';
import { Img, Popover } from '@layerise/design-core';
import { Preview } from 'components/media/Preview';
import { Supported } from 'pages/w/[id]/library/media';

type Props = {
  small?: boolean;
  item: Supported;
  useUpdateDate?: boolean;
};

const Container = styled.div<{ backgroundColor?: string; fill?: string; small?: boolean }>`
  background-color: ${props => props.backgroundColor};
  border-radius: 3px;
  height: ${props => (props.small ? '24px' : '32px')};
  width: ${props => (props.small ? '24px' : '32px')};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  svg path {
    fill: ${props => props.fill};
  }

  svg {
    height: ${props => (props.small ? '12px' : '16px')};
    width: ${props => (props.small ? '12px' : '16px')};
  }

  video {
    width: ${props => (props.small ? '12px' : '32px')};
    height: ${props => (props.small ? '12px' : '32px')};
    border-radius: 3px;
  }
  img {
    border-radius: 3px;
    max-height: ${props => (props.small ? '12px' : '32px')};
  }
`;

const TriggerContainer = styled.div<{ border: boolean; small?: boolean }>`
  height: ${props => (props.small ? '24px' : '32px')};
  width: ${props => (props.small ? '24px' : '32px')};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${props => (props.border ? '1px solid rgba(0,0,0,0.08)' : 'none')};
`;

export const ItemViz: React.FC<Props> = ({ small, item, useUpdateDate }) => {
  return (
    <Container small={small}>
      <Popover
        onHover
        hideOnScroll
        delay={250}
        right
        trigger={
          <TriggerContainer small={small} border>
            <Img
              width={32}
              src={item.dynamic || ''}
              mediaType={item.__typename === 'Video' ? 'video' : 'image'}
              alt={('name' in item && item.name) || ''}
            />
          </TriggerContainer>
        }>
        <Preview item={item as Supported} useUpdateDate={useUpdateDate} />
      </Popover>
    </Container>
  );
};
