import { Button, ModalActions, Modal, Img } from '@layerise/design-core';
import { IconInfo } from '@layerise/design-icon';
import { LearnMore, Content, Body, Title, UpdateTag, ModalHero } from './SharedStyles';
import { useMutation } from '@apollo/client';
import { SetMemberOnboardingDocument } from 'types/typed-document-nodes';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { useContext } from 'react';
import { NORMAL_MODAL_Z_INDEX, FeatureModalProps } from './constants';

export const StoriesUpdate = (props: FeatureModalProps) => {
  const [setOnboarding] = useMutation(SetMemberOnboardingDocument);

  const { refetch } = useContext(OrganisationContext);

  const handleClick = async () => {
    props.onRequestClose();
    await setOnboarding({
      variables: {
        discoverStory: true,
      },
    });
    await refetch();
  };

  return (
    <Modal
      onClick={props.onRequestClose}
      modalContainerStyle={{ zIndex: NORMAL_MODAL_Z_INDEX + 1 }}
      containerStyle={{ zIndex: NORMAL_MODAL_Z_INDEX + 2 }}
      padding="0px 0px 0px 0px"
      height="620px"
      width="520px">
      <ModalHero>
        <UpdateTag>UPDATE</UpdateTag>
        <Img alt="product" src="WWW/productUpdates/stories" height={776} width={1040} quality={80} />
      </ModalHero>
      <Content>
        <Title>Explain anything with Stories</Title>
        <Body>
          You can create Stories (previously called Highlights) for anything but they are especially great for step by
          step explanations. Explain and educate with images, videos, captions, and much more. All Stories in the
          library will be reusable in any Assistant.
        </Body>
        <ModalActions
          negative={
            <LearnMore
              href={`${process.env.URL_WWW}/help/assistant/creating-an-assistant/create-a-story`}
              target="_blank"
              rel="noreferrer">
              Learn more <IconInfo />
            </LearnMore>
          }>
          <Button size="lg" variant="primary" onClick={handleClick}>
            Close
          </Button>
        </ModalActions>
      </Content>
    </Modal>
  );
};
