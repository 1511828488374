import * as React from 'react';
import { SVGProps } from 'react';

const IconCustomDomain = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      d="M8 14.4A6.4 6.4 0 0 0 14.4 8 6.4 6.4 0 0 0 8 1.6 6.4 6.4 0 0 0 1.6 8 6.4 6.4 0 0 0 8 14.4ZM3.466 6.422a4.81 4.81 0 0 1 1.529-2.165A1.2 1.2 0 0 0 6 4.8 1.2 1.2 0 0 1 7.2 6v.4a1.6 1.6 0 1 0 3.2 0 1.6 1.6 0 0 1 1.218-1.554A4.776 4.776 0 0 1 12.8 8c0 .272-.022.54-.066.8H12a1.6 1.6 0 0 0-1.6 1.6v1.758A4.778 4.778 0 0 1 8 12.8v-1.6a1.6 1.6 0 0 0-1.6-1.6A1.6 1.6 0 0 1 4.8 8a1.6 1.6 0 0 0-1.334-1.578Z"
      fill="hsl(0, 0%, 100%)"
    />
  </svg>
);

export default IconCustomDomain;
