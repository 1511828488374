import { formatDistanceStrict } from 'date-fns';

export const formatDistanceShort = (date: string | Date) => {
  let initialDate = formatDistanceStrict(new Date(date), new Date());

  if (initialDate.includes('seconds')) {
    initialDate = initialDate.replace(' seconds', 's');
    initialDate = 'now';
  }
  if (initialDate.includes('second')) {
    initialDate = initialDate.replace(' second', 's');
    initialDate = 'now';
  }
  if (initialDate.includes('minutes')) {
    initialDate = initialDate.replace(' minutes', 'm');
  }
  if (initialDate.includes('minute')) {
    initialDate = initialDate.replace(' minute', 'm');
  }
  if (initialDate.includes('hours')) {
    initialDate = initialDate.replace(' hours', 'h');
  }
  if (initialDate.includes('hour')) {
    initialDate = initialDate.replace(' hour', 'h');
  }
  if (initialDate.includes('days')) {
    initialDate = initialDate.replace(' days', 'd');
  }

  if (initialDate.includes('day')) {
    initialDate = initialDate.replace(' day', 'd');
  }

  if (initialDate.includes('months')) {
    initialDate = initialDate.replace(' months', 'mo');
  }

  if (initialDate.includes('month')) {
    initialDate = initialDate.replace(' month', 'mo');
  }

  if (initialDate.includes('years')) {
    initialDate = initialDate.replace(' years', 'yr');
  }

  if (initialDate.includes('year')) {
    initialDate = initialDate.replace(' year', 'yr');
  }

  return initialDate;
};

export const formatDistanceShortNoSec = (date: string | Date) => {
  const distance = formatDistanceShort(date);
  return distance[distance.length - 1] === 's' ? '<1m' : distance;
};
