import React, { useRef, useMemo, useEffect, useState, ChangeEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { Button, Dropdown, Popover, useForm, Input, FormElements, CountrySelector } from '@layerise/design-core';
import { FilterItem } from './FilterItem';
import { useOuterClick } from '@layerise/utils';
import { IconFilter } from '@layerise/design-icon';
import { ParsedUrlQuery } from 'querystring';
import { PopoverRef } from 'types/popover';
import { useCountries } from 'hooks/data/useCountries';
import { flags } from '@layerise/design-flag';
import FilterMultiSelect from './FilterMultiSelect';

type FilterItemValue = boolean | string | string[] | number | null;
type FilterItemOption = { id: string | number; title: string };

type FilterItemType = {
  id: string;
  options?: FilterItemOption[];
  emptyOptionsPlaceHolder?: string;
  placeholder?: string;
  title: string;
  value?: FilterItemValue;
  isDate?: boolean;
  isDatePicker?: boolean;
  isMultiSelect?: boolean;
  isRange?: boolean;
  isCountrySelector?: boolean;
  preselectedOption?: FilterItemValue | ((options: FilterItemOption[] | undefined) => FilterItemValue | undefined);
  searchQuery?: string;
  onSearchChange?: (e: any) => void;
};

type Props = {
  onReset?: (filters: any) => void;
  onSubmit?: (filters: any) => void;
  onChange?: (name: string, value: string | string[] | number | null) => void;
  defaultValues?: {
    id: string;
    value?: boolean | string | number;
  }[];
  filters: FilterItemType[];
  compact?: boolean;
  noPortal?: boolean;
};

const Container = styled.div`
  background-color: #fff;
  box-shadow: var(--depth-Z200);
  border-radius: 7px;
  min-width: 234px;
  margin-top: 8px;

  > div:last-child {
    > div:last-child {
      border-bottom: 0;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }
`;

const Header = styled.div`
  height: 40px;
  background-color: var(--color-light-bg);
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 8px;
  border-radius: 7px 7px 0 0;
  border-bottom: 1px solid var(--color-N100);
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: var(--color-N500);
  text-align: center;
`;

const CountStyle = styled.div<{ active: boolean }>`
  margin-left: 4px;
  border-left: 1px solid var(--color-N150);
  height: 16px;
  padding-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: ${props => props.active && 'var(--color-B400)'};
`;

const Action = styled.div<{
  gridLength?: number;
}>`
  display: grid;
  grid-template-columns: ${props => (props.gridLength ? `repeat(${props.gridLength}, 1fr)` : '218px')};
  gap: 8px;
  margin-top: 4px;
`;

const IconContainer = styled.div<{ compact?: boolean }>`
  margin-top: -3px;
  ${props =>
    props.compact &&
    css`
      margin-right: -5px;
    `}
`;

const SEARCH_PARAM_PREFIX = 'f_';

const DATE_PICKER_DEFAULT_VALUES = {
  dateConfiguration: 'isInTheLast',
  startDate: '',
  endDate: 'day',
};

const DatePickerFilter = ({
  name,
  value,
  title,
  toggleValue,
  toggleChange,
  onChange,
}: {
  name: string;
  title: string;
  value: string[];
  toggleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleValue: boolean;
  onChange?: (name: string, value: string[]) => void;
}) => {
  const initialValues = value || [];
  const { inputs, handleChange: handleInputChange } = useForm({
    dateConfiguration: initialValues[0] || DATE_PICKER_DEFAULT_VALUES.dateConfiguration,
    startDate: initialValues[1] || DATE_PICKER_DEFAULT_VALUES.startDate,
    endDate: initialValues[2] || DATE_PICKER_DEFAULT_VALUES.endDate,
  });

  const handleChange = (e: React.ChangeEvent<FormElements>) => {
    const data = {
      ...inputs,
      [e.target.name]: e.target.value,
    };
    handleInputChange(e);
    if (onChange) {
      const value = [data.dateConfiguration, data.startDate.toString(), data.endDate.toString()];
      onChange(name, value);
    }
  };

  return (
    <FilterItem onChange={toggleChange} name={name} value={toggleValue} title={title}>
      <Dropdown size="sm" onChange={handleChange} value={inputs.dateConfiguration} name="dateConfiguration" wide>
        <option value="isInTheLast">Is in the last</option>
        <option value="isEqualTo">Is equal to</option>
        <option value="isBetween">Is between</option>
        <option value="isAfter">Is after</option>
        <option value="isOnOrAfter">Is on or after</option>
        <option value="isBefore">Is before</option>
        <option value="isBeforeOrOn">Is before or on</option>
      </Dropdown>
      {inputs.dateConfiguration === 'isInTheLast' && (
        <Action gridLength={2}>
          <Input
            type="number"
            size="sm"
            onChange={handleChange}
            placeholder="0"
            name="startDate"
            value={inputs.startDate}
          />
          <Dropdown size="sm" value={inputs.endDate || 'day'} onChange={handleChange} name="endDate">
            <option value="day">Day</option>
            <option value="month">Month</option>
          </Dropdown>
        </Action>
      )}
      {inputs.dateConfiguration === 'isEqualTo' && (
        <Action>
          <Input
            type="date"
            size="sm"
            value={inputs.startDate}
            onChange={handleChange}
            placeholder="0"
            name="startDate"
          />
        </Action>
      )}
      {inputs.dateConfiguration === 'isBetween' && (
        <Action gridLength={2}>
          <Input
            type="date"
            size="sm"
            value={inputs.startDate}
            onChange={handleChange}
            placeholder="0"
            name="startDate"
          />
          <Input type="date" size="sm" value={inputs.endDate} onChange={handleChange} placeholder="0" name="endDate" />
        </Action>
      )}
      {inputs.dateConfiguration === 'isAfter' && (
        <Action>
          <Input
            type="date"
            size="sm"
            value={inputs.startDate}
            onChange={handleChange}
            placeholder="0"
            name="startDate"
          />
        </Action>
      )}
      {inputs.dateConfiguration === 'isOnOrAfter' && (
        <Action>
          <Input
            type="date"
            size="sm"
            value={inputs.startDate}
            onChange={handleChange}
            placeholder="0"
            name="startDate"
          />
        </Action>
      )}
      {inputs.dateConfiguration === 'isBefore' && (
        <Action>
          <Input type="date" size="sm" value={inputs.endDate} onChange={handleChange} placeholder="0" name="endDate" />
        </Action>
      )}
      {inputs.dateConfiguration === 'isBeforeOrOn' && (
        <Action>
          <Input type="date" size="sm" value={inputs.endDate} onChange={handleChange} placeholder="0" name="endDate" />
        </Action>
      )}
    </FilterItem>
  );
};

const RangePicker = ({
  name,
  value,
  title,
  toggleValue,
  toggleChange,
  onChange,
}: {
  name: string;
  title: string;
  value?: string[];
  toggleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleValue: boolean;
  onChange?: (name: string, value: string[]) => void;
}) => {
  const { inputs, handleChange: handleInputChange } = useForm({
    type: value?.[0] || 'MIN',
    value: value?.[1] || 0,
  });

  const handleChange = (e: React.ChangeEvent<FormElements>) => {
    handleInputChange(e);
    if (onChange) {
      const isType = e.target.name === 'type';
      const newValue = [isType ? e.target.value : inputs.type, !isType ? e.target.value : inputs.value.toString()];
      onChange(name, newValue);
    }
  };
  return (
    <FilterItem onChange={toggleChange} name={name} value={toggleValue} title={title}>
      <Action gridLength={2}>
        <Dropdown size="sm" onChange={handleChange} value={inputs.type} name="type">
          <option value="MIN">Min</option>
          <option value="MAX">Max</option>
        </Dropdown>
        <Input type="number" size="sm" value={inputs.value} onChange={handleChange} placeholder="0" name="value" />
      </Action>
    </FilterItem>
  );
};

export const Filter: React.FC<Props> = ({
  onSubmit,
  filters,
  defaultValues,
  onReset,
  onChange,
  compact,
  noPortal = true,
}) => {
  const router = useRouter();
  const filterRef = useRef<HTMLDivElement>(null);
  const filterPopoverRef = useRef<PopoverRef>(null);
  const [routerInitialized, setRouterInitialized] = useState(false);
  const countries = useCountries();

  const initialFilters = useMemo(
    () =>
      filters.reduce(
        (memo, filter) => ({ ...memo, [filter.id]: filter.value || false }),
        {} as Record<FilterItemType['id'], FilterItemType['value']>
      ),
    [filters]
  );

  const { inputs, setInputs } = useForm(initialFilters);

  const activeFilters = useMemo(() => {
    return Object.entries(inputs).filter(([, v]) => v !== false);
  }, [inputs]);

  useEffect(() => {
    const filtersFromQuery = Object.entries(router.query).reduce((memo, [k, v]) => {
      if (k.startsWith(SEARCH_PARAM_PREFIX)) {
        const key = k.substring(SEARCH_PARAM_PREFIX.length);
        memo[key] = v;
      }
      return memo;
    }, {} as Record<string, ParsedUrlQuery[keyof ParsedUrlQuery]>);
    const combinedFilters = { ...inputs, ...filtersFromQuery };
    setInputs(combinedFilters);
    onSubmit?.(combinedFilters);
    setRouterInitialized(true);
  }, []);

  useEffect(() => {
    (async () => {
      if (!routerInitialized) {
        return;
      }
      await router.replace(
        {
          pathname: router.pathname,
          query: {
            ...Object.entries(router.query).reduce((memo, [k, v]) => {
              if (!k.startsWith(SEARCH_PARAM_PREFIX)) {
                memo[k] = v;
              }
              return memo;
            }, {} as Record<string, ParsedUrlQuery[keyof ParsedUrlQuery]>),
            ...activeFilters.reduce((memo, f) => ({ ...memo, [`${SEARCH_PARAM_PREFIX}${f[0]}`]: f[1] }), {}),
          },
        },
        undefined,
        { shallow: true }
      );
    })();
  }, [activeFilters, routerInitialized]);

  const handleReset = (filtersToReset: typeof filters) => {
    const updatedInputs = Object.keys(inputs).reduce((memo, key) => {
      const targetFilter = filtersToReset.find(filtersToReset => filtersToReset.id === key);
      if (!targetFilter) {
        return { ...memo, [key]: inputs[key] };
      }
      const defaultOption = (defaultValues || []).find(option => option.id === key);
      return { ...memo, [key]: defaultOption ? defaultOption.value : false };
    }, {});
    setInputs(updatedInputs);
    onReset?.(updatedInputs);
  };

  useOuterClick(filterRef, () => {
    setInputs(initialFilters);
  });

  const handleChangeBasic = (name: keyof typeof inputs, value) => {
    setInputs({ [name]: value });
    onChange?.(name, value || null);
  };

  const handleToggle = (filter: FilterItemType) => {
    let value: FilterItemValue | undefined;

    if (typeof inputs[filter.id] === 'undefined' || inputs[filter.id] === false) {
      if (typeof filter.preselectedOption === 'function') {
        value = filter.preselectedOption(filter.options);
      } else if (filter.preselectedOption) {
        value = filter.preselectedOption;
      }
      if (!value) {
        value = filter?.options?.[0]?.id ?? '';
      }
    } else {
      value = false;
    }

    handleChangeBasic(filter.id, value);
  };

  const handleDatePickerToggle = (e: React.ChangeEvent<HTMLInputElement>, filter: FilterItemType) => {
    const { checked } = e.target;
    const value = checked
      ? [
          DATE_PICKER_DEFAULT_VALUES.dateConfiguration,
          DATE_PICKER_DEFAULT_VALUES.startDate,
          DATE_PICKER_DEFAULT_VALUES.endDate,
        ]
      : false;
    handleChangeBasic(filter.id, value);
  };

  const handleDataRangeToggle = (e: React.ChangeEvent<HTMLInputElement>, filter: FilterItemType) => {
    const { checked } = e.target;
    const value = checked ? ['MIN', '0'] : false;
    handleChangeBasic(filter.id, value);
  };

  const handleFilterSubmit = () => {
    filterPopoverRef.current?.togglePopover();
    onSubmit?.(inputs);
  };

  const handleChange: ChangeEventHandler<HTMLSelectElement> = e => handleChangeBasic(e.target.name, e.target.value);

  const handleCountryChange: ChangeEventHandler<HTMLSelectElement> = e => {
    const value = countries.find(i => i.code === e.target.value)?.id;
    handleChangeBasic(e.target.name, value);
  };

  const otherActiveFilters: typeof activeFilters = [];
  filters.forEach(filter => {
    const currentActiveFilter = activeFilters.find(activeFilter => activeFilter[0] === filter.id);
    if (currentActiveFilter) {
      otherActiveFilters.push(currentActiveFilter);
    }
  });

  return (
    <>
      {filters.length > 0 && (
        <Popover
          ref={filterPopoverRef}
          noPortal={noPortal}
          trigger={
            <Button
              icon={
                <IconContainer compact={compact}>
                  <IconFilter />
                </IconContainer>
              }
              right={<CountStyle active={otherActiveFilters.length > 0}>{otherActiveFilters.length}</CountStyle>}>
              {compact ? null : 'Filter'}
            </Button>
          }>
          <Container ref={filterRef}>
            <Header>
              <Button size="sm" onClick={() => handleReset(filters)}>
                Clear
              </Button>
              <Title>Filters</Title>
              <Button size="sm" onClick={handleFilterSubmit} variant="primary">
                Done
              </Button>
            </Header>
            {filters.map(filter =>
              filter.isDatePicker ? (
                <DatePickerFilter
                  title={filter.title}
                  key={filter.id}
                  name={filter.id}
                  toggleChange={e => handleDatePickerToggle(e, filter)}
                  toggleValue={typeof inputs[filter.id] !== 'undefined' && inputs[filter.id] !== false}
                  value={inputs[filter.id] ? (inputs[filter.id] as string[]) : []}
                  onChange={handleChangeBasic}
                />
              ) : filter.isMultiSelect ? (
                <FilterItem
                  key={filter.id}
                  name={filter.id}
                  value={typeof inputs[filter.id] !== 'undefined' && inputs[filter.id] !== false}
                  title={filter.title}
                  onChange={() => handleToggle(filter)}>
                  <FilterMultiSelect
                    name={filter.id}
                    placeholder={filter.options?.length === 0 ? 'No items' : filter.placeholder}
                    options={(filter.options || []).map(option => {
                      return {
                        id: option.id.toString(),
                        value: option.id.toString(),
                        label: option.title,
                      };
                    })}
                    value={inputs[filter.id] as string[]}
                    searchQuery={filter.searchQuery}
                    onSearchChange={filter.onSearchChange}
                    onChange={e => {
                      handleChangeBasic(
                        e.target.name,
                        Array.from(e.target.selectedOptions).map(o => o.value)
                      );
                    }}
                    disabled={filter.options?.length === 0}
                  />
                </FilterItem>
              ) : filter.isRange ? (
                <RangePicker
                  key={filter.id}
                  name={filter.id}
                  value={inputs[filter.id] ? (inputs[filter.id] as string[]) : []}
                  title={filter.title}
                  onChange={handleChangeBasic}
                  toggleChange={e => handleDataRangeToggle(e, filter)}
                  toggleValue={typeof inputs[filter.id] !== 'undefined' && inputs[filter.id] !== false}
                />
              ) : (
                <FilterItem
                  key={filter.id}
                  onChange={() => handleToggle(filter)}
                  name={filter.id}
                  value={typeof inputs[filter.id] !== 'undefined' && inputs[filter.id] !== false}
                  title={filter.title}>
                  {filter.isCountrySelector ? (
                    <CountrySelector
                      countries={countries}
                      size="sm"
                      onChange={handleCountryChange}
                      value={
                        typeof inputs[filter.id] !== 'undefined'
                          ? (countries.find(i => i.id === inputs[filter.id])?.code || '').toString()
                          : ''
                      }
                      name={filter.id}
                      disabled={filter.options?.length === 0}
                      flags={flags}
                      wide
                    />
                  ) : (
                    <Dropdown
                      size="sm"
                      onChange={handleChange}
                      value={typeof inputs[filter.id] !== 'undefined' ? (inputs[filter.id] || '').toString() : ''}
                      name={filter.id}
                      disabled={filter.options?.length === 0}
                      wide>
                      {filter.options?.map(option => (
                        <option key={option.id} value={option.id}>
                          {option.title}
                        </option>
                      ))}
                      {filter.options?.length === 0 && (
                        <option>
                          {filter.emptyOptionsPlaceHolder ? filter.emptyOptionsPlaceHolder : 'No options'}
                        </option>
                      )}
                    </Dropdown>
                  )}
                </FilterItem>
              )
            )}
          </Container>
        </Popover>
      )}
    </>
  );
};
