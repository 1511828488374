import { Children, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Tag } from '@layerise/design-core';

const TagsWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;

  span {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 24px;
  }
`;

const InlineTagsWrapper = styled(TagsWrapper)`
  display: inline-flex;
`;

type InfoTagsProps = PropsWithChildren<{
  items?: { id?: string; label: ReactNode }[] | null;
  onClick?: MouseEventHandler;
  className?: string;
  Container?: React.ComponentType<any>;
}>;

type InfoTagProps = Omit<InfoTagsProps, 'items'>;

export function InfoTag({ children, onClick, className }: InfoTagProps) {
  return (
    <InfoTags
      className={className}
      onClick={onClick}
      Container={InlineTagsWrapper}
      items={Children.toArray(children).map(c => ({ label: c }))}
    />
  );
}

export function InfoTags({ items, className, onClick, children, Container = TagsWrapper }: InfoTagsProps) {
  return (
    <Container className={className} onClick={onClick}>
      {items?.map((i, idx) => (
        <Tag key={i.id ?? `${idx}:${i.label}`} variant="info">
          {i.label}
        </Tag>
      ))}
      {children}
    </Container>
  );
}
