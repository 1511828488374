import { IconContainer } from './IconContainer';

export const IconChatUnassigned = () => (
  <IconContainer bgColor="#2f96e2">
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={14} height={14}>
      <g>
        <defs>
          <path
            d="M 12.6 7 C 12.6 10.093 10.093 12.6 7 12.6 C 3.907 12.6 1.4 10.093 1.4 7 C 1.4 3.907 3.907 1.4 7 1.4 C 10.093 1.4 12.6 3.907 12.6 7 Z M 7 3.5 C 6.613 3.5 6.3 3.813 6.3 4.2 L 6.3 7 C 6.3 7.387 6.613 7.7 7 7.7 C 7.387 7.7 7.7 7.387 7.7 7 L 7.7 4.2 C 7.7 3.813 7.387 3.5 7 3.5 Z M 7 9.1 C 6.613 9.1 6.3 9.413 6.3 9.8 C 6.3 10.187 6.613 10.5 7 10.5 C 7.387 10.5 7.7 10.187 7.7 9.8 C 7.7 9.413 7.387 9.1 7 9.1 Z"
            id="a1206z"
          />
          <filter id="a1208z" x="-37.2%" y="-46.0%" width="173.5%" height="191.2%" filterUnits="objectBoundingBox">
            <feOffset dx={0} dy={1} in="SourceAlpha" result="a1210z" />
            <feGaussianBlur stdDeviation={1} in="a1210z" result="a1211z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
              type="matrix"
              in="a1211z"
              result="a1212z"
            />
          </filter>
        </defs>
        <g filter="url(#a1208z)">
          <use
            fill="black"
            fillOpacity={1}
            stroke="black"
            strokeOpacity={0}
            strokeWidth={0}
            xlinkHref="#a1206z"
            clipPath="url(#a1207z)"
          />
        </g>
        <use xlinkHref="#a1206z" fill="hsl(0, 0%, 100%)" clipPath="url(#a1207z)" />
      </g>
    </svg>
  </IconContainer>
);
