export enum AppSection {
  LIBRARY = 'library',
  CONVERSATIONS = 'conversations',
  SERVICE = 'service',
  WORKSPACE_SETTINGS = 'workspaceSettings',
  ORGANISATION_SETTINGS = 'organisationSettings',
  MARKETING = 'marketing',
  AUTOMATION = 'automation',
  CUSTOMERS = 'customers',
  PRODUCTS = 'products',
  INSIGHTS = 'insights',
  FEEDBACKS = 'feedbacks',
}
