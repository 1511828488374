import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Client, Conversation, Message, Paginator } from '@twilio/conversations';
import { ChatMessage } from './types';
import { MemberContext } from 'contexts/MemberContext';
import { AppSection } from 'types/sections';

export const useChatClient = (accessToken: string | null) => {
  const { canView } = useContext(MemberContext);
  const canRead = canView(AppSection.CONVERSATIONS);

  const client = useMemo(() => {
    if (!accessToken || !canRead) return null;
    return new Client(accessToken);
  }, [accessToken, canRead]);

  return { client };
};

type ClientOptions = {
  onConversationAdded(conversation: Conversation): void;
  noMessages: boolean;
};

type MessagesHash = Record<Conversation['sid'], Message[]>;

export const useConversations = (
  client: Client | null,
  options: Partial<ClientOptions> = {},
  log = false
): [Conversation[], MessagesHash] => {
  const clientRef = useRef(client);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [messages, setMessages] = useState<MessagesHash>({});

  useEffect(() => {
    if (client !== clientRef.current) {
      setConversations([]);
    }

    clientRef.current = client;

    if (!client) return;

    if (!options.noMessages) {
      client.on('conversationAdded', async conversation => {
        log && console.log('added', conversation);
        options.onConversationAdded?.(conversation);

        const messages = await conversation.getMessages();
        setMessages(prevState => {
          const existingMessages = prevState[conversation.sid];
          return {
            ...prevState,
            [conversation.sid]: [...(existingMessages || []), ...messages.items],
          };
        });

        conversation.on('messageUpdated', ({ message }) => {
          setMessages(prevState => {
            const existingMessages = prevState[conversation.sid];
            return {
              ...prevState,
              [conversation.sid]: existingMessages.map(m => {
                return m.sid === message.sid ? message : m;
              }),
            };
          });
        });

        conversation.on('messageAdded', message => {
          setMessages(prevState => {
            const existingMessages = prevState[conversation.sid];
            return {
              ...prevState,
              [conversation.sid]: [...(existingMessages || []), message],
            };
          });
        });

        conversation.on('updated', ({ updateReasons }) => {
          if (updateReasons.includes('lastReadMessageIndex') || updateReasons.includes('lastMessage')) {
            setMessages(prevState => ({ ...prevState }));
          }
        });
      });
    }

    (async () => {
      let conversationsPaginator: Paginator<Conversation> | null = await client.getSubscribedConversations();
      let allConversations: Conversation[] = [];
      while (conversationsPaginator) {
        allConversations = allConversations.concat(conversationsPaginator.items);
        if (conversationsPaginator.hasNextPage) {
          conversationsPaginator = await conversationsPaginator.nextPage();
        } else {
          conversationsPaginator = null;
        }
      }

      setConversations(allConversations);

      client.on('conversationJoined', (conversation: Conversation) => {
        log && console.log('joined', conversation);
        setConversations(prevState => [...prevState, conversation]);
      });

      client.on('conversationLeft', (conversation: Conversation) => {
        log && console.log('left', conversation);
        setConversations(prevState => prevState.filter(c => c !== conversation));
      });
    })();
  }, [client]);

  return [conversations, messages];
};

export const useTypingIndicator = (convo: Conversation | undefined) => {
  const [isTyping, setTyping] = useState(false);

  useEffect(() => {
    if (convo) {
      convo.on('typingStarted', () => setTyping(true));
      convo.on('typingEnded', () => setTyping(false));
    }

    return () => {
      if (convo) {
        convo.removeAllListeners('typingStarted');
        convo.removeAllListeners('typingEnded');
      }
    };
  }, [convo]);

  return isTyping;
};

export const useSortedMessages = (messages: ChatMessage[]) => {
  return useMemo(() => {
    const uniqueIds = new Set<string>();
    const uniqueMessages: ChatMessage[] = [];

    messages.forEach(m => {
      const id = m.twilioId || m.id;
      if (!uniqueIds.has(id)) {
        uniqueMessages.push(m);
      }
      uniqueIds.add(id);
    });

    return uniqueMessages.sort((a, b) => {
      // TODO: explore sorting the messages by ID if possible
      // if (Number.isInteger(a.index) && Number.isInteger(b.index)) return a.index - b.index;
      // if (a.id.length === b.id.length) {
      //   if (a.id < b.id) return -1;
      //   if (a.id > b.id) return 1;
      //   return 0;
      // }
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
  }, [JSON.stringify(messages)]);
};
