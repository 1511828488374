import { useCallback, useContext } from 'react';
import ToastContext from 'contexts/ToastContext';
import type { ApolloError } from '@apollo/client';

export const useApiErrorHandler = (message?: string) => {
  const { setToast, clearToasts } = useContext(ToastContext);
  return useCallback(
    (error: ApolloError) => {
      clearToasts();
      setToast({ type: 'warning', message: message || error.message });
    },
    [setToast, clearToasts, message]
  );
};
