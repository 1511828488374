import { useState, createContext, useEffect, useCallback, ReactNode } from 'react';
import Toasts from 'components/Toasts';
import { v4 as uuid } from 'uuid';
import { useRouter } from 'next/router';

export type ToastType = {
  type: 'warning' | 'success';
  message: string;
  id?: string;
  timeout?: number;
  href?: string;
  as?: string;
  loading?: boolean;
  handleClick?(): void;
};

export const TOASTS_QUERY_PARAMS: Record<string | number, ToastType> = {
  403: {
    type: 'warning',
    message: `You don't have access or permissions to visit this destination. Redirecting back to home`,
  },
  articleArchived: {
    type: 'warning',
    message: 'The article you tried to access is archived. Reactivate it to continue',
  },
  smsCampaignArchived: {
    type: 'warning',
    message: 'The campaign you tried to access is archived, reactivate it to continue',
  },
  highlightArchived: {
    type: 'warning',
    message:
      'The highlight you tried to access is for an assistant that is no longer active. Reactivate it to continue',
  },
};

interface IToastContext {
  setToast({ type, message, timeout, href, as, id, loading }: ToastType): void;
  clearToasts(): void;
}

export const ToastContext = createContext<IToastContext>(undefined!);

export const ToastProvider = ({ children }: { children?: ReactNode }) => {
  const router = useRouter();
  const [toastList, setToastList] = useState<ToastType[]>([]);
  const setToast = useCallback(({ type, message, timeout, href, id, loading, as, handleClick }: ToastType) => {
    setToastList([{ type, message, timeout, href, as, loading, id: id ? id : uuid(), handleClick }]);
  }, []);

  const clearToasts = useCallback(() => {
    setToastList([]);
  }, []);

  useEffect(() => {
    const { t, ...restQuery } = router.query;
    if (t) {
      setToast(TOASTS_QUERY_PARAMS[t as string]);
      router.replace(
        {
          query: restQuery,
        },
        undefined,
        { shallow: true }
      );
    }
  }, [router.query.t]);

  return (
    <ToastContext.Provider value={{ setToast, clearToasts }}>
      {toastList.length > 0 && <Toasts toastList={toastList} />}
      {children}
    </ToastContext.Provider>
  );
};

export const ToastConsumer = ToastContext.Consumer;
export default ToastContext;
