import { IconContainer } from './IconContainer';

export const IconApps = () => (
  <IconContainer bgColor="#474666">
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14" height="14">
      <g>
        <defs>
          <path
            d="M 3.5 2.1 C 2.727 2.1 2.1 2.727 2.1 3.5 L 2.1 4.9 C 2.1 5.673 2.727 6.3 3.5 6.3 L 4.9 6.3 C 5.673 6.3 6.3 5.673 6.3 4.9 L 6.3 3.5 C 6.3 2.727 5.673 2.1 4.9 2.1 Z M 3.5 7.7 C 2.727 7.7 2.1 8.327 2.1 9.1 L 2.1 10.5 C 2.1 11.273 2.727 11.9 3.5 11.9 L 4.9 11.9 C 5.673 11.9 6.3 11.273 6.3 10.5 L 6.3 9.1 C 6.3 8.327 5.673 7.7 4.9 7.7 Z M 7.7 3.5 C 7.7 2.727 8.327 2.1 9.1 2.1 L 10.5 2.1 C 11.273 2.1 11.9 2.727 11.9 3.5 L 11.9 4.9 C 11.9 5.673 11.273 6.3 10.5 6.3 L 9.1 6.3 C 8.327 6.3 7.7 5.673 7.7 4.9 Z"
            id="a1002z"
          />
          <filter id="a1004z" x="-42.4%" y="-52.5%" width="183.8%" height="204.0%" filterUnits="objectBoundingBox">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="a1006z" />
            <feGaussianBlur stdDeviation="1" in="a1006z" result="a1007z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
              type="matrix"
              in="a1007z"
              result="a1008z"
            />
          </filter>
        </defs>
        <g filter="url(#a1004z)">
          <use
            fill="black"
            fillOpacity="1"
            stroke="black"
            strokeOpacity="0"
            strokeWidth="0"
            xlinkHref="#a1002z"
            clipPath="url(#a1003z)"
          />
        </g>
        <use xlinkHref="#a1002z" fill="hsl(0, 0%, 100%)" clipPath="url(#a1003z)" />
      </g>
      <path
        d="M 9.8 7.7 C 10.187 7.7 10.5 8.013 10.5 8.4 L 10.5 9.1 L 11.2 9.1 C 11.587 9.1 11.9 9.413 11.9 9.8 C 11.9 10.187 11.587 10.5 11.2 10.5 L 10.5 10.5 L 10.5 11.2 C 10.5 11.587 10.187 11.9 9.8 11.9 C 9.413 11.9 9.1 11.587 9.1 11.2 L 9.1 10.5 L 8.4 10.5 C 8.013 10.5 7.7 10.187 7.7 9.8 C 7.7 9.413 8.013 9.1 8.4 9.1 L 9.1 9.1 L 9.1 8.4 C 9.1 8.013 9.413 7.7 9.8 7.7 Z"
        fill="rgba(255, 255, 255, 0.5)"
      />
    </svg>
  </IconContainer>
);
