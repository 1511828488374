import { ComponentProps, PropsWithChildren, createContext, useCallback, useEffect, useRef, useState } from 'react';
import { SideDrawer } from 'components/layout/SideDrawer';
import { useOuterClick } from '@layerise/utils';
import { CONNECTION_BUTTON_CLASS_NAME } from 'components/connections/ConnectionsButton';
import { getFirstParentMatch } from 'lib/utils';
import { useRouter } from 'next/router';

type DrawerProps = Omit<ComponentProps<typeof SideDrawer>, 'open' | 'onClose'>;

type IContext = {
  openDrawer(props: DrawerProps): void;
  updateContent(props: Partial<DrawerProps>): void;
  animationFinished: boolean;
};

export const SideDrawerContext = createContext<IContext>(undefined!);

export function SideDrawerProvider({ children }: PropsWithChildren) {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [drawerProps, setDrawerProps] = useState<DrawerProps | null>(null);
  const [animationFinished, setAnimationFinished] = useState(false);
  const sidebarRef = useRef<HTMLElement>(null);
  const router = useRouter();

  const openDrawer: IContext['openDrawer'] = useCallback(props => {
    setDrawerProps(props);
    setDrawerOpened(true);
  }, []);

  const updateContent: IContext['updateContent'] = useCallback(props => {
    setDrawerProps(prev => ({ ...prev, ...props }));
  }, []);

  useOuterClick(sidebarRef, (e: React.MouseEvent & { target: Element }) => {
    if (
      e.target.classList.contains(CONNECTION_BUTTON_CLASS_NAME) ||
      getFirstParentMatch(e.target, `.${CONNECTION_BUTTON_CLASS_NAME}`)
    )
      return;

    setDrawerOpened(false);
  });

  useEffect(() => {
    const handleRouteChange = () => {
      setDrawerOpened(false);
      setAnimationFinished(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  return (
    <SideDrawerContext.Provider value={{ openDrawer, updateContent, animationFinished }}>
      {children}
      <SideDrawer
        ref={sidebarRef}
        open={drawerOpened}
        onExitComplete={() => setAnimationFinished(false)}
        onAnimationComplete={() => setAnimationFinished(true)}
        onClose={() => setDrawerOpened(false)}
        {...drawerProps}
      />
    </SideDrawerContext.Provider>
  );
}
