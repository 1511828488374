import * as React from 'react';
import { SVGProps } from 'react';

const IconUpsellSerialNumbers = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={16} height={16} {...props}>
    <defs>
      <filter id="a" width="186.2%" height="221.7%" x="-43.5%" y="-61.3%" filterUnits="objectBoundingBox">
        <feOffset dy={1.33} in="SourceAlpha" result="a1004z" />
        <feGaussianBlur in="a1004z" result="a1005z" stdDeviation={1.335} />
        <feColorMatrix
          colorInterpolationFilters="sRGB"
          in="a1005z"
          result="a1006z"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
      </filter>
      <path
        id="b"
        d="M7.394 2.424a.8.8 0 0 1 .582.97L7.624 4.8h2.352l.448-1.794a.8.8 0 1 1 1.552.388L11.624 4.8H13.6a.8.8 0 1 1 0 1.6h-2.376l-.8 3.2H12a.8.8 0 1 1 0 1.6h-1.976l-.448 1.794a.8.8 0 1 1-1.552-.388l.352-1.406H6.024l-.448 1.794a.8.8 0 1 1-1.552-.388l.352-1.406H2.4a.8.8 0 1 1 0-1.6h2.376l.8-3.2H4a.8.8 0 1 1 0-1.6h1.976l.448-1.794a.8.8 0 0 1 .97-.582ZM7.224 6.4l-.8 3.2h2.35l.8-3.2H7.225Z"
      />
    </defs>
    <g filter="url(#a)">
      <use xlinkHref="#b" stroke="#000" strokeOpacity={0} strokeWidth={0} clipPath="url(#a1001z)" />
    </g>
    <use xlinkHref="#b" fill="#FFF" clipPath="url(#a1001z)" />
  </svg>
);

export default IconUpsellSerialNumbers;
