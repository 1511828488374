import { useContext, useEffect } from 'react';
import { ModalContext } from '@layerise/design-core';
import { useLazyQuery } from '@apollo/client';
import { MemberBackupCodesDocument } from 'types/typed-document-nodes';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { BackupCodesModal } from './BackupCodesModal';
import { ContentViewType, CurrentMemberProfileModal } from 'components/settings/workspace/members/ManageMemberModal';

export function BackupCodesAwareness() {
  const { me } = useContext(OrganisationContext);
  const { showModal } = useContext(ModalContext);
  const [getRecoveryCodes] = useLazyQuery(MemberBackupCodesDocument);

  useEffect(() => {
    if (me?.twoFactorAuthEnabled && me?.recoveryDataShown === false) {
      (async () => {
        const { data } = await getRecoveryCodes();
        if (!data?.recoveryData?.codes) return;
        showModal(BackupCodesModal, {
          recoveryData: data.recoveryData,
          actionRequired: true,
          initialDisplay: true,
          onDownload() {
            showModal(CurrentMemberProfileModal, { activeTab: ContentViewType.Security });
          },
        });
      })();
    }
  }, [me?.twoFactorAuthEnabled, me?.recoveryDataShown, showModal, getRecoveryCodes]);

  return null;
}
