import { Skeleton } from '@layerise/design-core';
import React from 'react';
import { StatusTag } from '../../tag/StatusTag';
import { MetaInfo } from '../../table/MetaInfo';
import { ConnectionsButton } from 'components/connections/ConnectionsButton';
import NoWrapColumn from '../../table/NoWrapColumn';
import { LibraryItemsType } from '../hooks/useData';
import { TagList } from 'components/tag/TagList';
import { LocaleConnectionsTag } from 'components/connections/LocaleConnectionsTag';
import { CountryConnectionsTag } from 'components/connections/CountryConnectionsTag';

interface ColumnProps {
  warranty: Extract<LibraryItemsType, { __typename: 'Warranty' }>;
  selected?: boolean;
}

export const warrantiesHeaderItems = [
  {
    id: '1',
    title: 'name',
  },
  {
    id: '2',
    title: 'status',
  },
  {
    id: '3',
    title: 'tags',
  },
  {
    id: '4',
    title: 'languages',
  },
  {
    id: '5',
    title: 'countries',
  },
  {
    id: '6',
    title: 'products',
  },
  {
    id: '7',
    title: 'ID',
  },
  {
    id: '8',
    title: 'updated by',
    padding: '0 8px',
    width: '110',
    align: 'left',
  },
];

export const warrantiesLoadingView = [
  {
    id: 'skeleton',
    columns: [
      { id: '0', width: 16, content: <Skeleton height="16px" width="16px" /> },
      { id: '1', content: <Skeleton /> },
      {
        id: '2',
        content: <Skeleton />,
      },
      {
        id: '3',
        content: <Skeleton />,
      },
      {
        id: '4',
        content: <Skeleton />,
      },
      {
        id: '5',
        content: <Skeleton />,
      },
      {
        id: '6',
        content: <Skeleton />,
      },
      {
        id: '7',
        content: <Skeleton />,
      },
      {
        id: '8',
        content: <Skeleton />,
      },
    ],
  },
];

export const warrantyColumns = ({ warranty, selected }: ColumnProps) => [
  {
    id: '1',
    content: warranty.warrantyName || '',
    fontWeight: 600,
    fontColor: 'var(--color-N600)',
    fontSize: 14,
  },
  {
    id: '2',
    content: <StatusTag archived={warranty.metaInfo?.archived} published={warranty.metaInfo?.published} />,
  },
  {
    id: '3',
    content: <TagList viewOnly multiLine={false} tags={warranty.metaInfo?.tags} />,
  },
  {
    id: '4',
    content: <LocaleConnectionsTag entityName="warranty" locales={warranty.metaInfo?.locales} />,
  },
  {
    id: '5',
    content: <CountryConnectionsTag entityName="warranty" countries={warranty.countries} />,
  },
  {
    id: '6',
    content: <ConnectionsButton number={warranty?.metaInfo?.connections || 0} />,
  },
  {
    id: '7',
    content: <NoWrapColumn>{warranty.metaInfo?.reference}</NoWrapColumn>,
  },
  {
    id: '8',
    content: (
      <MetaInfo transactionLogs={warranty?.transactionLogs} updatedAt={warranty.updatedAt} selected={selected} />
    ),
  },
];
