import { IconContainer } from './IconContainer';

export const IconMarketingSms = () => (
  <IconContainer bgColor="#7a52d6">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
      <path
        d="M 8.4 1.4 C 8.718 1.4 8.996 1.614 9.077 1.921 L 9.902 5.04 L 12.25 6.394 C 12.467 6.519 12.6 6.75 12.6 7 C 12.6 7.25 12.467 7.481 12.25 7.606 L 9.902 8.961 L 9.076 12.079 C 8.995 12.386 8.717 12.6 8.4 12.6 C 8.082 12.6 7.805 12.386 7.723 12.079 L 6.898 8.96 L 4.55 7.606 C 4.333 7.481 4.2 7.25 4.2 7 C 4.2 6.75 4.333 6.519 4.55 6.394 L 6.898 5.039 L 7.724 1.921 C 7.805 1.614 8.083 1.4 8.4 1.4 Z"
        fill="hsl(0, 0%, 100%)"
      />
      <path
        d="M 3.5 1.4 C 3.887 1.4 4.2 1.713 4.2 2.1 L 4.2 2.8 L 4.9 2.8 C 5.287 2.8 5.6 3.113 5.6 3.5 C 5.6 3.887 5.287 4.2 4.9 4.2 L 4.2 4.2 L 4.2 4.9 C 4.2 5.287 3.887 5.6 3.5 5.6 C 3.113 5.6 2.8 5.287 2.8 4.9 L 2.8 4.2 L 2.1 4.2 C 1.713 4.2 1.4 3.887 1.4 3.5 C 1.4 3.113 1.713 2.8 2.1 2.8 L 2.8 2.8 L 2.8 2.1 C 2.8 1.713 3.113 1.4 3.5 1.4 Z"
        fill="rgba(255, 255, 255, 0.54)"
      />
      <path
        d="M 3.5 8.4 C 3.887 8.4 4.2 8.713 4.2 9.1 L 4.2 9.8 L 4.9 9.8 C 5.287 9.8 5.6 10.113 5.6 10.5 C 5.6 10.887 5.287 11.2 4.9 11.2 L 4.2 11.2 L 4.2 11.9 C 4.2 12.287 3.887 12.6 3.5 12.6 C 3.113 12.6 2.8 12.287 2.8 11.9 L 2.8 11.2 L 2.1 11.2 C 1.713 11.2 1.4 10.887 1.4 10.5 C 1.4 10.113 1.713 9.8 2.1 9.8 L 2.8 9.8 L 2.8 9.1 C 2.8 8.713 3.113 8.4 3.5 8.4 Z"
        fill="rgba(255, 255, 255, 0.54)"
      />
    </svg>
  </IconContainer>
);
