import { SVGProps } from 'react';

export const IconDocuments = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <path
      d="M6.3 1.4a1.4 1.4 0 0 0-1.4 1.4v5.6a1.4 1.4 0 0 0 1.4 1.4h4.2a1.4 1.4 0 0 0 1.4-1.4V4.49c0-.371-.148-.728-.41-.99L9.8 1.81a1.402 1.402 0 0 0-.99-.41Z"
      fill="currentColor"
    />
    <path
      d="M2.1 5.6a1.4 1.4 0 0 1 1.4-1.4v7h5.6a1.4 1.4 0 0 1-1.4 1.4H3.5a1.4 1.4 0 0 1-1.4-1.4Z"
      fill="currentColor"
    />
  </svg>
);
