import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { Img } from '@layerise/design-core';
import { Workspace } from 'types/typed-document-nodes';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 12px 0;
  position: relative;
  cursor: pointer;

  &::before {
    position: absolute;
    left: 0;
    display: block;
    height: 24px;
    width: 4px;
    top: 4px;
    background-color: #ffffff;
    border-radius: 0 7px 7px 0;
  }

  &[aria-selected='true']::before,
  &[aria-selected='false']:hover::before {
    content: '';
  }

  &[aria-selected='false']:hover::before {
    opacity: 0.5;
  }
`;

const Icon = styled.div<{ bgColor?: string | null; color?: string }>`
  background-color: ${props => props.bgColor || 'var(--color-N600)'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color || '#ffffff'};
  font-weight: 700;
  width: 100%;
`;

const IconContainer = styled.div<{ borderRadius?: number; noBorder?: boolean; shadow?: boolean }>`
  display: flex;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : 6)}px;
  width: 32px;
  height: 32px;
  ${props =>
    !props.noBorder &&
    css`
      border: 1px solid rgba(255, 255, 255, 0.2);
    `}
  ${props =>
    props.shadow &&
    css`
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    `}
  overflow: hidden;
`;

interface WorkspaceIconProps extends PropsWithChildren {
  workspace?: Pick<Workspace, 'id' | 'name' | 'brandColor'> & {
    iconImg: Pick<NonNullable<Workspace['iconImg']>, 'dynamic'> | null;
  };
  className?: string;
  borderRadius?: number;
  noBorder?: boolean;
  shadow?: boolean;
  charCount?: number;
}

export const WorkspaceIcon: React.FC<WorkspaceIconProps> = ({
  workspace,
  className,
  borderRadius,
  noBorder,
  shadow = true,
  charCount,
  children,
}) => {
  const workspaceIcon = workspace?.iconImg?.dynamic;
  return (
    <IconContainer className={className} borderRadius={borderRadius} noBorder={noBorder} shadow={shadow}>
      {workspace ? (
        <Icon color={workspaceIcon ? '#140038' : '#fff'} bgColor={workspaceIcon ? '#fff' : workspace.brandColor}>
          {workspaceIcon ? (
            <Img src={workspaceIcon} alt={workspace.name || ''} />
          ) : (
            <span>{workspace.name?.substring(0, charCount || 1)}</span>
          )}
        </Icon>
      ) : (
        <Icon>{children}</Icon>
      )}
    </IconContainer>
  );
};

interface MinibarWorkspaceIconProps extends WorkspaceIconProps {
  active?: boolean;
}

export const MinibarWorkspaceIcon: React.FC<MinibarWorkspaceIconProps> = ({ active, workspace }) => (
  <Container aria-selected={active}>
    <WorkspaceIcon workspace={workspace} />
  </Container>
);
