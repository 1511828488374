import { IconContainer } from './IconContainer';

export const IconChatInbox = () => (
  <IconContainer bgColor="#2f96e2">
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={14} height={14}>
      <g>
        <defs>
          <path
            d="M 1.4 3.5 C 1.4 2.727 2.027 2.1 2.8 2.1 L 7.7 2.1 C 8.473 2.1 9.1 2.727 9.1 3.5 L 9.1 6.3 C 9.1 7.073 8.473 7.7 7.7 7.7 L 6.3 7.7 L 4.2 9.8 L 4.2 7.7 L 2.8 7.7 C 2.027 7.7 1.4 7.073 1.4 6.3 Z"
            id="a1178z"
          />
          <filter id="a1180z" x="-53.8%" y="-66.7%" width="206.4%" height="232.1%" filterUnits="objectBoundingBox">
            <feOffset dx={0} dy={1} in="SourceAlpha" result="a1182z" />
            <feGaussianBlur stdDeviation={1} in="a1182z" result="a1183z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
              type="matrix"
              in="a1183z"
              result="a1184z"
            />
          </filter>
        </defs>
        <g filter="url(#a1180z)">
          <use
            fill="black"
            fillOpacity={1}
            stroke="black"
            strokeOpacity={0}
            strokeWidth={0}
            xlinkHref="#a1178z"
            clipPath="url(#a1179z)"
          />
        </g>
        <use xlinkHref="#a1178z" fill="hsl(0, 0%, 100%)" clipPath="url(#a1179z)" />
      </g>
      <g>
        <defs>
          <path
            d="M 10.5 4.9 L 10.5 6.3 C 10.5 7.846 9.246 9.1 7.7 9.1 L 6.88 9.1 L 5.643 10.337 C 5.839 10.441 6.063 10.5 6.3 10.5 L 7.7 10.5 L 9.8 12.6 L 9.8 10.5 L 11.2 10.5 C 11.973 10.5 12.6 9.873 12.6 9.1 L 12.6 6.3 C 12.6 5.527 11.973 4.9 11.2 4.9 Z"
            id="a1185z"
          />
          <filter id="a1187z" x="-59.5%" y="-66.7%" width="217.6%" height="232.1%" filterUnits="objectBoundingBox">
            <feOffset dx={0} dy={1} in="SourceAlpha" result="a1189z" />
            <feGaussianBlur stdDeviation={1} in="a1189z" result="a1190z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
              type="matrix"
              in="a1190z"
              result="a1191z"
            />
          </filter>
        </defs>
        <g filter="url(#a1187z)">
          <use
            fill="black"
            fillOpacity={1}
            stroke="black"
            strokeOpacity={0}
            strokeWidth={0}
            xlinkHref="#a1185z"
            clipPath="url(#a1186z)"
          />
        </g>
        <use xlinkHref="#a1185z" fill="rgba(255, 255, 255, 0.5)" clipPath="url(#a1186z)" />
      </g>
    </svg>
  </IconContainer>
);
