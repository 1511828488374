import { AccessLevel, MemberType, WorkspaceMembersQuery } from 'types/typed-document-nodes';
type Member = NonNullable<NonNullable<WorkspaceMembersQuery['workspace']>['members']>[number];
export type PlatformArea = Exclude<keyof Member['workspacePermissions'][number]['accessLevel'], '__typename'>;
type PlatformAreaPermissionConfig = { accessLevel: AccessLevel; editable: boolean };

export const MemberTypePermissionsPreset: Record<MemberType, Record<PlatformArea, PlatformAreaPermissionConfig>> = {
  [MemberType.Standard]: {
    library: { accessLevel: AccessLevel.Contributor, editable: true },
    conversations: { accessLevel: AccessLevel.Contributor, editable: true },
    service: { accessLevel: AccessLevel.Contributor, editable: true },
    workspaceSettings: { accessLevel: AccessLevel.Contributor, editable: true },
    marketing: { accessLevel: AccessLevel.Contributor, editable: true },
    automation: { accessLevel: AccessLevel.Contributor, editable: true },
    customers: { accessLevel: AccessLevel.Contributor, editable: true },
  },
  [MemberType.LiteContentMarketer]: {
    library: { accessLevel: AccessLevel.Contributor, editable: true },
    conversations: { accessLevel: AccessLevel.NoAccess, editable: false },
    service: { accessLevel: AccessLevel.NoAccess, editable: false },
    workspaceSettings: { accessLevel: AccessLevel.NoAccess, editable: false },
    marketing: { accessLevel: AccessLevel.NoAccess, editable: false },
    automation: { accessLevel: AccessLevel.NoAccess, editable: false },
    customers: { accessLevel: AccessLevel.NoAccess, editable: false },
  },
  [MemberType.LiteCustomerSupport]: {
    library: { accessLevel: AccessLevel.Viewer, editable: false },
    conversations: { accessLevel: AccessLevel.Contributor, editable: true },
    service: { accessLevel: AccessLevel.Contributor, editable: true },
    workspaceSettings: { accessLevel: AccessLevel.NoAccess, editable: false },
    marketing: { accessLevel: AccessLevel.NoAccess, editable: false },
    automation: { accessLevel: AccessLevel.NoAccess, editable: false },
    customers: { accessLevel: AccessLevel.Contributor, editable: true },
  },
};

export function memberTypeAreaPermissions(memberType: MemberType) {
  return function accessLevelGetter(area: PlatformArea) {
    return MemberTypePermissionsPreset[memberType][area].accessLevel;
  };
}
