import styled, { css } from 'styled-components';
import { Checkbox } from '@layerise/design-core';
import { AnimatePresence, m } from 'framer-motion';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  name: string;
  title: string;
  value: unknown;
  noCheckbox?: boolean;
}>;

const Item = styled(m.div)<{
  selected?: boolean;
}>`
  height: ${props => (props.selected ? 'auto' : '40px')};
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${props => (props.selected ? '#F7F7FA' : '#ffffff')};
  width: 234px;
  border-bottom: 1px solid var(--color-N100);
  label {
    font-size: 13px;
    color: var(--color-N500);
    font-weight: 500;
  }
  span {
    margin-left: 8px;
  }
  ${props =>
    props.selected &&
    css`
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex-flow: column wrap;
      align-content: stretch;
      gap: 4px;
    `};
`;

const Container = styled.div``;

export const FilterItem: React.FC<Props> = ({ onChange, title, name, value, children, noCheckbox }) => {
  return (
    <AnimatePresence>
      <Container>
        {!noCheckbox && (
          <Item>
            <Checkbox
              wide
              minHeight={40}
              size="sm"
              onChange={typeof onChange === 'function' ? onChange : () => {}}
              name={name}
              checked={Boolean(value)}
              label={title}
            />
          </Item>
        )}
        {value ? (
          <Item
            key={name}
            initial={{ y: -4, opacity: 0.8 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -4, opacity: 0 }}
            selected={Boolean(value)}>
            {children}
          </Item>
        ) : null}
      </Container>
    </AnimatePresence>
  );
};
