export const loaderStyle = `
  @keyframes logoAnimation {
    0% { 
      width: 56px;
      height: 56px;
      box-shadow: 0px 4px 8px 0px rgba(0, 33, 219, 0.12);
    }
  20% {
      width: 59px;
      height: 59px;
  }
  30% {
      width: 62px;
      height: 62px;
  }
  80% { 
      width: 63px;
      height: 63px;
      box-shadow: 0px 4px 14px 0px rgba(0, 33, 219, 0.24);
  }
  90% { 
      width: 64px;
      height: 64px;
      box-shadow: 0px 4px 14px 0px rgba(0, 33, 219, 0.24);
  }
  100% { 
      width: 59px;
      height: 59px;
    }
  }

  @keyframes innerBorderAnimation { 
    0% { 
      border-width: 0px;
      opacity: 0;
      top:0px;
      left:0px;
    }
  20% {
      border-width: 0px;
      opacity: 0.6;
      top:0px;
      left:0px;
  }
  30% {
      border-width: 6px;
      opacity: 0.8;
      top:-6px;
      left:-6px;
  }
  70% { 
      border-width: 8px;
      opacity: 1;
      top:-8px;
      left:-8px;
  }
  90% { 
      border-width: 8px;
      opacity: 1;
      top:-8px;
      left:-8px;
  }
  100% { 
      border-width: 6px;
      opacity: 0.5;
      top: -6px;
      left: -6px;
    }
  }

  @keyframes outerBorderAnimation { 
    0% { 
      border-width: 0px;
      opacity: 0;
      top: 0px;
      left: 0px;
    }
  20% {
      border-width: 0px;
      opacity: 0.4;
      top:0px;
      left:0px;
  }
  30% {
      border-width: 14px;
      opacity: 0.8;
      top:-14px;
      left:-14px;
  }
  70% { 
      border-width: 18px;
      opacity: 1;
      top: -18px;
      left: -18px;
  }
  90% { 
      border-width: 18px;
      opacity: 1;
      top: -18px;
      left: -18px;
  }
  100% { 
      border-width: 12px;
      opacity: 0.5;
      top: -12px;
      left: -12px;
    }
  }

  #loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 100;
  }

  #loader-circle-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #loader-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: linear-gradient(208deg, #052bfc 0%, #001fd1 100%);
    overflow: visible;
    aspect-ratio: 1 / 1;
    border-radius: 305px;
    animation-name: logoAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    position: absolute;
  }

  #loader-inner-shadow,
  #outer-inner-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 305px;
    box-sizing: content-box;
  }

  #loader-inner-shadow {
    z-index: -1;
    border: 0px solid rgba(5, 43, 252, 0.15);
    animation: innerBorderAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
  }
  #outer-inner-shadow {
    z-index: -2;
    border: 0px solid rgba(5, 43, 252, 0.1);
    animation: outerBorderAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
  }

  #loader-circle svg {
    height: 70%;
  }
`;

export const loaderHtml = (
  <div id="loader-container">
    <div id="loader-circle-wrapper">
      <div id="loader-circle">
        <div id="loader-inner-shadow"></div>
        <div id="outer-inner-shadow"></div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="48">
          <g>
            <defs>
              <path
                d="M 27.512 4.689 L 19.301 19.17 L 31.653 19.17 L 27.06 27.339 L 6.399 27.339 L 19.201 4.689 Z M 20.171 39.593 L 11.865 39.593 L 16.989 30.494 L 26.307 28.679 Z"
                id="a1000z"></path>
              <filter id="a1002z" x="-51.8%" y="-42.0%" width="203.1%" height="183.7%" filterUnits="objectBoundingBox">
                <feOffset dx="0" dy="1.57" in="SourceAlpha" result="a1004z"></feOffset>
                <feGaussianBlur stdDeviation="3.125" in="a1004z" result="a1005z"></feGaussianBlur>
                <feColorMatrix
                  colorInterpolationFilters="sRGB"
                  values="0 0 0 0 0.078   0 0 0 0 0   0 0 0 0 0.22  0 0 0 0.32 0"
                  type="matrix"
                  in="a1005z"
                  result="a1006z"></feColorMatrix>
              </filter>
            </defs>
            <g filter="url(#a1002z)">
              <use
                fill="black"
                fillOpacity="1"
                stroke="black"
                strokeOpacity="0"
                strokeWidth="0"
                xlinkHref="#a1000z"
                clipPath="url(#a1001z)"></use>
            </g>
            <use xlinkHref="#a1000z" fill="hsl(0, 0%, 100%)" clipPath="url(#a1001z)"></use>
          </g>
        </svg>
      </div>
    </div>
  </div>
);

export const hideLoader = () => {
  if (typeof window !== 'undefined') {
    const loader = document.getElementById('loader-container');
    if (loader) loader.style.display = 'none';
  }
};
