import Cookies from 'js-cookie';

export const hubspotForm = async ({ formId, fields }) => {
  const isBrowser = typeof window !== 'undefined';
  const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
  const pageUrl = isBrowser ? window.location.href : null;
  const pageName = isBrowser ? document.title : null;

  if (process.env.NODE_ENV !== 'production') {
    return await {
      status: 200,
    };
  }

  var data = {
    submittedAt: Date.now(),
    fields: fields,
    context: {
      hutk: hutk,
      pageUri: pageUrl,
      pageName: pageName,
    },
  };
  const portalId = '8190476';
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;
  const bodyData = JSON.stringify(data);

  async function submitResponse() {
    try {
      let response = await fetch(url, {
        method: 'post',
        body: bodyData,
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });
      const responseData = await response;
      //  console.log(responseData);
    } catch (err) {
      console.log(err);
    }
  }
  submitResponse();
};

export const configureConversationWidget = ({ token, email }) => {
  if (!window) return;

  if (email && token) {
    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: email,
      identificationToken: token,
    };
    if (window.HubSpotConversations) {
      const status = window.HubSpotConversations.widget.status();
      if (status.loaded) {
        window.HubSpotConversations.widget.refresh();
      } else {
        window.HubSpotConversations.widget.load();
      }
    }
  } else {
    window.hsConversationsSettings = {
      loadImmediately: true,
    };
  }
};
