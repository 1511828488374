import { Toast } from '@layerise/design-core';
import React from 'react';
import Link from 'next/link';

interface ToastProps {
  toastList: {
    id?: string;
    type: 'warning' | 'info' | 'success';
    variant?: 'warning' | 'info' | 'success';
    loading?: boolean;
    timeout?: number;
    href?: string;
    as?: string;
    message?: string;
    handleClick?(): void;
  }[];
}

const Toasts: React.FC<ToastProps> = ({ toastList }) => {
  return (
    <>
      {toastList.map(toast => (
        <Toast
          onClick={toast.href || toast.as ? undefined : toast.handleClick}
          key={toast.id}
          variant={toast.type || toast.variant}
          timeout={toast.timeout || 6000}
          loading={toast.loading}>
          {toast.href ? (
            <Link href={toast.href} as={toast.as} onClick={toast.handleClick} style={{ color: '#fff' }}>
              {toast.message}
            </Link>
          ) : (
            toast.message
          )}
        </Toast>
      ))}
    </>
  );
};
export default Toasts;
