import { ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { AccessLevelDropdown } from './AccessLevelDropdown';
import { PermissionOptions } from './PermissionOptions';
import { AccessLevel } from 'types/typed-document-nodes';

const Container = styled.div<{ $disabled?: boolean }>`
  display: grid;
  grid-template-columns: 32px auto 132px;
  align-items: center;
  margin-bottom: 16px;
  gap: 4px;

  ${props =>
    props.$disabled &&
    css`
      opacity: 0.5;
      ${AccessLevelDropdown} {
        opacity: 1;
        > select {
          cursor: default;
        }
      }
    `}
`;

const PermissionItemTitle = styled.div`
  font-size: 14px;
  line-height: 1;
  margin-bottom: 4px;
  margin-left: 4px;
  color: var(--color-N600);
`;

const PermissionItemDescription = styled.div`
  color: rgba(20, 0, 56, 0.74);
  font-size: 12px;
  line-height: 1;
  margin-left: 4px;
`;

type Props = {
  disabled?: boolean;
  readonly?: boolean;
  option: (typeof PermissionOptions)[number];
  value: AccessLevel;
  handleChange: ComponentProps<typeof AccessLevelDropdown>['onChange'];
};

export function PermissionItem({ disabled, option, value, readonly, handleChange }: Props) {
  return (
    <Container $disabled={disabled}>
      <div>{option.icon}</div>
      <div>
        <PermissionItemTitle>{option.title}</PermissionItemTitle>
        <PermissionItemDescription>{option.description[value]}</PermissionItemDescription>
      </div>
      <AccessLevelDropdown name={option.key} value={value} onChange={handleChange} disabled={disabled || readonly} />
    </Container>
  );
}
