import { IconContainer } from './IconContainer';

export const IconLibComponents = () => (
  <IconContainer bgColor="#fe871a">
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
      <path
        d="M 5.427 0.6 L 11.307 1.554 L 10.407 7.425 L 8.624 4.573 L 7.068 7.065 L 4.499 6.647 Z"
        fill="rgb(255, 255, 255)"
      />
      <path
        d="M 4.158 3.925 C 3.012 3.431 1.688 3.716 0.83 4.641 C -0.028 5.565 -0.243 6.938 0.29 8.093 C 0.821 9.248 1.99 9.946 3.23 9.848 C 4.469 9.751 5.521 8.877 5.876 7.652 L 3.642 7.29 L 4.159 3.924 L 4.159 3.925 Z"
        fill="rgba(255, 255, 255, 0.5)"
      />
      <path d="M 5.25 11.4 L 8.624 6 L 12 11.4 Z" fill="rgb(255, 255, 255)" />
    </svg>
  </IconContainer>
);
