import { useContext, useMemo } from 'react';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { QueryHookOptions, useQuery } from '@apollo/client';
import {
  ContentItemStatus,
  ContentLibraryDocument,
  ContentLibraryQuery,
  CustomerListsDocument,
  CustomerListsQuery,
  CustomerListType,
  FormType,
  FormWhereInput,
  LibraryAllItemsDocument,
  LibraryAllItemsQueryVariables,
  LibraryItemType,
  ProductListingQuery,
  WarrantyWhereInput,
  WorkspaceFormsDocument,
  WorkspaceFormsQuery,
  WorkspaceFormsQueryVariables,
  WorkspacesDocument,
  WorkspacesQuery,
  WorkspaceWarrantyIdsListingDocument,
} from 'types/typed-document-nodes';
import { useApiErrorHandler } from 'hooks/useApiErrorHandler';
import { hasValueAtKey } from 'ts-is-present';

export type ProductFragment = ProductListingQuery['products'][number];
export type LibraryItemsType = Exclude<ContentLibraryQuery['library']['items'][number], { __typename: 'Assistant' }>;
export type WorkspaceFragment = NonNullable<NonNullable<WorkspacesQuery['workspaces']>[number]>;
export type FormFragment = NonNullable<NonNullable<WorkspaceFormsQuery['forms']>[number]>;
export type CustomerListsFragment = NonNullable<NonNullable<CustomerListsQuery['customerLists']>[number]>;
export type WarrantyFragment = Extract<LibraryItemsType, { __typename: 'Warranty' }>;
export type AssistantFragment = Extract<ContentLibraryQuery['library']['items'][number], { __typename: 'Assistant' }>;

export enum ProductType {
  Product = 'product',
}

export enum WorkspaceType {
  Workspace = 'workspace',
}

export enum FormsType {
  Form = 'form',
}

export enum CustomersListType {
  CustomersList = 'customersList',
}

export enum WarrantyListType {
  Warranty = 'Warranty',
}

export type PickerType =
  | LibraryItemType
  | ProductType
  | WorkspaceType
  | FormsType
  | CustomersListType
  | WarrantyListType;

export type FilterType = Partial<{
  status: ContentItemStatus | null;
  createdAt: string[] | null;
  query: string | null;
  tagId: string;
  createdById: string | null;
  productId: string | null;
  allowedSubtype: LibraryItemType.Image | LibraryItemType.Video;
  countryId: string | null;
  categoryId: string;
  localeIds: string[];
  assistantIds: string[];
  connections: string[];
  memberId: string;
  categoryIds: string[];
}>;

export interface DataProps {
  types: LibraryItemType[];
  itemsPerPage: number;
  page: number;
  filter: FilterType;
}

export const isMediaType = (type: PickerType) => {
  return type === LibraryItemType.Image || type === LibraryItemType.Video || type === LibraryItemType.Pdf;
};

export const getLibraryItemTitleByType = (libraryItem: LibraryItemsType) => {
  switch (libraryItem.__typename) {
    case 'Article':
      return libraryItem.articleTitle;
    case 'Warranty':
      return libraryItem.warrantyName;
    case 'Story':
      return libraryItem.storyTitle;
    case 'Image':
      return libraryItem.name;
    case 'Video':
      return libraryItem.name;
    case 'Pdf':
      return libraryItem.title;
  }

  return null;
};

export function mapLibraryFilter(filter: DataProps['filter'], types: DataProps['types']) {
  return {
    allowedTypes: filter.allowedSubtype ? [filter.allowedSubtype] : types,
    createdAt: filter.createdAt || undefined,
    status: filter.status || undefined,
    query: filter.query || undefined,
    tagIds: filter.tagId ? (Array.isArray(filter.tagId) ? filter.tagId : [filter.tagId]) : undefined,
    createdById: filter.createdById || undefined,
    productId: filter.productId || undefined,
    countryId: filter.countryId || undefined,
    localeIds: filter.localeIds || undefined,
    assistantIds: filter.assistantIds
      ? ((Array.isArray(filter.assistantIds) ? filter.assistantIds : [filter.assistantIds]) as string[])
      : undefined,
    ...(!!filter.connections?.length
      ? {
          ...(filter.connections[0] === 'MIN'
            ? { minConnections: parseInt(filter.connections[1]) }
            : { maxConnections: parseInt(filter.connections[1]) }),
        }
      : undefined),
  };
}

export const useLibraryData = ({ types, itemsPerPage, page, filter }: DataProps) => {
  const { workspace } = useContext(OrganisationContext);

  return useQuery(ContentLibraryDocument, {
    variables: {
      workspaceId: workspace.id,
      skip: itemsPerPage * page,
      limit: itemsPerPage,
      filters: mapLibraryFilter(filter, types),
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: useApiErrorHandler(),
  });
};

type WorkspaceDataProps = Omit<DataProps, 'types' | 'filter'> & {
  organisationId?: string;
  name?: string;
  excludedIds?: string[];
};

export const useWorkspaceData = ({ organisationId, name, excludedIds, itemsPerPage, page }: WorkspaceDataProps) => {
  const { data, loading, error, refetch } = useQuery(WorkspacesDocument, {
    variables: {
      organisationId: organisationId,
      name: name,
      excludedIds: excludedIds,
      skip: itemsPerPage * page,
      first: itemsPerPage,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return {
    data,
    loading,
    error,
    refetch,
  };
};

type FormsDataProps = Omit<DataProps, 'types'> & { filter: { type?: FormType } };

export const useFormsData = (
  { filter, itemsPerPage, page }: FormsDataProps,
  queryOptions?: QueryHookOptions<WorkspaceFormsQuery, WorkspaceFormsQueryVariables>
) => {
  const { workspace } = useContext(OrganisationContext);
  const handleApiError = useApiErrorHandler();

  const where: FormWhereInput = {
    type: filter.type || undefined,
    workspaceId: workspace.id,
    name_contains: filter.query || undefined,
    ...(filter.productId && {
      OR: [
        {
          products_some: {
            id: filter.productId,
          },
        },
        {
          formProducts_some: {
            productId: filter.productId,
          },
        },
      ],
    }),
    ...(filter.createdById && {
      createdBy: {
        id: filter.createdById,
      },
    }),
    ...(filter.createdAt && {
      createdAt_matches: filter.createdAt,
    }),
    ...(filter.status === ContentItemStatus.Active && {
      published: true,
      archived: false,
    }),
    ...(filter.status === ContentItemStatus.Draft && {
      published: false,
      archived: false,
    }),
    ...(filter.status === ContentItemStatus.Archived && {
      published: false,
      archived: true,
    }),
  };
  const { data, loading, error, refetch } = useQuery(WorkspaceFormsDocument, {
    variables: {
      where,
      skip: itemsPerPage * page,
      limit: itemsPerPage,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (...args) => {
      handleApiError(...args);
      queryOptions?.onError?.(...args);
    },
    onCompleted: queryOptions?.onCompleted,
  });

  return {
    data,
    loading,
    error,
    refetch,
  };
};

type WarrantiesDataProps = Omit<DataProps, 'types' | 'filter'> & {
  filters: ActiveFilters;
};

export type ActiveFilters = {
  [key in 'status' | 'productId' | 'countryId' | 'createdById']?: string | undefined;
};

export const warrantyWhere = (workspaceId: string, filters: ActiveFilters): WarrantyWhereInput => {
  const status = filters.status ? (filters.status as ContentItemStatus) : undefined;

  return {
    workspace: { id: workspaceId },
    ...(filters.createdById && {
      createdBy: {
        id: filters.createdById as string,
      },
    }),
    ...(status === ContentItemStatus.Active && {
      metaInfo: {
        published: true,
        archived: false,
      },
    }),
    ...(status === ContentItemStatus.Draft && {
      metaInfo: {
        published: false,
        archived: false,
      },
    }),
    ...(status === ContentItemStatus.Archived && {
      metaInfo: {
        published: false,
        archived: true,
      },
    }),
    ...(filters.productId && {
      productWarranties_some: {
        productId: filters.productId as string,
      },
    }),
    ...(filters.countryId && {
      countries_some: {
        id: filters.countryId as string,
      },
    }),
  };
};

export const useWarrantiesData = ({ filters, itemsPerPage, page }: WarrantiesDataProps) => {
  const { data, ...restQueryResults } = useLibraryData({
    types: [LibraryItemType.Warranty],
    itemsPerPage,
    page,
    filter: {
      status: filters.status as ContentItemStatus,
      productId: filters.productId,
      createdById: filters.createdById,
      countryId: filters.countryId,
    },
  });

  const filteredData = useMemo(() => {
    if (!data) return undefined;
    return {
      items: data.library.items.filter(hasValueAtKey('__typename', 'Warranty' as const)),
      total: data.library.total,
    };
  }, [data]);

  return {
    data: filteredData,
    ...restQueryResults,
  };
};

export const useAllWarrantiesIds = ({ filters }: Pick<WarrantiesDataProps, 'filters'>) => {
  const { workspace } = useContext(OrganisationContext);
  const { data, ...restQueryResults } = useQuery(WorkspaceWarrantyIdsListingDocument, {
    variables: {
      where: warrantyWhere(workspace.id, filters),
    },
  });

  const warrantiesData = useMemo(() => {
    return data ? { items: data.warranties } : undefined;
  }, [data]);

  return {
    data: warrantiesData,
    ...restQueryResults,
  };
};

export const useAllLibraryItemsIds = (
  types: DataProps['types'],
  filters: NonNullable<LibraryAllItemsQueryVariables['filters']>
) => {
  const { workspace } = useContext(OrganisationContext);
  const { data, ...restQueryResults } = useQuery(LibraryAllItemsDocument, {
    variables: {
      workspaceId: workspace.id,
      filters: mapLibraryFilter(filters, types),
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const itemsData = useMemo(() => {
    return data ? { items: data.library.items } : undefined;
  }, [data]);

  return {
    data: itemsData,
    ...restQueryResults,
  };
};

export const useCustomersListsData = ({ itemsPerPage, page }: Omit<DataProps, 'types' | 'filter'>) => {
  const { workspace } = useContext(OrganisationContext);
  const { data, loading, error, refetch } = useQuery(CustomerListsDocument, {
    variables: {
      listType: CustomerListType.Static,
      workspaceId: workspace.id,
      skip: itemsPerPage * page,
      limit: itemsPerPage,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return {
    data,
    loading,
    error,
    refetch,
  };
};
