import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { LocalesQueryDocument, LocalesQueryQuery } from 'types/typed-document-nodes';

interface Locale {
  id: string;
  title: string;
}

export const useLocales = (): LocalesQueryQuery['locales'] => {
  const { data } = useQuery(LocalesQueryDocument);

  return useMemo(() => {
    return data?.locales || [];
  }, [data?.locales]);
};

export const useLocalesFilterOptions = (): Locale[] => {
  const locales = useLocales();

  return useMemo(
    () =>
      locales.map(l => ({
        id: l.id,
        title: l.nameEN,
      })),
    [locales]
  );
};
