import React from 'react';
import ItemColumn from './ItemColumn';
import { Checkbox, Skeleton } from '@layerise/design-core';
import { ConnectionsButton } from 'components/connections/ConnectionsButton';
import { FormFragment } from '../hooks/useData';
import { StatusTag } from 'components/tag/StatusTag';
import { MetaInfo } from 'components/table/MetaInfo';
import { CheckboxWrapper } from './styles';

interface ColumnProps {
  item: FormFragment;
  onClick: (itemId: string) => void;
  openConnectionsSidebar: React.Dispatch<React.SetStateAction<string>>;
  selected: boolean;
  multi?: boolean;
}

interface HeaderProps {
  onSelectAll: () => void;
  selected: boolean;
  multi?: boolean;
}

export const formsLoadingView = (multi?: boolean) => [
  {
    id: 'skeleton',
    columns: [
      ...(multi ? [{ id: '0', width: 16, content: <Skeleton height="16px" width="16px" /> }] : []),
      {
        id: '1',
        content: <Skeleton />,
      },
      {
        id: '2',
        content: <Skeleton />,
      },
      {
        id: '3',
        content: <Skeleton />,
      },
      {
        id: '4',
        content: <Skeleton />,
      },
      {
        id: '5',
        content: <Skeleton />,
      },
    ],
  },
];

export const formsHeaderItems = ({ onSelectAll, selected, multi }: HeaderProps) => [
  ...(multi
    ? [
        {
          id: '0',
          visible: true,
          content: (
            <CheckboxWrapper>
              <Checkbox
                onChange={() => {
                  onSelectAll();
                }}
                value={`all`}
                checked={selected}
                name={`all`}
                size="sm"
              />
            </CheckboxWrapper>
          ),
        },
      ]
    : []),
  {
    id: '1',
    title: 'name',
  },
  {
    id: '2',
    title: 'status',
  },
  {
    id: '3',
    title: 'products',
  },
  {
    id: '4',
    title: 'type',
  },
  {
    id: '5',
    title: 'updated by',
    padding: '0 8px',
    width: '110',
    align: 'left',
  },
];

export const formsColumns = ({ item, onClick, selected, openConnectionsSidebar, multi }: ColumnProps) => {
  return [
    ...(multi
      ? [
          {
            id: '0',
            width: 16,
            content: (
              <CheckboxWrapper>
                <Checkbox
                  onChange={() => {
                    onClick(item.id);
                  }}
                  value={item.id}
                  checked={selected}
                  name={item.id}
                  size="sm"
                />
              </CheckboxWrapper>
            ),
          },
        ]
      : []),
    {
      id: '1',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          {item.name}
        </ItemColumn>
      ),
      fontWeight: 600,
      fontColor: 'var(--color-N600)',
      fontSize: 14,
    },
    {
      id: '2',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          <StatusTag archived={item.archived} published={item.published} />
        </ItemColumn>
      ),
      fontWeight: 600,
      fontColor: 'var(--color-N600)',
      fontSize: 14,
    },
    {
      id: '3',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          <ConnectionsButton
            number={item.connections || 0}
            onClick={e => {
              e.stopPropagation();
              openConnectionsSidebar(prev => {
                return prev === item.id ? '' : item.id || '';
              });
            }}
          />
        </ItemColumn>
      ),
    },
    {
      id: '4',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          {item.type}
        </ItemColumn>
      ),
    },
    {
      id: '86',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          <MetaInfo transactionLogs={item.updatedLogItems} updatedAt={item.updatedAt} selected={selected} />
        </ItemColumn>
      ),
    },
  ];
};
