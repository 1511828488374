import React from 'react';

export const IconConnection = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
    <g>
      <g>
        <path
          d="M 11.083 8.75 C 10.344 8.752 9.685 9.219 9.438 9.917 L 6.417 9.917 C 5.772 9.917 5.25 9.394 5.25 8.75 C 5.25 8.106 5.772 7.583 6.417 7.583 L 7.583 7.583 C 8.872 7.583 9.917 6.539 9.917 5.25 C 9.917 3.961 8.872 2.917 7.583 2.917 L 4.562 2.917 C 4.265 2.077 3.385 1.594 2.517 1.795 C 1.649 1.996 1.071 2.816 1.173 3.701 C 1.276 4.586 2.026 5.253 2.917 5.25 C 3.656 5.248 4.315 4.781 4.562 4.083 L 7.583 4.083 C 8.228 4.083 8.75 4.606 8.75 5.25 C 8.75 5.894 8.228 6.417 7.583 6.417 L 6.417 6.417 C 5.128 6.417 4.083 7.461 4.083 8.75 C 4.083 10.039 5.128 11.083 6.417 11.083 L 9.438 11.083 C 9.735 11.923 10.615 12.406 11.483 12.205 C 12.351 12.004 12.929 11.184 12.827 10.299 C 12.724 9.414 11.974 8.747 11.083 8.75 Z M 2.917 4.083 C 2.595 4.083 2.333 3.822 2.333 3.5 C 2.333 3.178 2.595 2.917 2.917 2.917 C 3.239 2.917 3.5 3.178 3.5 3.5 C 3.5 3.822 3.239 4.083 2.917 4.083 Z"
          fill="hsl(0, 0%, 100%)"></path>
        <path d="M 0 0 L 14 0 L 14 14 L 0 14 Z" fill="transparent"></path>
      </g>
    </g>
  </svg>
);
