import { IconShortcut } from '@layerise/design-icon';
import styled from 'styled-components';

const Action = styled.a`
  height: 24px;
  width: 24px;
  box-shadow: var(--depth-Z100);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  svg {
    height: 14px;
    width: 14px;
  }

  svg path {
    fill: #140068;
  }
  &:hover {
    cursor: pointer;
    background-color: #140068;
    svg path {
      fill: #fff;
    }
  }
`;

type Props = {
  href: string;
  className?: string;
};

export function NewTabLinkButton({ href, className }: Props) {
  return (
    <Action className={className} href={href} target="_blank" onClick={e => e.stopPropagation()}>
      <IconShortcut />
    </Action>
  );
}
