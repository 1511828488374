import { IconContainer } from './IconContainer';

export const IconAutomationForms = () => (
  <IconContainer bgColor="#db4dab">
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="11.78" height="11.78">
      <defs>
        <filter id="PRu-y3sKir" width="212.8%" height="240%" x="-57.1%" y="-70.7%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="a1018z" />
          <feGaussianBlur in="a1018z" result="a1019z" stdDeviation="1" />
          <feColorMatrix
            colorInterpolationFilters="sRGB"
            in="a1019z"
            result="a1020z"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
        </filter>
        <path
          id="0wuZLx4iz8"
          d="M3.85.52A.7.7 0 1 0 2.5.88l.18.68a.7.7 0 0 0 1.36-.36L3.85.52ZM.88 2.5a.7.7 0 1 0-.36 1.35l.68.18a.7.7 0 0 0 .36-1.35L.88 2.5Zm6.17-.4a.7.7 0 0 0-.99-.99l-.5.5a.7.7 0 1 0 1 .99ZM2.1 7.05l.5-.5a.7.7 0 1 0-1-.98l-.49.49a.7.7 0 1 0 1 1Z"
        />
      </defs>
      <g filter="url(#PRu-y3sKir)">
        <use xlinkHref="#0wuZLx4iz8" stroke="#000" strokeOpacity="0" strokeWidth="0" clipPath="url(#a1015z)" />
      </g>
      <use xlinkHref="#0wuZLx4iz8" fill="rgba(255, 255, 255, 0.5)" clipPath="url(#a1015z)" />
      <defs>
        <filter id="0fEYz4JH6k" width="197.6%" height="221.2%" x="-49.4%" y="-61.2%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="a1011z" />
          <feGaussianBlur in="a1011z" result="a1012z" stdDeviation="1" />
          <feColorMatrix
            colorInterpolationFilters="sRGB"
            in="a1012z"
            result="a1013z"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
        </filter>
        <path
          id="lr07-D7hFC"
          d="M4.34 3.43a.7.7 0 0 0-.9.91l2.8 7a.7.7 0 0 0 1.27.05l.97-1.93 2.1 2.12a.7.7 0 0 0 1-1l-2.12-2.1 1.94-.97a.7.7 0 0 0-.06-1.28Z"
        />
      </defs>
      <g filter="url(#0fEYz4JH6k)">
        <use xlinkHref="#lr07-D7hFC" stroke="#000" strokeOpacity="0" strokeWidth="0" clipPath="url(#a1008z)" />
      </g>
      <use xlinkHref="#lr07-D7hFC" fill="#FFF" clipPath="url(#a1008z)" />
    </svg>
  </IconContainer>
);
