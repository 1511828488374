import { IconContainer } from './IconContainer';

export const IconChatForYou = () => (
  <IconContainer bgColor="#2f96e2">
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={14} height={14}>
      <g>
        <defs>
          <path
            d="M 5.6 6.3 C 6.76 6.3 7.7 5.36 7.7 4.2 C 7.7 3.04 6.76 2.1 5.6 2.1 C 4.44 2.1 3.5 3.04 3.5 4.2 C 3.5 5.36 4.44 6.3 5.6 6.3 Z M 5.6 7.7 C 7.92 7.7 9.8 9.58 9.8 11.9 L 1.4 11.9 C 1.4 9.58 3.28 7.7 5.6 7.7 Z"
            id="a1192z"
          />
          <filter id="a1194z" x="-49.4%" y="-52.5%" width="197.6%" height="204.0%" filterUnits="objectBoundingBox">
            <feOffset dx={0} dy={1} in="SourceAlpha" result="a1196z" />
            <feGaussianBlur stdDeviation={1} in="a1196z" result="a1197z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
              type="matrix"
              in="a1197z"
              result="a1198z"
            />
          </filter>
        </defs>
        <g filter="url(#a1194z)">
          <use
            fill="black"
            fillOpacity={1}
            stroke="black"
            strokeOpacity={0}
            strokeWidth={0}
            xlinkHref="#a1192z"
            clipPath="url(#a1193z)"
          />
        </g>
        <use xlinkHref="#a1192z" fill="hsl(0, 0%, 100%)" clipPath="url(#a1193z)" />
      </g>
      <g>
        <defs>
          <path
            d="M 11.2 4.9 C 11.2 4.513 10.887 4.2 10.5 4.2 C 10.113 4.2 9.8 4.513 9.8 4.9 L 9.8 5.6 L 9.1 5.6 C 8.713 5.6 8.4 5.913 8.4 6.3 C 8.4 6.687 8.713 7 9.1 7 L 9.8 7 L 9.8 7.7 C 9.8 8.087 10.113 8.4 10.5 8.4 C 10.887 8.4 11.2 8.087 11.2 7.7 L 11.2 7 L 11.9 7 C 12.287 7 12.6 6.687 12.6 6.3 C 12.6 5.913 12.287 5.6 11.9 5.6 L 11.2 5.6 Z"
            id="a1199z"
          />
          <filter id="a1201z" x="-97.7%" y="-120.9%" width="293.0%" height="339.5%" filterUnits="objectBoundingBox">
            <feOffset dx={0} dy={1} in="SourceAlpha" result="a1203z" />
            <feGaussianBlur stdDeviation={1} in="a1203z" result="a1204z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
              type="matrix"
              in="a1204z"
              result="a1205z"
            />
          </filter>
        </defs>
        <g filter="url(#a1201z)">
          <use
            fill="black"
            fillOpacity={1}
            stroke="black"
            strokeOpacity={0}
            strokeWidth={0}
            xlinkHref="#a1199z"
            clipPath="url(#a1200z)"
          />
        </g>
        <use xlinkHref="#a1199z" fill="rgba(255, 255, 255, 0.5)" clipPath="url(#a1200z)" />
      </g>
    </svg>
  </IconContainer>
);
