import * as React from 'react';
import { SVGProps } from 'react';

const IconUpsellReports = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path d="M1.6 8A6.4 6.4 0 0 1 8 1.6V8h6.4A6.4 6.4 0 0 1 8 14.4 6.4 6.4 0 0 1 1.6 8Z" fill="hsl(0, 0%, 100%)" />
    <path d="M9.6 1.802A6.41 6.41 0 0 1 14.198 6.4H9.6Z" fill="rgba(255, 255, 255, 0.54)" />
  </svg>
);

export default IconUpsellReports;
