import { ApolloQueryResult } from '@apollo/client';
import { Button, Card, ModalContext } from '@layerise/design-core';
import { NORMAL_MODAL_Z_INDEX } from 'components/featureModals/constants';
import { DeleteMemberModal } from 'components/settings/DeleteMemberModal';
import { Heading, Title } from 'components/settings/elements';
import { useContext } from 'react';
import { EditMemberFragmentFragment } from 'types/typed-document-nodes';
import { FieldDescription, FieldTitle, Item, Save } from '../elements';

type Props = {
  member: EditMemberFragmentFragment;
  readonly: boolean;
  refetch(): Promise<ApolloQueryResult<unknown>[]> | Promise<ApolloQueryResult<unknown>>;
  onRequestClose(): void;
};

export function AdvancedView({ member, readonly, refetch, onRequestClose }: Props) {
  const { showModal } = useContext(ModalContext);
  return (
    <section>
      <Heading>Advanced</Heading>
      <Title>Delete</Title>
      <Card none>
        <Item>
          <div>
            <FieldTitle>Delete member</FieldTitle>
            <FieldDescription>Member will be deleted immediately from the workspace</FieldDescription>
          </div>
        </Item>

        <Save>
          <Button
            type="submit"
            variant="warning"
            size="lg"
            restricted={readonly}
            onClick={() => {
              showModal(DeleteMemberModal, {
                member,
                scope: 'workspace',
                zIndex: NORMAL_MODAL_Z_INDEX + 1,
                async onCompleted() {
                  await refetch();
                  onRequestClose();
                },
              });
            }}>
            Delete member
          </Button>
        </Save>
      </Card>
    </section>
  );
}
