import { useWindowDimensions } from '@layerise/utils';
import { useEffect } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';

export const isMobileViewport = makeVar(false);

export const useMobileFlag = () => {
  return useReactiveVar(isMobileViewport);
};

export default function useMobileBreakpoint() {
  const { width } = useWindowDimensions();

  useEffect(() => {
    isMobileViewport(Math.round(width) <= 800);
  }, [width]);

  return null;
}
