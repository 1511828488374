import * as Sentry from '@sentry/nextjs';
import { NextApiHandler, NextApiRequest, NextApiResponse } from 'next';

export const initSentry = () => {
  const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      tracesSampleRate: 0.1,
      debug: process.env.NODE_ENV === 'development',
      enabled: process.env.NODE_ENV !== 'development',
      environment:
        process.env.VERCEL_GIT_COMMIT_REF === 'dev' ? 'development' : process.env.VERCEL_ENV || process.env.NODE_ENV,
    });
  }
};

// todo: this is a custom Sentry handler, because it seems like default one does not work
// todo: more details can be found here: https://github.com/getsentry/sentry-javascript/issues/3917
export const withSentry = (handler: NextApiHandler) => {
  initSentry();
  return async (req: NextApiRequest, res: NextApiResponse) => {
    try {
      return await handler(req, res);
    } catch (e) {
      Sentry.captureException(e);
      return new Promise<void>(resolve => {
        Sentry.flush(2000).finally(() => {
          res.status(500).send('Sorry, something went wrong. Please try again later.');
          resolve();
        });
      });
    }
  };
};
