import * as React from 'react';
import { SVGProps } from 'react';

const IconUpsellRegistrationScore = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={16} height={16} {...props}>
    <path
      d="M 7 0 C 10.866 0 14 3.134 14 7 C 14 10.866 10.866 14 7 14 C 3.134 14 0 10.866 0 7 C 0 3.134 3.134 0 7 0 Z M 2.269 3.842 C 2.068 3.96 1.944 4.177 1.944 4.413 L 1.944 9.587 C 1.944 9.823 2.068 10.04 2.269 10.158 L 6.676 12.745 C 6.876 12.863 7.124 12.863 7.324 12.745 L 11.731 10.158 C 11.932 10.04 12.056 9.823 12.056 9.587 L 12.056 4.413 C 12.056 4.177 11.932 3.96 11.731 3.842 L 7.324 1.255 C 7.124 1.137 6.876 1.137 6.676 1.255 Z M 6.333 8.833 L 7.667 8.833 L 7.667 7.5 L 6.333 7.5 Z M 6.333 6.167 L 7.667 6.167 L 7.667 4.833 L 6.333 4.833 Z M 6.333 10.167 C 5.597 10.167 5 9.57 5 8.833 L 5 7.833 C 5 7.281 5.448 6.833 6 6.833 C 5.448 6.833 5 6.386 5 5.833 L 5 4.833 C 5 4.097 5.597 3.5 6.333 3.5 L 7.667 3.5 C 8.403 3.5 9 4.097 9 4.833 L 9 5.833 C 9 6.386 8.552 6.833 8 6.833 C 8.552 6.833 9 7.281 9 7.833 L 9 8.833 C 9 9.57 8.403 10.167 7.667 10.167 Z"
      fill="hsl(0, 0%, 100%)"></path>
  </svg>
);

export default IconUpsellRegistrationScore;
