import { Button, ModalActions, Modal, Img } from '@layerise/design-core';
import { IconInfo } from '@layerise/design-icon';
import React from 'react';
import { LearnMore, Content, Body, TitleContainer, Title, ModalHero, TitleIconContainer } from './SharedStyles';
import { NORMAL_MODAL_Z_INDEX, FeatureModalProps } from './constants';
import IconImport from 'components/icons/IconImport';
import { chatWidget } from 'lib/chatWidget';

export const DataImport = (props: FeatureModalProps) => {
  const handleClick = async () => {
    props.onRequestClose();
    chatWidget('show');
  };

  return (
    <Modal
      onClick={props.onRequestClose}
      modalContainerStyle={{ zIndex: NORMAL_MODAL_Z_INDEX + 1 }}
      containerStyle={{ zIndex: NORMAL_MODAL_Z_INDEX + 2 }}
      padding="0px 0px 0px 0px"
      height="608px"
      width="520px">
      <ModalHero withBorder>
        <Img
          alt="workspace import"
          src="WWW/featureAddon/workspace_import-cover.png"
          height={776}
          width={1040}
          quality={80}
        />
      </ModalHero>
      <Content>
        <TitleContainer>
          <TitleIconContainer>
            <IconImport width={16} height={16} viewBox="0 0 16 16" />
          </TitleIconContainer>
          <Title>Data import</Title>
        </TitleContainer>
        <Body>
          Supporting multiple white-label products? Save time and repetitive tasks with Data import. Move data like
          products and their related Assistants to other workspaces.
        </Body>
        <ModalActions
          negative={
            <LearnMore
              href={`${process.env.URL_WWW}/help/my-account/managing-a-workspace/import-data-from-a-workspace`}
              target="_blank"
              rel="noreferrer">
              Learn more <IconInfo />
            </LearnMore>
          }>
          <Button size="lg" variant="primary" onClick={handleClick}>
            Talk with sales
          </Button>
        </ModalActions>
      </Content>
    </Modal>
  );
};
