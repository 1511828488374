import { IconContainer } from './IconContainer';

export const IconWarrantyRequests = () => (
  <IconContainer bgColor="#01a5a1">
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14" height="14">
      <g>
        <defs>
          <path
            d="M 2.8 2.8 C 2.8 2.027 3.427 1.4 4.2 1.4 L 7.41 1.4 C 7.781 1.4 8.138 1.548 8.4 1.81 L 10.79 4.2 C 11.052 4.462 11.2 4.819 11.2 5.19 L 11.2 11.2 C 11.2 11.973 10.573 12.6 9.8 12.6 L 8.73 12.6 C 10.215 10.939 10.145 8.407 8.569 6.831 C 6.993 5.255 4.461 5.185 2.8 6.67 Z"
            id="a1012z"
          />
          <filter id="a1014z" x="-49.4%" y="-46.0%" width="197.6%" height="191.2%" filterUnits="objectBoundingBox">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="a1016z" />
            <feGaussianBlur stdDeviation="1" in="a1016z" result="a1017z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
              type="matrix"
              in="a1017z"
              result="a1018z"
            />
          </filter>
        </defs>
        <g filter="url(#a1014z)">
          <use
            fill="black"
            fillOpacity="1"
            stroke="black"
            strokeOpacity="0"
            strokeWidth="0"
            xlinkHref="#a1012z"
            clipPath="url(#a1013z)"
          />
        </g>
        <use xlinkHref="#a1012z" fill="rgba(255, 255, 255, 0.5)" clipPath="url(#a1013z)" />
      </g>
      <g>
        <defs>
          <path
            d="M 5.6 7 C 4.595 7 3.667 7.538 3.168 8.411 C 2.67 9.284 2.677 10.357 3.188 11.222 L 2.305 12.104 C 2.032 12.378 2.031 12.821 2.305 13.095 C 2.578 13.368 3.021 13.368 3.295 13.095 L 4.178 12.212 C 5.194 12.811 6.477 12.709 7.386 11.956 C 8.295 11.203 8.634 9.961 8.233 8.851 C 7.833 7.74 6.78 7 5.6 7 Z M 4.2 9.8 C 4.2 9.027 4.827 8.4 5.6 8.4 C 6.373 8.4 7 9.027 7 9.8 C 7 10.573 6.373 11.2 5.6 11.2 C 4.827 11.2 4.2 10.573 4.2 9.8 Z"
            id="a1019z"
          />
          <filter id="a1021z" x="-65.6%" y="-81.3%" width="229.7%" height="260.9%" filterUnits="objectBoundingBox">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="a1023z" />
            <feGaussianBlur stdDeviation="1" in="a1023z" result="a1024z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
              type="matrix"
              in="a1024z"
              result="a1025z"
            />
          </filter>
        </defs>
        <g filter="url(#a1021z)">
          <use
            fill="black"
            fillOpacity="1"
            stroke="black"
            strokeOpacity="0"
            strokeWidth="0"
            xlinkHref="#a1019z"
            clipPath="url(#a1020z)"
          />
        </g>
        <use xlinkHref="#a1019z" fill="hsl(0, 0%, 100%)" clipPath="url(#a1020z)" />
      </g>
    </svg>
  </IconContainer>
);
