import * as React from 'react';
import { SVGProps } from 'react';

const IconUpsellArrows = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      d="M6.4 4a.8.8 0 1 0 0 1.6h4.469L9.834 6.634a.801.801 0 0 0 1.132 1.132l2.4-2.4a.802.802 0 0 0 0-1.132l-2.4-2.4a.8.8 0 1 0-1.132 1.132L10.869 4Z"
      fill="#FFF"
    />
    <path
      d="M9.6 12a.8.8 0 1 0 0-1.6H5.131l1.035-1.034a.8.8 0 1 0-1.132-1.132l-2.4 2.4a.802.802 0 0 0 0 1.132l2.4 2.4a.801.801 0 0 0 1.132-1.132L5.131 12Z"
      fill="hsla(0, 0%, 100%, 0.54)"
    />
  </svg>
);

export default IconUpsellArrows;
