import { IconContainer } from './IconContainer';

export const IconChatClosed = () => (
  <IconContainer bgColor="#2f96e2">
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={14} height={14}>
      <g>
        <defs>
          <path
            d="M 2.8 2.1 C 2.027 2.1 1.4 2.727 1.4 3.5 C 1.4 4.273 2.027 4.9 2.8 4.9 L 11.2 4.9 C 11.973 4.9 12.6 4.273 12.6 3.5 C 12.6 2.727 11.973 2.1 11.2 2.1 Z"
            id="a1213z"
          />
          <filter id="a1215z" x="-37.2%" y="-179.3%" width="173.5%" height="455.2%" filterUnits="objectBoundingBox">
            <feOffset dx={0} dy={1} in="SourceAlpha" result="a1217z" />
            <feGaussianBlur stdDeviation={1} in="a1217z" result="a1218z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
              type="matrix"
              in="a1218z"
              result="a1219z"
            />
          </filter>
        </defs>
        <g filter="url(#a1215z)">
          <use
            fill="black"
            fillOpacity={1}
            stroke="black"
            strokeOpacity={0}
            strokeWidth={0}
            xlinkHref="#a1213z"
            clipPath="url(#a1214z)"
          />
        </g>
        <use xlinkHref="#a1213z" fill="rgba(255, 255, 255, 0.5)" clipPath="url(#a1214z)" />
      </g>
      <g>
        <defs>
          <path
            d="M 2.1 5.6 L 11.9 5.6 L 11.9 10.5 C 11.9 11.273 11.273 11.9 10.5 11.9 L 3.5 11.9 C 2.727 11.9 2.1 11.273 2.1 10.5 Z M 6.3 8.4 L 7.7 8.4 C 8.087 8.4 8.4 8.087 8.4 7.7 C 8.4 7.313 8.087 7 7.7 7 L 6.3 7 C 5.913 7 5.6 7.313 5.6 7.7 C 5.6 8.087 5.913 8.4 6.3 8.4 Z"
            id="a1220z"
          />
          <filter id="a1222z" x="-42.4%" y="-81.3%" width="183.8%" height="260.9%" filterUnits="objectBoundingBox">
            <feOffset dx={0} dy={1} in="SourceAlpha" result="a1224z" />
            <feGaussianBlur stdDeviation={1} in="a1224z" result="a1225z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
              type="matrix"
              in="a1225z"
              result="a1226z"
            />
          </filter>
        </defs>
        <g filter="url(#a1222z)">
          <use
            fill="black"
            fillOpacity={1}
            stroke="black"
            strokeOpacity={0}
            strokeWidth={0}
            xlinkHref="#a1220z"
            clipPath="url(#a1221z)"
          />
        </g>
        <use xlinkHref="#a1220z" fill="hsl(0, 0%, 100%)" clipPath="url(#a1221z)" />
      </g>
    </svg>
  </IconContainer>
);
