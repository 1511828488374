import { useContext, useMemo } from 'react';
import { CurrentMemberQuery } from 'types/typed-document-nodes';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { getFullName } from 'lib/utils';

type Member = NonNullable<NonNullable<CurrentMemberQuery['workspace']>['members']>[number];

export const useWorkspaceMembers = (): Member[] => {
  const { workspace } = useContext(OrganisationContext);
  return workspace.members || [];
};

export const useMembersFilterOptions = () => {
  const members = useWorkspaceMembers();

  return useMemo(() => {
    return members.map(member => ({
      id: member.id,
      title: getFullName(member),
    }));
  }, [members]);
};
