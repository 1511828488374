import { WorkspaceActiveConversationsQuery, WorkspaceArchivedConversationsQuery } from 'types/typed-document-nodes';

export type ChatArea = 'INBOX' | 'FOR_YOU' | 'UNASSIGNED' | 'CLOSED';
export const SUPPORTED_MEDIA_TYPES = ['IMAGE', 'VIDEO', 'FILE'] as const;
export type MediaType = (typeof SUPPORTED_MEDIA_TYPES)[number];
export type MessageType =
  | 'MESSAGE'
  | 'NOTE'
  | 'RATE'
  | 'TYPING'
  | 'ARCHIVE'
  | 'UNARCHIVE'
  | 'ASSIGN'
  | 'PROMOTION'
  | MediaType;

export function isMediaType(type: string): type is MediaType {
  return SUPPORTED_MEDIA_TYPES.includes(type as MediaType);
}

export type InternalConversation =
  | WorkspaceActiveConversationsQuery['getWorkspaceActiveConversations'][number]
  | WorkspaceArchivedConversationsQuery['getWorkspaceArchivedConversations'][number];

export type ChatParticipant = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  avatarImg: {
    id: string;
    dynamic: string | null;
  } | null;
};

export type Customer = ChatParticipant;
export type Member = ChatParticipant & { deleted: boolean | null };

export type ChatMessage = {
  id: string;
  twilioId: string | null;
  message?: string | null;
  createdAt: string | Date;
  index: number | null;
  type: string | null;
  customer?: Customer | null;
  member?: Member | null;
  filename?: string | null;
};

export type Team = {
  id: string;
  name: string;
};
