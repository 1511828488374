import { Tag } from '@layerise/design-core';
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { AddTag, AddTagDirection } from './AddTag';
import { TagOverfow } from './TagOverflow';

const TagListContainers = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
`;

const TagContainer = styled.div``;

const TagWrapper = styled(Tag)<{ wrap?: boolean; viewOnly?: boolean; multiLine?: boolean }>`
  ${props =>
    props.wrap &&
    css<{ viewOnly?: boolean; multiLine?: boolean }>`
      display: grid;
      grid-template-columns: ${props => (!props.viewOnly ? 'auto 25px' : 'auto')};
      align-items: ${props => (!props.multiLine ? 'baseline' : null)};
      padding: 0;
      gap: 8px;
      line-height: 20px;

      button {
        height: ${props => (props.multiLine ? '100%' : null)};
        margin-left: 0;
        padding-bottom: 2px;
        svg {
          margin-left: 0;
          margin-bottom: 0;
        }
      }
    `}
`;

const TagTitleWrapper = styled.p<{ viewOnly?: boolean; multiLine?: boolean; tagMaxLength?: number }>`
  max-width: ${props => (props.tagMaxLength ? `${props.tagMaxLength}px` : props.multiLine ? '200px' : '140px')};
  text-overflow: ellipsis;
  display: inline-block;
  overflow-x: hidden;
  padding-top: 2px;
  font-size: 12px;
  color: inherit;
  margin-left: 8px;
  margin-right: ${props => (props.viewOnly ? '8px' : '0')};
  padding-bottom: 3px;
  ${props =>
    props.multiLine &&
    css`
      white-space: break-spaces;
      word-break: break-all;
    `};
`;

export type Tag = {
  id: string;
  title: string;
  checked?: boolean;
};

interface TagListProps {
  tags?: Tag[];
  addTagDirection?: AddTagDirection;
  disableOverflow?: boolean;
  wrap?: boolean;
  viewOnly?: boolean;
  multiLine?: boolean;
  tagMaxLength?: number;
  onChange?: (tags: Tag[]) => void;
}

export const DISPLAYED_TAGS_COUNT = 3;
const TOOLTIP_TAG_TITLE_LENGTH = 20;

export const TagList = ({
  tags = [],
  viewOnly,
  addTagDirection = 'right',
  onChange,
  disableOverflow,
  wrap = true,
  multiLine = false,
  tagMaxLength,
}: TagListProps) => {
  const [displayTags, setDisplayTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (disableOverflow) {
      setDisplayTags(tags);
    }
    if (!viewOnly) {
      const filtered = tags.filter(tag => tag.checked);
      setDisplayTags(filtered.slice(0, DISPLAYED_TAGS_COUNT));
    }
    setDisplayTags(tags.slice(0, DISPLAYED_TAGS_COUNT));
  }, [disableOverflow, tags, viewOnly]);

  const handleAddTags = (newTags: Tag[]) => {
    onChange?.(newTags);
  };

  const handleRemoveTag = (tagId: string) => {
    if (viewOnly) {
      return;
    }
    return () => {
      const newTags = tags.filter(tag => tag.id !== tagId);
      onChange?.(newTags);
    };
  };

  const overflowedTags = useMemo(() => {
    if (tags.length > DISPLAYED_TAGS_COUNT) {
      return tags.slice(DISPLAYED_TAGS_COUNT);
    }
    return [];
  }, [tags]);

  const overflowText = useMemo(() => {
    return overflowedTags
      .map(tag =>
        tag.title.length > TOOLTIP_TAG_TITLE_LENGTH
          ? `${tag.title.substring(0, TOOLTIP_TAG_TITLE_LENGTH)}...`
          : tag.title
      )
      .join(', ');
  }, [overflowedTags]);

  return (
    <TagListContainers>
      {displayTags.map(tag => {
        return (
          <TagContainer key={tag.id}>
            <TagWrapper
              wrap={wrap}
              viewOnly={viewOnly}
              multiLine={multiLine}
              bgColor="#140038"
              onRemove={handleRemoveTag(tag.id)}
              variant="custom">
              {wrap ? (
                <TagTitleWrapper
                  tagMaxLength={tagMaxLength}
                  viewOnly={viewOnly}
                  multiLine={multiLine}
                  title={!multiLine ? tag.title : ''}>
                  {tag.title}
                </TagTitleWrapper>
              ) : (
                tag.title
              )}
            </TagWrapper>
          </TagContainer>
        );
      })}
      {!disableOverflow && overflowedTags.length > 0 && (
        <TagOverfow count={overflowedTags.length} tooltip={overflowText} />
      )}
      {!viewOnly && <AddTag direction={addTagDirection} onAddTags={handleAddTags} tags={tags} existingTagIds={[]} />}
    </TagListContainers>
  );
};
