import ErrorComponent from 'next/error';

interface ErrCodeException extends Error {
  code?: string;
}

export const throw404 = () => {
  if (process.browser) {
    return <ErrorComponent statusCode={404} />;
  }
  const e = new Error() as ErrCodeException;
  e.code = 'ENOENT';
  throw e;
};
