import * as React from 'react';
import { SVGProps } from 'react';

const IconImport = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14 4.25c0 1.795-2.687 3.25-6 3.25S2 6.045 2 4.25 4.687 1 8 1s6 1.455 6 3.25Z" fill="hsl(0, 0%, 100%)" />
    <path
      d="M8 8.5c1.79 0 3.46-.391 4.719-1.073a5.511 5.511 0 0 0 1.264-.922A1.8 1.8 0 0 1 14 6.75C14 8.545 11.313 10 8 10S2 8.545 2 6.75a1.8 1.8 0 0 1 .017-.245c.375.366.801.676 1.264.922C4.539 8.109 6.21 8.5 8 8.5Z"
      fill="hsl(0, 0%, 100%)"
    />
    <path
      d="M8 11c1.79 0 3.46-.391 4.719-1.073a5.511 5.511 0 0 0 1.264-.922A1.8 1.8 0 0 1 14 9.25c0 1.795-2.687 3.25-6 3.25s-6-1.455-6-3.25a1.8 1.8 0 0 1 .017-.245c.375.366.801.676 1.264.922C4.539 10.609 6.21 11 8 11Z"
      fill="hsl(0, 0%, 100%)"
    />
    <path
      d="M8 13.5c1.79 0 3.46-.391 4.719-1.073a5.511 5.511 0 0 0 1.264-.922 1.8 1.8 0 0 1 .017.245C14 13.545 11.313 15 8 15s-6-1.455-6-3.25a1.8 1.8 0 0 1 .017-.245c.375.366.801.676 1.264.922C4.539 13.109 6.21 13.5 8 13.5Z"
      fill="hsl(0, 0%, 100%)"
    />
  </svg>
  /*
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
    <path d="M10.5 3.188c0 1.346-2.015 2.437-4.5 2.437S1.5 4.534 1.5 3.188C1.5 1.841 3.515.75 6 .75s4.5 1.091 4.5 2.438Z" />
    <path d="M6 6.375c1.343 0 2.595-.293 3.539-.804a4.14 4.14 0 0 0 .948-.693c.008.061.013.122.013.185C10.5 6.409 8.485 7.5 6 7.5S1.5 6.409 1.5 5.063c0-.063.004-.124.012-.185.282.275.602.508.949.693.944.511 2.196.804 3.539.804Z" />
    <path d="M6 8.25c1.343 0 2.595-.293 3.539-.804a4.14 4.14 0 0 0 .948-.693c.008.061.013.122.013.185 0 1.346-2.015 2.437-4.5 2.437S1.5 8.284 1.5 6.938c0-.063.004-.124.012-.185.282.275.602.508.949.693.944.511 2.196.804 3.539.804Z" />
    <path d="M6 10.125c1.343 0 2.595-.293 3.539-.805.347-.184.667-.417.948-.692.008.061.013.122.013.184 0 1.346-2.015 2.438-4.5 2.438s-4.5-1.092-4.5-2.438c0-.062.004-.123.012-.184.282.275.602.508.949.692.944.512 2.196.805 3.539.805Z" />
  </svg>
   */
);

export default IconImport;
