import styled from 'styled-components';
import { Input, useForm, Card, Button, FileUpload } from '@layerise/design-core';
import { Title } from 'components/settings/elements';
import { Actions as ActionsBase } from 'components/settings/workspace/elements';
import { Heading } from 'components/settings/elements';
import { MAXIMUM_UPLOAD_FILE_SIZE, MAXIMUM_UPLOAD_FILE_SIZE_FORMATTED } from 'constants/uploadFileSize';
import { LibraryPickerModal } from 'components/pickerModal/LibraryPickerModal';
import { getUploadDirectory } from 'lib/upload';
import ImageEditorDynamicSSR from 'components/dynamicSSR/ImageEditorDynamicSSR';
import { EditMemberFragmentFragment, LibraryItemType, UpdateMemberDocument } from 'types/typed-document-nodes';
import ToastContext from 'contexts/ToastContext';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { useContext, useEffect } from 'react';
import { FieldTitle, Item, Save } from '../elements';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { useApiErrorHandler } from 'hooks/useApiErrorHandler';

const Actions = styled(ActionsBase)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
`;

const ImageContainer = styled.div`
  flex: 1;
  padding: 24px;

  display: flex;
  flex-direction: row-reverse;

  button {
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
  }
  button span {
    margin-right: 0;
  }
`;

type FormData = {
  email: string;
  firstName: string | null;
  lastName: string | null;
  avatar: string | null | undefined;
  avatarId: string | null | undefined;
};

type Props = {
  member: EditMemberFragmentFragment;
  readonly: boolean;
  refetch(): Promise<ApolloQueryResult<unknown>[]> | Promise<ApolloQueryResult<unknown>>;
};

export function GeneralView({ member, readonly, refetch }: Props) {
  const { setToast } = useContext(ToastContext);
  const { workspace } = useContext(OrganisationContext);

  const { inputs, setInputs, handleChange } = useForm<FormData>({
    email: member.email,
    firstName: member.firstName,
    lastName: member.lastName,
    avatar: member.avatarImg?.dynamic ?? null,
    avatarId: member.avatarImg?.id ?? null,
  });

  const [updateMember, { loading }] = useMutation(UpdateMemberDocument, {
    variables: {
      id: member.id,
      email: inputs.email,
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      avatar: inputs.avatar,
      avatarId: inputs.avatarId,
    },
    onCompleted: async () => {
      await refetch();
      setToast({
        type: 'success',
        message: 'Member profile successfully updated',
      });
    },
    onError: useApiErrorHandler(),
  });

  useEffect(() => {
    setInputs({
      email: member.email,
      firstName: member.firstName,
      lastName: member.lastName,
      avatar: member.avatarImg?.dynamic ?? null,
      avatarId: member.avatarImg?.id ?? null,
    });
  }, [member]);

  const isChanged =
    inputs.firstName !== member.firstName ||
    inputs.lastName !== member.lastName ||
    inputs.email !== member.email ||
    inputs.avatar !== (member.avatarImg?.dynamic ?? null) ||
    inputs.avatarId !== (member.avatarImg?.id ?? null);

  return (
    <section>
      <Heading>General</Heading>
      <Title>Profile</Title>
      <Card none>
        <form
          onSubmit={e => {
            e.preventDefault();
            updateMember();
          }}>
          <Item>
            <FieldTitle>Name</FieldTitle>
            <Actions>
              <Input
                name="firstName"
                placeholder="First name"
                value={inputs.firstName}
                disabled={readonly}
                onChange={handleChange}
              />
              <Input
                name="lastName"
                placeholder="Last name"
                value={inputs.lastName}
                disabled={readonly}
                onChange={handleChange}
              />
            </Actions>
          </Item>
          <Item>
            <FieldTitle>Email</FieldTitle>
            <Actions>
              <Input
                name="email"
                type="email"
                placeholder="name@domain.com"
                disabled={readonly}
                value={inputs.email}
                onChange={handleChange}
              />
            </Actions>
          </Item>
          <Item>
            <FieldTitle>Avatar</FieldTitle>
            <Actions>
              <ImageContainer>
                <FileUpload
                  editorOptions={{
                    component: ImageEditorDynamicSSR,
                    options: {
                      aspectRatioPresets: [{ width: 516, height: 516, label: 'Avatar' }],
                      circular: true,
                    },
                  }}
                  preview={inputs.avatar || undefined}
                  preset="member_avatar"
                  disabled={readonly}
                  acceptType={{ 'image/*': [] }}
                  maxSize={MAXIMUM_UPLOAD_FILE_SIZE}
                  placeholder="Upload avatar"
                  height={80}
                  width={80}
                  circular
                  small
                  multiple={false}
                  folder={getUploadDirectory('member', workspace.id)}
                  onLibrary={showModal => {
                    showModal((modalProps: any) => {
                      return (
                        <LibraryPickerModal
                          type={LibraryItemType.Image}
                          onItemsSelect={async ([item]) => {
                            setInputs({
                              avatar: item.dynamic,
                              avatarId: item.id,
                            });
                          }}
                          onRequestClose={modalProps.onRequestClose}
                        />
                      );
                    }, {});
                  }}
                  onError={(e: any) => {
                    if (e.message.includes(MAXIMUM_UPLOAD_FILE_SIZE.toString())) {
                      setToast({
                        type: 'warning',
                        message: `The file you're trying to upload is too big. The limit is ${MAXIMUM_UPLOAD_FILE_SIZE_FORMATTED}`,
                      });
                      return;
                    } else {
                      setToast({
                        type: 'warning',
                        message: e.message,
                      });
                    }
                  }}
                  onRemove={() => {
                    setInputs({ ...inputs, avatar: null, avatarId: null });
                  }}
                  onUpload={fileObjects => {
                    const fileObject = Array.isArray(fileObjects) ? fileObjects[0] : fileObjects;
                    setInputs({ ...inputs, avatar: fileObject.cloudinaryId, avatarId: null });
                  }}
                />
              </ImageContainer>
            </Actions>
          </Item>

          <Save>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              loading={loading}
              restricted={readonly}
              disabled={!isChanged || loading}>
              {loading ? 'Saving...' : 'Save changes'}
            </Button>
          </Save>
        </form>
      </Card>
    </section>
  );
}
