import styled, { css } from 'styled-components';
import { IconArrowDown } from '@layerise/design-icon';
import { Checkbox, Pagination, Skeleton } from '@layerise/design-core';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Total } from 'components/feedback/elements';
import { TableBodyRow } from './TableBodyRow';

type Props = {
  page?: number;
  itemsPerPage?: number;
  onChangePage?: (page: number) => void;
  plain?: boolean;
  disabled?: boolean;
  headerBackground?: boolean;
  layout?: 'auto' | 'fixed';
  className?: string;
  size?: 'standard' | 'compact';
  noHover?: boolean;
  infiniteScroll?: any;
  headerItems: {
    id?: string;
    visible?: boolean;
    asc?: boolean;
    onClick?: () => void;
    title?: string;
    width?: number | string;
    padding?: string;
    content?: any;
    align?: any;
  }[];
  subHeaderItems?: {
    id?: string;
    visible?: boolean;
    onClick?: () => void;
    title?: React.ReactNode;
    width?: number;
    padding?: string;
  }[];
  alwaysShowSubHeaderItems?: boolean;
  emptyState?: React.ReactNode;
  loading?: boolean;
  subHeaderCollapse?: boolean;
  items: {
    onClick?: React.MouseEventHandler<HTMLTableRowElement>;
    id: string;
    selected?: boolean;
    disabled?: boolean;
    tooltip?: React.ReactNode;
    columns: {
      id?: string;
      width?: number;
      padding?: string;
      content?: any;
      onClick?: () => void;
      fontWeight?: number;
      fontColor?: string;
      fontSize?: number;
      textOverflow?: boolean;
      align?: 'left' | 'right' | 'center' | 'justify' | 'char';
      skeleton?: React.ComponentProps<typeof Skeleton> | false;
    }[];
  }[];
  selectAllChecked?: boolean;
  onSelectAll?: () => void;
};

const Container = styled.div<{
  emptyState?: React.ReactNode;
}>`
  background-color: #fff;
  width: 100%;
`;

const TableContainer = styled.table<{
  isDisabled?: boolean;
  layout: 'auto' | 'fixed';
  plain?: boolean;
}>`
  background-color: #fff;
  width: 100%;
  border-collapse: collapse;
  table-layout: ${props => props.layout};
  border-radius: 7px;
  box-shadow: ${props => (props.plain ? 'none' : 'var(--depth-Z100)')};

  ${props =>
    props.isDisabled &&
    css`
      filter: opacity(0.4);
      pointer-events: none;
    `}
`;

const TableHeader = styled.thead<{
  headerBackground: boolean;
}>`
  background-color: ${props => (props.headerBackground ? 'var(--color-N050)' : 'transparent')};
`;

const Tr = styled.tr<{
  subHeaderItems?: boolean;
  subHeader?: boolean;
  noBorder?: boolean;
}>`
  padding: 12px 16px;
  height: 40px;
  border-bottom: ${props => (props.subHeaderItems ? '1px solid #D9D9E2' : '1px solid var(--color-N100)')};
  text-align: left;
  background-color: ${props => (props.subHeader ? '#F7F7FA' : 'var(--color-N000)')};

  thead > &:first-of-type {
    background-color: transparent;
  }

  ${props =>
    props.noBorder &&
    css`
      border-bottom: none;
    `}
`;

const TableCheckbox = styled.th<{
  width?: number | string;
  padding?: string;
  align?: 'left' | 'right' | 'center' | 'justify' | 'char';
}>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding: ${props => props.padding || '0px 0px 0px 16px'};
  text-align: ${props => props.align};

  :first-of-type {
    border-top-left-radius: 8px;
  }

  :last-of-type {
    border-top-right-radius: 8px;
  }
`;

const TableMeta = styled.th<{
  visible?: boolean;
  asc?: boolean;
  width?: number | string;
  padding?: string;
  align?: 'left' | 'right' | 'center' | 'justify' | 'char';
}>`
  line-height: 16px;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 600;
  color: var(--color-N500);
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding: ${props => props.padding || '0px 8px'};
  white-space: nowrap;
  text-align: ${props => props.align};

  svg {
    display: inline;
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
    transform: ${props => (props.asc ? 'rotate(180deg)' : 'rotate(0deg)')};
    height: 8px;
    width: 8px;
  }

  :first-of-type {
    border-top-left-radius: 8px;
  }

  :last-of-type {
    border-top-right-radius: 8px;
  }
`;

const TableSubHeader = styled.th<{
  visible?: boolean;
  asc?: boolean;
  width?: number;
  padding?: string;
}>`
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-N600);
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding: ${props => props.padding || '0px 8px'};
  white-space: nowrap;
`;

const TBody = styled.tbody``;
const Td = styled.td<{
  width?: number;
  padding?: string;
  fontColor?: string;
  fontWeight?: number;
  fontSize?: number;
  textOverflow?: boolean;
  size?: 'compact' | 'standard';
}>`
  border-bottom: 1px solid var(--color-N100);
  font-size: ${props => (props.fontSize ? props.fontSize + 'px' : '14px')};
  color: ${props => (props.fontColor ? props.fontColor : 'var(--color-N400)')};
  height: ${props => (props.size === 'compact' ? '40px' : '64px')};
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding: ${props => props.padding || '0px 8px'};
  font-weight: ${props => props.fontWeight || 400};

  &:last-child {
    padding: ${props => props.padding || '0px 16px 0px 8px'};
  }

  ${props =>
    props.textOverflow &&
    css<{ width?: number }>`
      span,
      a,
      p,
      div {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: ${props => props.width + 'px'};
        vertical-align: middle;
      }
    `}
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid var(--color-N100);
  background-color: #f7f7fa;
  padding: 16px;
`;

export const Table: React.FC<Props> = ({
  headerItems,
  items,
  headerBackground,
  plain,
  layout = 'auto',
  disabled,
  emptyState,
  loading,
  className,
  size = 'standard',
  noHover,
  subHeaderItems,
  infiniteScroll,
  alwaysShowSubHeaderItems = false,
  page,
  itemsPerPage = 5,
  selectAllChecked,
  onSelectAll,
  onChangePage,
}) => {
  const showEmptyState = !loading && items?.length === 0 && emptyState;
  const noSubHeaderBorder = items?.length === 0;
  let Wrapper;
  let wrapperProps = {};

  if (infiniteScroll) {
    Wrapper = InfiniteScroll;
    wrapperProps = {
      dataLength: items.length,
      scrollableTarget: 'tableContainer',
      ...infiniteScroll,
    };
  } else {
    Wrapper = React.Fragment;
  }

  return (
    <Container id="tableContainer" className={className}>
      <Wrapper {...wrapperProps}>
        {showEmptyState ? (
          emptyState
        ) : (
          <TableContainer plain={plain} isDisabled={disabled} layout={layout}>
            <TableHeader headerBackground={!!headerBackground}>
              <Tr subHeaderItems={!!subHeaderItems}>
                {headerItems.map((headerItem, idx) =>
                  idx === 0 && !!onSelectAll ? (
                    <TableCheckbox
                      key={headerItem.id}
                      padding={headerItem.padding}
                      width={headerItem.width}
                      align={headerItem.align}>
                      <Checkbox checked={selectAllChecked} onChange={onSelectAll} name="selectAllChecked" size="sm" />
                    </TableCheckbox>
                  ) : (
                    <TableMeta
                      visible={headerItem.visible}
                      asc={headerItem.asc}
                      onClick={headerItem.onClick}
                      key={headerItem.id}
                      padding={headerItem.padding}
                      width={headerItem.width}
                      align={headerItem.align}>
                      {headerItem.content && <>{headerItem.content}</>}
                      {headerItem.title && (
                        <>
                          {headerItem.title} <IconArrowDown />
                        </>
                      )}
                    </TableMeta>
                  )
                )}
              </Tr>
              {subHeaderItems && (items?.length !== 1 || alwaysShowSubHeaderItems) && (
                <Tr noBorder={noSubHeaderBorder} subHeader>
                  {subHeaderItems?.map(headerItem => (
                    <TableSubHeader
                      visible={headerItem.visible}
                      onClick={headerItem.onClick}
                      key={headerItem.id}
                      padding={headerItem.padding}
                      width={headerItem.width}>
                      {headerItem.title}
                    </TableSubHeader>
                  ))}
                </Tr>
              )}
            </TableHeader>
            <TBody>
              {items?.map(item => (
                <TableBodyRow
                  key={item.id}
                  disabled={item.disabled}
                  selected={item.selected}
                  noHover={noHover}
                  tooltip={item.tooltip}
                  onClick={item.disabled ? undefined : item.onClick}>
                  {item.columns.map(column => {
                    return (
                      <Td
                        fontWeight={column.fontWeight}
                        fontColor={column.fontColor}
                        fontSize={column.fontSize}
                        width={column.width}
                        onClick={column.onClick}
                        padding={column.padding}
                        align={column.align}
                        textOverflow={column.textOverflow}
                        size={size}
                        key={column.id}>
                        {loading && column.skeleton !== false ? (
                          <Skeleton margin="0" {...column.skeleton} />
                        ) : column.textOverflow ? (
                          <span title={typeof column.content === 'string' ? column.content : undefined}>
                            {column.content}
                          </span>
                        ) : (
                          column.content
                        )}
                      </Td>
                    );
                  })}
                </TableBodyRow>
              ))}
            </TBody>
            {typeof page !== 'undefined' && (
              <tfoot>
                <tr>
                  <td colSpan={headerItems.length}>
                    <FooterContainer>
                      <Total>{items.length} results</Total>
                      <Pagination
                        disabledPrevious={page === 0}
                        disabledNext={typeof page !== 'undefined' ? (page + 1) * itemsPerPage >= items.length : true}
                        onClickPrevious={() => {
                          if (typeof page !== 'undefined') {
                            onChangePage?.(page - 1);
                          }
                        }}
                        onClickNext={() => {
                          if (typeof page !== 'undefined') {
                            onChangePage?.(page + 1);
                          }
                        }}
                      />
                    </FooterContainer>
                  </td>
                </tr>
              </tfoot>
            )}
          </TableContainer>
        )}
      </Wrapper>
    </Container>
  );
};
