import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Pagination } from '@layerise/design-core';

interface TableFooterProps {
  totalItems?: number;
  hideTotalItems?: boolean;
  page: number;
  itemsPerPage: number;
  setPage: (page: number) => void;
  alignFooter?: 'LEFT' | 'RIGHT';
  centerContent?: React.ReactNode;
}

const FooterWrapper = styled.div<{ leftAlign?: boolean }>`
  display: flex;
  align-items: center;
  ${props =>
    props.leftAlign &&
    css`
      justify-content: space-between;
    `}
`;

const Total = styled.p`
  color: var(--color-N500);
  font-size: 14px;
`;

export const TableFooter = ({
  totalItems = 0,
  hideTotalItems,
  page,
  itemsPerPage,
  setPage,
  alignFooter = 'LEFT',
  centerContent,
}: TableFooterProps) => {
  const isLastPage = typeof totalItems === 'number' ? (page + 1) * itemsPerPage >= totalItems : false;
  const pageInfo = useMemo(() => {
    if (totalItems <= itemsPerPage) return '';
    return `${page * itemsPerPage + 1}-${isLastPage ? totalItems : (page + 1) * itemsPerPage} of `;
  }, [isLastPage, itemsPerPage, page, totalItems]);
  return (
    <FooterWrapper leftAlign={alignFooter === 'LEFT'}>
      {!hideTotalItems && (
        <Total>
          {pageInfo}
          {totalItems} {totalItems === 1 ? 'result' : 'results'}
        </Total>
      )}
      {centerContent}
      <Pagination
        disabledPrevious={page === 0}
        disabledNext={isLastPage}
        onClickPrevious={() => {
          setPage(page - 1);
        }}
        onClickNext={() => {
          setPage(page + 1);
        }}
      />
    </FooterWrapper>
  );
};

const Copy = styled.span`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  color: var(--color-N400);

  > a {
    color: #140068;
    cursor: pointer;
    font-weight: 600;
  }
`;

interface LearnMoreLinkProps {
  href?: string;
  as?: string;
  onClick?: React.MouseEventHandler;
  linkLabel: string;
}
export const LearnMoreLink = ({ onClick, linkLabel, href }: LearnMoreLinkProps) => (
  <Copy>
    Learn more about{' '}
    <a target="_blank" href={href} onClick={onClick} rel="noreferrer">
      {linkLabel}
    </a>
  </Copy>
);
