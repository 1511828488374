import React from 'react';
import ItemColumn from './ItemColumn';
import { Skeleton, Tag } from '@layerise/design-core';
import { CustomerListsFragment } from '../hooks/useData';
import { MetaInfo } from 'components/table/MetaInfo';

interface ColumnProps {
  item: CustomerListsFragment;
  onClick: (itemId: string) => void;
  selected: boolean;
}

export const customersListLoadingView = [
  {
    id: 'skeleton',
    columns: [
      {
        id: '1',
        content: <Skeleton />,
      },
      {
        id: '2',
        content: <Skeleton />,
      },
      {
        id: '3',
        content: <Skeleton />,
      },
    ],
  },
];

export const customersListHeaderItems = [
  {
    id: '1',
    title: 'name',
  },
  {
    id: '2',
    title: 'size',
  },
  {
    id: '3',
    title: 'type',
  },
  {
    id: '4',
    title: 'updated by',
    padding: '0 8px',
    width: '110',
    align: 'left',
  },
];

export const customersListColumns = ({ item, onClick, selected }: ColumnProps) => {
  const updatedLogItems = [
    {
      createdAt: item.updatedAt,
      member: item.createdBy,
    },
  ];
  return [
    {
      id: '1',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          {item.name}
        </ItemColumn>
      ),
      fontWeight: 600,
      fontColor: 'var(--color-N600)',
      fontSize: 14,
    },
    {
      id: '2',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          {item.totalCustomers}
        </ItemColumn>
      ),
      fontWeight: 600,
      fontColor: 'var(--color-N600)',
      fontSize: 14,
    },
    {
      id: '3',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          {item.type === 'STATIC' ? (
            <Tag variant="neutral">Static</Tag>
          ) : item.type === 'ACTIVE' ? (
            <Tag variant="success">Active</Tag>
          ) : null}
        </ItemColumn>
      ),
      fontWeight: 600,
      fontColor: 'var(--color-N600)',
      fontSize: 14,
    },

    {
      id: '86',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          <MetaInfo transactionLogs={updatedLogItems} updatedAt={item.updatedAt} selected={selected} />
        </ItemColumn>
      ),
    },
  ];
};
