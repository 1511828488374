import { useQuery, QueryResult, ObservableQuery } from '@apollo/client';
import React, { createContext, useCallback, useEffect, PropsWithChildren } from 'react';
import {
  CurrentMemberDocument,
  CurrentMemberQuery,
  CurrentMemberQueryVariables,
  MemberWorkspacePermissionsDocument,
  MemberWorkspacePermissionsQuery,
  OrganisationWorkspacesUsageDocument,
  OrganisationWorkspacesUsageQuery,
  OrganisationWorkspacesUsageQueryVariables,
} from 'types/typed-document-nodes';
import { getPlanData } from 'lib/organisation';
import useHubSpotTracking from 'hooks/useHubSpotTracking';
import { useRouter } from 'next/router';
import { isBrowser, isPowerUser } from 'lib/utils';
import { hideLoader } from 'lib/loader';
import { throw404 } from '../lib/errors';
import useLinks from '../hooks/useLinks';

type Member = NonNullable<CurrentMemberQuery['me']>;
type Organisation = NonNullable<Member['organisation']>;
type Workspace = NonNullable<CurrentMemberQuery['workspace']>;

interface IOrganisationContext {
  me: Member;
  organisation: Organisation;
  plan: ReturnType<typeof getPlanData>;
  permissions: NonNullable<MemberWorkspacePermissionsQuery['me']>['workspacePermissions'];
  workspace: Workspace;
  workspaces: CurrentMemberQuery['memberWorkspaces'];
  refetch: ObservableQuery<CurrentMemberQuery, CurrentMemberQueryVariables>['refetch'];
  onboarding: {
    workspace: Workspace['onboarding'] | null;
    member: Member['onboarding'] | null;
  };
  currency: NonNullable<CurrentMemberQuery['planFeatures']>['currency'] | null | undefined;
  workspacesUsageQuery: QueryResult<OrganisationWorkspacesUsageQuery, OrganisationWorkspacesUsageQueryVariables>;
}

export const OrganisationContext = createContext<IOrganisationContext>(undefined!);

export const OrganisationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();

  const workspaceSlug = router.query.id as string;
  const memberQuery = useQuery(CurrentMemberDocument, {
    skip: !isBrowser() || !router.isReady || !workspaceSlug,
    variables: { workspaceSlug },
  });

  const permissionsQuery = useQuery(MemberWorkspacePermissionsDocument, {
    skip: !isBrowser() || !router.isReady || !workspaceSlug,
    variables: { workspaceSlug },
  });

  useEffect(() => {
    const pollInterval = setInterval(() => {
      permissionsQuery.refetch();
    }, 10 * 60 * 1000);

    return () => clearInterval(pollInterval);
  }, [permissionsQuery]);

  useHubSpotTracking(memberQuery.data?.me?.email);

  const isLoading = memberQuery.loading || permissionsQuery.loading;
  useEffect(() => {
    if (!router.isReady) return;
    if (workspaceSlug) {
      if (!isLoading) {
        hideLoader();
      }
    } else {
      hideLoader();
    }
  }, [router.isReady, isLoading, workspaceSlug]);

  const me = memberQuery.data?.me;
  const organisation = me?.organisation || null;
  const workspace = memberQuery.data?.workspace;
  const links = useLinks();
  const isSetupPage = [links.plan.href, links.setup.href].includes(router.pathname);
  const shouldRedirectToWorkspaceSetup = workspace && !workspace.onboarding?.setup && !isSetupPage;

  useEffect(() => {
    if (shouldRedirectToWorkspaceSetup) {
      if (organisation?.plan?.planId) {
        router.push(links.plan.href, links.plan.as);
      } else {
        router.push(links.setup.href, links.setup.as);
      }
    }
  }, [shouldRedirectToWorkspaceSetup, links, organisation, router]);

  const workspacesUsageQuery = useQuery(OrganisationWorkspacesUsageDocument, {
    skip: !isBrowser() || !isPowerUser(permissionsQuery.data?.me?.role),
  });

  const refetch = useCallback(async () => {
    const [memberQueryResult] = await Promise.all([
      memberQuery.refetch(),
      permissionsQuery.refetch(),
      workspacesUsageQuery.refetch(),
    ]);
    return memberQueryResult;
  }, [memberQuery, workspacesUsageQuery, permissionsQuery]);

  if (isLoading) return null;
  if (!isBrowser() || !router.isReady) return null;

  if (workspaceSlug && !workspace) {
    return throw404();
  }

  if (!memberQuery.data || !me || !organisation) {
    return throw404();
  }

  if (shouldRedirectToWorkspaceSetup) {
    return null;
  }

  const { planFeatures } = memberQuery.data;
  const plan = getPlanData({ planLimit: organisation.plan, planFeatures });

  return (
    <OrganisationContext.Provider
      value={{
        me,
        organisation,
        plan,
        workspace,
        workspaces: memberQuery.data?.memberWorkspaces || [],
        permissions: permissionsQuery.data?.me?.workspacePermissions || [],
        refetch,
        onboarding: { workspace: workspace?.onboarding ?? null, member: me.onboarding },
        currency: planFeatures?.currency,
        workspacesUsageQuery,
      }}>
      {children}
    </OrganisationContext.Provider>
  );
};

export default OrganisationProvider;
