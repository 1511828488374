import { IconContainer } from './IconContainer';

export const IconAssistants = () => (
  <IconContainer bgColor="#062af3">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
      <path
        d="M 10.648 4.393 C 10.885 4.275 11.034 4.032 11.034 3.767 C 11.034 3.503 10.885 3.26 10.648 3.142 L 7.313 1.474 C 7.116 1.376 6.884 1.376 6.687 1.474 L 3.352 3.142 C 3.115 3.26 2.966 3.503 2.966 3.767 C 2.966 4.032 3.115 4.275 3.352 4.393 L 6.687 6.061 C 6.884 6.159 7.116 6.159 7.313 6.061 Z M 3.113 5.839 C 2.896 5.731 2.638 5.743 2.432 5.87 C 2.226 5.998 2.1 6.223 2.1 6.465 L 2.1 10.5 C 2.1 10.765 2.25 11.007 2.487 11.126 L 5.287 12.526 C 5.504 12.634 5.762 12.623 5.968 12.495 C 6.174 12.368 6.3 12.143 6.3 11.9 L 6.3 7.865 C 6.3 7.6 6.15 7.358 5.913 7.239 Z"
        fill="hsl(0, 0%, 100%)"
      />
      <path
        d="M 7.7 11.9 C 7.7 12.143 7.826 12.368 8.032 12.495 C 8.238 12.623 8.496 12.634 8.713 12.526 L 11.513 11.126 C 11.75 11.007 11.9 10.765 11.9 10.5 L 11.9 6.465 C 11.9 6.223 11.774 5.998 11.568 5.87 C 11.362 5.743 11.104 5.731 10.887 5.839 L 8.087 7.239 C 7.85 7.358 7.7 7.6 7.7 7.865 Z"
        fill="rgba(255, 255, 255, 0.54)"
      />
    </svg>
  </IconContainer>
);
