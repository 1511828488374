import React from 'react';
import ItemColumn from './ItemColumn';
import { Checkbox, Skeleton } from '@layerise/design-core';
import { TagList } from 'components/tag/TagList';
import { ConnectionsButton } from 'components/connections/ConnectionsButton';
import { getLibraryItemTitleByType, isMediaType, LibraryItemsType, PickerType } from '../hooks/useData';
import NoWrapColumn from 'components/table/NoWrapColumn';
import { StatusTag } from 'components/tag/StatusTag';
import { MetaInfo } from 'components/table/MetaInfo';
import { LocaleConnectionsTag } from 'components/connections/LocaleConnectionsTag';
import { CheckboxMock, CheckboxWrapper } from './styles';
import { ItemViz } from 'components/media/ItemViz';

interface ColumnProps {
  item: LibraryItemsType;
  onClick: (itemId: string) => void;
  openConnectionsSidebar: React.Dispatch<React.SetStateAction<string>>;
  selected: boolean;
  multi?: boolean;
}

interface HeaderProps {
  types: PickerType[];
  selected: boolean;
  onSelectAll: () => void;
  geoFencingEnabled?: boolean;
  multi?: boolean;
}

export const libraryLoadingView = (types: PickerType[], multi?: boolean) => {
  const isMedia = types.every(isMediaType);
  return isMedia
    ? [
        {
          id: 'skeleton',
          columns: [
            ...(multi ? [{ id: '0', width: 24, content: <Skeleton height="24px" width="24px" /> }] : []),
            { id: '1', width: 48, content: <Skeleton height="32px" width="32px" /> },
            {
              id: '2',
              content: <Skeleton />,
            },
            {
              id: '3',
              content: <Skeleton />,
            },
            {
              id: '4',
              content: <Skeleton />,
            },
            {
              id: '5',
              content: <Skeleton />,
            },
            {
              id: '6',
              content: <Skeleton />,
            },
            {
              id: '7',
              content: <Skeleton />,
            },
          ],
        },
      ]
    : [
        {
          id: 'skeleton',
          columns: [
            ...(multi ? [{ id: '0', width: 24, content: <Skeleton height="24px" width="24px" /> }] : []),
            {
              id: '2',
              content: <Skeleton />,
            },
            {
              id: '3',
              content: <Skeleton />,
            },
            {
              id: '4',
              content: <Skeleton />,
            },
            {
              id: '5',
              content: <Skeleton />,
            },
            {
              id: '6',
              content: <Skeleton />,
            },
          ],
        },
      ];
};

export const libraryHeaderItems = ({ types, selected, onSelectAll, multi }: HeaderProps) => {
  const isMedia = types.every(isMediaType);
  return isMedia
    ? [
        ...(multi
          ? [
              {
                id: '0',
                content: (
                  <CheckboxWrapper>
                    <Checkbox
                      onChange={() => {
                        onSelectAll();
                      }}
                      value={`all`}
                      checked={selected}
                      name={`all`}
                      size="sm"
                    />
                  </CheckboxWrapper>
                ),
              },
            ]
          : []),
        { id: '1' },
        {
          id: '2',
          title: 'name',
        },
        {
          id: '3',
          title: 'Type',
        },
        {
          id: '4',
          title: 'Tags',
        },
        {
          id: '5',
          title: 'ID',
        },
        {
          id: '6',
          title: 'Created by',
          align: 'right',
          width: 100,
          padding: '0',
        },
        {
          id: '7',
          title: 'Updated by',
          align: 'left',
          width: 110,
          padding: '0 8px',
        },
      ]
    : [
        ...(multi ? [{ id: '0' }] : []),
        {
          id: '2',
          title: 'name',
        },
        {
          id: '3',
          title: 'Status',
        },
        {
          id: '4',
          title: 'Tags',
        },
        {
          id: '5',
          title: 'Languages',
        },
        {
          id: '6',
          title: 'Connections',
        },
        {
          id: '7',
          title: 'ID',
        },
        {
          id: '8',
          title: 'Updated by',
          padding: '0 8px',
          width: '110',
          align: 'left',
        },
      ];
};

export const libraryColumns = ({ item, onClick, selected, openConnectionsSidebar, multi }: ColumnProps) => {
  const isMedia = item.__typename === 'Image' || item.__typename === 'Video' || item.__typename === 'Pdf';

  if (isMedia) {
    return [
      ...(multi
        ? [
            {
              id: '0',
              width: 24,
              content: (
                <ItemColumn itemId={item.id} onClick={onClick} contentAlign="center">
                  <CheckboxMock>
                    <Checkbox name={item.id} checked={selected} size="sm" />
                  </CheckboxMock>
                </ItemColumn>
              ),
            },
          ]
        : []),
      {
        id: '1',
        width: 64,
        content: (
          <ItemColumn itemId={item.id} onClick={onClick} contentAlign="center">
            <ItemViz item={item} />
          </ItemColumn>
        ),
      },
      {
        id: '2',
        content: (
          <ItemColumn itemId={item.id} onClick={onClick}>
            {getLibraryItemTitleByType(item)}
          </ItemColumn>
        ),
        fontWeight: 600,
        fontColor: 'var(--color-N600)',
        fontSize: 14,
      },
      {
        id: '3',
        content: (
          <ItemColumn itemId={item.id} onClick={onClick}>
            {item.__typename}
          </ItemColumn>
        ),
      },
      {
        id: '4',
        content: (
          <ItemColumn itemId={item.id} onClick={onClick}>
            {'metaInfo' in item && item.metaInfo ? (
              <TagList
                wrap
                viewOnly
                key={item.id}
                multiLine={false}
                tags={item.metaInfo.tags.map(tag => ({ ...tag, checked: true }))}
              />
            ) : null}
          </ItemColumn>
        ),
      },
      {
        id: '5',
        content: (
          <ItemColumn itemId={item.id} onClick={onClick}>
            {item.metaInfo?.reference}
          </ItemColumn>
        ),
      },
      {
        id: '6',
        width: 100,
        padding: '0 12px',
        content: (
          <ItemColumn itemId={item.id} onClick={onClick}>
            <MetaInfo transactionLogs={item.createdLogItems} updatedAt={item.createdAt} />
          </ItemColumn>
        ),
      },
      {
        id: '7',
        width: 100,
        padding: '0 12px',
        content: (
          <ItemColumn itemId={item.id} onClick={onClick}>
            <MetaInfo transactionLogs={item.updatedLogItems} updatedAt={item.updatedAt} />
          </ItemColumn>
        ),
      },
    ];
  }

  return [
    ...(multi
      ? [
          {
            id: '0',
            width: 24,
            content: (
              <ItemColumn itemId={item.id} onClick={onClick} contentAlign="center">
                <CheckboxMock>
                  <Checkbox name={item.id} checked={selected} size="sm" />
                </CheckboxMock>
              </ItemColumn>
            ),
          },
        ]
      : []),
    {
      id: '2',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          {getLibraryItemTitleByType(item)}
        </ItemColumn>
      ),
      fontWeight: 600,
      fontColor: 'var(--color-N600)',
      fontSize: 14,
    },
    {
      id: '3',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          <StatusTag archived={item.metaInfo?.archived} published={item.metaInfo?.published} />
        </ItemColumn>
      ),
    },
    {
      id: '4',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          <TagList viewOnly multiLine={false} tags={item.metaInfo?.tags} />
        </ItemColumn>
      ),
    },
    {
      id: '5',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          <LocaleConnectionsTag entityName={item.__typename.toLowerCase()} locales={item.metaInfo?.locales} />
        </ItemColumn>
      ),
    },
    {
      id: '6',
      content: (
        <ConnectionsButton
          number={item.metaInfo?.connections || 0}
          onClick={() => {
            if (item.metaInfo?.reference) {
              openConnectionsSidebar(prev => {
                return prev === item.metaInfo?.reference ? '' : item.metaInfo?.reference || '';
              });
            }
          }}
        />
      ),
    },
    {
      id: '7',
      content: <NoWrapColumn>{item.metaInfo?.reference}</NoWrapColumn>,
    },
    {
      id: '8',
      content: (
        <ItemColumn itemId={item.id} onClick={onClick}>
          <MetaInfo transactionLogs={item.transactionLogs} updatedAt={item.updatedAt} selected={selected} />
        </ItemColumn>
      ),
    },
  ];
};
