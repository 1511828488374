import { ComponentProps } from 'react';
import { MessageBubble } from '@layerise/design-chat';
import { Conversation, Message } from '@twilio/conversations';
import { ChatMessage, Customer, isMediaType, Member, MessageType } from './types';
import { getFullName } from 'lib/utils';
import { WorkspaceActiveConversationsQuery, WorkspaceArchivedConversationsQuery } from 'types/typed-document-nodes';

export const mapMessage = ({
  message: m,
  messageAuthors,
  customer,
}: {
  message: Message;
  messageAuthors: Member[];
  customer: Customer | null | undefined;
}) => {
  const messageAttributes = m.attributes as { type?: MessageType; id?: string; filename?: string };
  const type: MessageType = messageAttributes.type || 'MESSAGE';
  return {
    id: m.sid,
    twilioId: m.sid,
    index: m.index,
    type,
    ...((m.author?.startsWith('cus_') || (type === 'RATE' && m.body !== '0')) && {
      customer: customer,
    }),
    ...(m.author?.startsWith('mem_') && {
      member: messageAuthors.find(supportMember => `mem_${supportMember.id}` === m.author),
    }),
    message: isMediaType(type) ? messageAttributes.id : m.body,
    filename: messageAttributes.filename,
    createdAt: m.dateCreated || new Date(),
  };
};

type MessageBubbleProps = ComponentProps<typeof MessageBubble>;

export const getMessageBubbleProps = (
  message: ChatMessage,
  memberId: string,
  authorStatus: MessageBubbleProps['authorStatus'],
  lastCustomerLastReadIndex: number | null
) => {
  let title = message.type === 'PROMOTION' ? 'SMS Campaign' : 'Not found.';
  let read = false;
  let status: MessageBubbleProps['status'] = 'none';

  if (message.customer || message.member) {
    let titleFound: string | null | undefined;

    if (message.customer) {
      if (message.customer.firstName) {
        titleFound = getFullName(message.customer);
      } else {
        titleFound = message.customer.email;
      }
    }

    if (message.member) {
      status = 'sent';
      if (
        lastCustomerLastReadIndex !== null &&
        typeof message.index !== 'undefined' &&
        message.index !== null &&
        lastCustomerLastReadIndex >= message.index
      ) {
        read = true;
      }
      if (memberId === message.member.id) {
        titleFound = 'You';
      } else if (message.member.firstName) {
        titleFound = getFullName(message.member);
      } else {
        if (message.member.deleted) {
          titleFound = `Unknown`;
        } else {
          titleFound = message.member.email;
        }
      }
    }
    if (titleFound && titleFound.length > 0) {
      title = titleFound;
    }
  }

  let type: MessageBubbleProps['type'];
  if (message.type === 'IMAGE') {
    type = 'image';
  } else if (message.type === 'VIDEO') {
    type = 'video';
  } else if (message.type === 'FILE') {
    type = 'file';
  } else {
    type = 'text';
  }

  return {
    key: message.id,
    text: `${message.message}`,
    right: !message.customer,
    name: title,
    type,
    status: status,
    read: read,
    authorStatus,
    ...(message.filename && {
      filename: message.filename,
    }),
    ...(message.customer?.avatarImg && {
      src: message.customer.avatarImg?.dynamic,
    }),
    ...(message.member?.avatarImg && {
      src: message.member.avatarImg?.dynamic,
    }),
    bgColor: 'var(--color-N150)',
    brandColor: 'var(--color-B500)',
  };
};

export const isUnread = (
  twilioConvo?: Conversation,
  orgMessages: Record<Conversation['sid'], Message[]> = {}
): Boolean => {
  if (!twilioConvo) {
    return false;
  }
  if (twilioConvo.state?.current !== 'active') {
    return false;
  }
  const messages = orgMessages[twilioConvo.sid] || [];
  const lastMessage = messages[messages.length - 1];
  if (!lastMessage) {
    return false;
  }
  if (!lastMessage.author?.startsWith('cus_')) {
    return false;
  }
  const lastCustomerMessage = getLastCustomerMessage(twilioConvo, orgMessages);
  if (
    !lastCustomerMessage ||
    (typeof lastCustomerMessage.attributes === 'object' &&
      lastCustomerMessage.attributes !== null &&
      'type' in lastCustomerMessage.attributes &&
      lastCustomerMessage.attributes.type === 'RATE')
  ) {
    return false;
  }

  let isUnread = false;
  if (twilioConvo.lastReadMessageIndex === null || twilioConvo.lastReadMessageIndex < lastCustomerMessage.index) {
    isUnread = true;
  }

  return isUnread;
};

export const getLastCustomerMessage = (
  twilioConvo?: Conversation,
  workspaceMessages: Record<Conversation['sid'], Message[]> = {}
): Message | undefined => {
  if (!twilioConvo) {
    return undefined;
  }
  return [...(workspaceMessages[twilioConvo.sid] || [])].reverse().find(m => m.author?.startsWith('cus_'));
};

type WorkspaceConversation =
  | WorkspaceActiveConversationsQuery['getWorkspaceActiveConversations'][number]
  | WorkspaceArchivedConversationsQuery['getWorkspaceArchivedConversations'][number];
type ConversationRef = { __ref: string };
type Conversations = Array<WorkspaceConversation | ConversationRef>;

export const conversationsMergeFunction = (existing: Conversations = [], incoming: Conversations) => {
  const mergedConversations = [...existing, ...incoming];
  const uniqueIds = new Set<string>();
  const uniqueConversations = [];

  for (const conversation of mergedConversations) {
    const key = 'id' in conversation ? conversation.id : conversation.__ref;
    if (!uniqueIds.has(key)) {
      uniqueConversations.push(conversation);
      uniqueIds.add(key);
    }
  }

  return uniqueConversations;
};
