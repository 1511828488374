import { IconShield } from 'components/icons/IconShield';
import styled from 'styled-components';
import { Member } from 'types/typed-document-nodes';

const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  gap: 2px;
`;

const IconContainer = styled.div`
  display: inline-block;
`;

type Props = {
  member: Pick<Member, 'twoFactorAuthEnabled' | 'phone'>;
};

export function MemberPhoneInfo({ member }: Props) {
  return (
    <Container>
      {member.twoFactorAuthEnabled ? (
        <IconContainer title="2FA enabled">
          <IconShield style={{ position: 'relative', top: 1 }} />
        </IconContainer>
      ) : null}
      <span>{member.phone}</span>
    </Container>
  );
}
