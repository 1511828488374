import React, { useMemo } from 'react';
import { Tooltip, TooltipIconContainer } from '@layerise/design-core';
import { IconLocalisation, IconLocation, IconMiniInfo } from '@layerise/design-icon';
import styled from 'styled-components';
import { IconAssistants } from 'components/icons/IconAssistants';
import { IconProducts } from 'components/icons/IconProducts';
import { IconDocuments } from 'components/icons/IconDocuments';

export enum ContentItemType {
  Product,
  Assistant,
  Component,
  Story,
  Article,
  Warranty,
  Media,
  Form,
}

export enum ResponseType {
  products = 'products',
  assistants = 'assistants',
}

interface Props {
  title: string;
  hasEntities?: boolean;
  contentItemType: ContentItemType;
  animationFinished?: boolean;
  tooltipText?: string;
  tooltipWidth?: number;
}

const Header = styled.div`
  border-radius: var(--radius-large) var(--radius-large) 0 0;
  border-bottom: 1px solid #ededf3;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f7fa;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  & > div:nth-child(1) {
    margin-right: 8px;
  }

  & span {
    font-weight: 599;
    font-size: 13px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  background-color: #7a52d6;
  overflow: visible;
  border-radius: 4px;
  color: #fff;
`;

const InfoIconContainer = styled(IconContainer)`
  background-color: var(--color-info-secondary);
  svg path {
    fill: var(--color-info-primary);
  }
`;

export const getEntityName = (contentItemType: ContentItemType) => {
  switch (contentItemType) {
    case ContentItemType.Product:
      return 'product';
    case ContentItemType.Article:
      return 'article';
    case ContentItemType.Story:
      return 'story';
    case ContentItemType.Component:
      return 'component';
    case ContentItemType.Warranty:
      return 'warranty';
    case ContentItemType.Media:
      return 'media';
    case ContentItemType.Assistant:
      return 'assistant';
    case ContentItemType.Form:
      return 'form';
    default:
      return '';
  }
};

export const getResponseType = (contentItemType: ContentItemType): ResponseType => {
  switch (contentItemType) {
    case ContentItemType.Warranty:
    case ContentItemType.Assistant:
    case ContentItemType.Form:
      return ResponseType.products;
    default:
      return ResponseType.assistants;
  }
};

export const ConnectionHeader = ({
  hasEntities = true,
  title,
  contentItemType,
  animationFinished,
  tooltipText,
  tooltipWidth = 250,
}: Props) => {
  const tooltip = useMemo(() => {
    if (tooltipText) {
      return tooltipText;
    }
    if ([ContentItemType.Assistant, ContentItemType.Warranty, ContentItemType.Form].includes(contentItemType)) {
      const entityName = getEntityName(contentItemType);
      return hasEntities
        ? `This ${entityName} is assigned to one or multiple products`
        : `This ${entityName} is not assigned to any products`;
    } else {
      const contentItemName = getEntityName(contentItemType);
      return hasEntities
        ? `This ${contentItemName} is being used in one or multiple places inside the listed Assistants`
        : `This ${contentItemName} is not used in any assistants`;
    }
  }, [tooltipText, contentItemType, hasEntities]);

  return (
    <Header>
      <Title>
        <IconContainer>
          {[
            ContentItemType.Component,
            ContentItemType.Story,
            ContentItemType.Article,
            ContentItemType.Product,
            ContentItemType.Media,
          ].includes(contentItemType) ? (
            <IconAssistants />
          ) : (
            <IconProducts />
          )}
        </IconContainer>
        <span>{title}</span>
      </Title>
      <Tooltip placement={animationFinished ? 'top left' : 'top'} width={tooltipWidth} tooltip={tooltip}>
        <TooltipIconContainer>
          <IconMiniInfo />
        </TooltipIconContainer>
      </Tooltip>
    </Header>
  );
};

export function LanguageConnectionHeader(props: {
  total: number | undefined | null;
  animationFinished?: boolean;
  entityName: string;
}) {
  const total = props.total ?? 0;
  return (
    <Header>
      <Title>
        <InfoIconContainer>
          <IconLocalisation height="10px" width="10px" />
        </InfoIconContainer>
        <span>{`${total} Language${total !== 1 ? 's' : ''}`}</span>
      </Title>
      <Tooltip
        placement={props.animationFinished ? 'top left' : 'top'}
        width={235}
        tooltip={`This ${props.entityName} is connected to the listed Languages.`}>
        <TooltipIconContainer>
          <IconMiniInfo />
        </TooltipIconContainer>
      </Tooltip>
    </Header>
  );
}

export function CountryConnectionHeader(props: {
  total: number | undefined | null;
  animationFinished?: boolean;
  entityName: string;
}) {
  const total = props.total ?? 0;
  return (
    <Header>
      <Title>
        <InfoIconContainer>
          <IconLocation height="10px" width="10px" />
        </InfoIconContainer>
        <span>{`${total} ${total !== 1 ? 'Countries' : 'Country'}`}</span>
      </Title>
      <Tooltip
        placement={props.animationFinished ? 'top left' : 'top'}
        width={235}
        tooltip={`This ${props.entityName} is connected to the listed Countries.`}>
        <TooltipIconContainer>
          <IconMiniInfo />
        </TooltipIconContainer>
      </Tooltip>
    </Header>
  );
}

export function AssistantConnectionHeader(props: {
  total: number | undefined | null;
  animationFinished?: boolean;
  entityName: string;
}) {
  const total = props.total ?? 0;
  return (
    <Header>
      <Title>
        <IconContainer style={{ backgroundColor: '#fe871a' }}>
          <IconDocuments />
        </IconContainer>
        <span>{`${total} ${total !== 1 ? 'Assistants' : 'Assistant'}`}</span>
      </Title>
      <Tooltip
        placement={props.animationFinished ? 'top left' : 'top'}
        width={235}
        tooltip={`This ${props.entityName} is connected to the listed Assistants.`}>
        <TooltipIconContainer>
          <IconMiniInfo />
        </TooltipIconContainer>
      </Tooltip>
    </Header>
  );
}
