import * as React from 'react';
import { SVGProps } from 'react';

const IconAdvancedComponents = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path d="m7.236.8 7.84 1.272-1.2 7.828-2.377-3.802L9.424 9.42l-3.426-.557Z" fill="#FFF" />
    <path
      d="M5.544 5.233a3.92 3.92 0 0 0-4.438.955 4.202 4.202 0 0 0-.72 4.603c.709 1.54 2.268 2.471 3.92 2.339 1.652-.129 3.055-1.294 3.528-2.927L4.856 9.72l.689-4.488v.001Z"
      fill="rgba(255, 255, 255, 0.5)"
    />
    <path d="M7 15.2 11.499 8 16 15.2Z" fill="#FFF" />
  </svg>
);

export default IconAdvancedComponents;
