import * as React from 'react';
import { SVGProps } from 'react';

const IconUpsellForms = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      d="M5.338 1.529a.8.8 0 0 0-1.546.414l.207.773a.801.801 0 0 0 1.546-.414l-.208-.773ZM1.943 3.792a.8.8 0 1 0-.413 1.546l.773.207a.8.8 0 0 0 .413-1.546l-.773-.208Zm7.052-.455a.8.8 0 0 0-1.132-1.131l-.566.565a.8.8 0 1 0 1.132 1.132ZM3.338 8.995l.565-.566a.8.8 0 1 0-1.131-1.133l-.566.566a.8.8 0 1 0 1.132 1.132Z"
      fill="rgba(255, 255, 255, 0.5)"
    />
    <path
      d="M5.898 4.858a.8.8 0 0 0-1.04 1.04l3.2 8a.8.8 0 0 0 1.458.06l1.104-2.207 2.415 2.416a.8.8 0 0 0 1.131-1.132l-2.415-2.416 2.208-1.104a.8.8 0 0 0-.061-1.457Z"
      fill="#FFF"
    />
  </svg>
);

export default IconUpsellForms;
