import { AccessLevels, AccessLevel } from 'types/typed-document-nodes';
import styled from 'styled-components';

const IconContainer = styled.div<{ $bgColor: string }>`
  width: 32px;
  height: 32px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  background-color: ${props => props.$bgColor};
  overflow: visible;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #fff;
`;

type PermissionOption = {
  key: keyof Omit<AccessLevels, '__typename'>;
  icon?: React.ReactNode;
  title: string;
  description: { [Property in AccessLevel]: string };
};

export const PermissionOptions: PermissionOption[] = [
  {
    key: 'customers',
    title: 'Customers',
    icon: (
      <IconContainer $bgColor="#052bfc">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
          <path
            fill="hsl(0, 0%, 100%)"
            d="M8.1 5.4a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0ZM5.4 9.9a4.5 4.5 0 0 1 4.5 4.5v.9h-9v-.9a4.5 4.5 0 0 1 4.5-4.5Z"
          />
          <path
            fill="rgba(255, 255, 255, 0.54)"
            d="M15.3 5.4a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0Zm-3.663 9.9c.041-.294.063-.594.063-.9a6.276 6.276 0 0 0-1.35-3.897A4.5 4.5 0 0 1 17.1 14.4v.9Z"
          />
        </svg>
      </IconContainer>
    ),
    description: {
      [AccessLevel.NoAccess]: "Member won't have access to customers section.",
      [AccessLevel.Viewer]: "Member can see customers section but can't do actions.",
      [AccessLevel.Contributor]: 'Member can see customers section and do actions.',
    },
  },
  {
    key: 'library',
    title: 'Library',
    icon: (
      <IconContainer $bgColor="#fe871a">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
          <path
            d="M 1.8 5.4 C 1.8 4.406 2.606 3.6 3.6 3.6 L 7.2 3.6 L 9 5.4 L 12.6 5.4 C 13.594 5.4 14.4 6.206 14.4 7.2 L 14.4 8.1 L 7.2 8.1 C 5.709 8.1 4.5 9.309 4.5 10.8 L 4.5 12.15 C 4.5 12.896 3.896 13.5 3.15 13.5 C 2.404 13.5 1.8 12.896 1.8 12.15 Z"
            fill="hsl(0, 0%, 100%)"
          />
          <path
            d="M 5.4 10.8 C 5.4 9.806 6.206 9 7.2 9 L 14.4 9 C 15.394 9 16.2 9.806 16.2 10.8 L 16.2 12.6 C 16.2 13.594 15.394 14.4 14.4 14.4 L 1.8 14.4 L 3.6 14.4 C 4.594 14.4 5.4 13.594 5.4 12.6 Z"
            fill="rgba(255, 255, 255, 0.54)"
          />
        </svg>
      </IconContainer>
    ),
    description: {
      [AccessLevel.NoAccess]: "Member won't have access to library section.",
      [AccessLevel.Viewer]: "Member can see library section but can't do actions.",
      [AccessLevel.Contributor]: 'Member can see library section and do actions.',
    },
  },
  {
    key: 'conversations',
    title: 'Conversations',
    icon: (
      <IconContainer $bgColor="#2f95e1">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="18" height="18">
          <path
            fillRule="evenodd"
            d="M18 13V5a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h3l3 3 3-3h3a2 2 0 0 0 2-2zM5 7a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1zm1 3a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2H6z"
            clipRule="evenodd"
          />
        </svg>
      </IconContainer>
    ),
    description: {
      [AccessLevel.NoAccess]: "Member won't have access to conversations section.",
      [AccessLevel.Viewer]: "Member can see conversations section but can't do actions.",
      [AccessLevel.Contributor]: 'Member can see conversations section and do actions.',
    },
  },
  {
    key: 'service',
    title: 'Service',
    icon: (
      <IconContainer $bgColor="#01a5a1">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
          <path
            d="M 3.6 3.6 C 3.6 2.606 4.406 1.8 5.4 1.8 L 9.527 1.8 C 10.005 1.8 10.463 1.99 10.8 2.327 L 13.873 5.4 C 14.21 5.737 14.4 6.195 14.4 6.673 L 14.4 14.4 C 14.4 15.394 13.594 16.2 12.6 16.2 L 11.225 16.2 C 13.134 14.064 13.043 10.809 11.017 8.783 C 8.991 6.757 5.736 6.666 3.6 8.575 Z"
            fill="rgba(255, 255, 255, 0.5)"></path>
          <path
            d="M 7.2 9 C 5.908 9 4.715 9.692 4.074 10.814 C 3.433 11.936 3.442 13.316 4.099 14.429 L 2.964 15.563 C 2.612 15.914 2.612 16.484 2.963 16.836 C 3.315 17.188 3.885 17.188 4.236 16.836 L 5.371 15.701 C 6.678 16.472 8.328 16.34 9.496 15.372 C 10.665 14.404 11.1 12.807 10.586 11.379 C 10.071 9.952 8.717 9 7.2 9 Z M 5.4 12.6 C 5.4 11.606 6.206 10.8 7.2 10.8 C 8.194 10.8 9 11.606 9 12.6 C 9 13.594 8.194 14.4 7.2 14.4 C 6.206 14.4 5.4 13.594 5.4 12.6 Z"
            fill="hsl(0, 0%, 100%)"></path>
        </svg>
      </IconContainer>
    ),
    description: {
      [AccessLevel.NoAccess]: "Member won't have access to service section.",
      [AccessLevel.Viewer]: "Member can see service section but can't do actions.",
      [AccessLevel.Contributor]: 'Member can see service section and do actions.',
    },
  },
  {
    key: 'workspaceSettings',
    title: 'Workspace settings',
    icon: (
      <IconContainer $bgColor="#474666">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="18" height="18">
          <path d="M5 4a1 1 0 0 0-2 0v7.268a2 2 0 0 0 0 3.464V16a1 1 0 1 0 2 0v-1.268a2 2 0 0 0 0-3.464V4zm6 0a1 1 0 1 0-2 0v1.268a2 2 0 0 0 0 3.464V16a1 1 0 1 0 2 0V8.732a2 2 0 0 0 0-3.464V4zm5-1a1 1 0 0 1 1 1v7.268a2 2 0 0 1 0 3.464V16a1 1 0 1 1-2 0v-1.268a2 2 0 0 1 0-3.464V4a1 1 0 0 1 1-1z" />
        </svg>
      </IconContainer>
    ),
    description: {
      [AccessLevel.NoAccess]: "Member won't have access to workspace settings.",
      [AccessLevel.Viewer]: "Member can see workspace settings but can't do actions.",
      [AccessLevel.Contributor]: 'Member can see workspace settings and do actions.',
    },
  },
  {
    key: 'marketing',
    title: 'Marketing',
    icon: (
      <IconContainer $bgColor="#7a52d6">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
          <path
            d="M 10.8 1.8 C 11.208 1.8 11.566 2.075 11.67 2.47 L 12.731 6.48 L 15.75 8.221 C 16.028 8.381 16.2 8.678 16.2 9 C 16.2 9.322 16.028 9.619 15.75 9.779 L 12.731 11.521 L 11.669 15.53 C 11.565 15.925 11.208 16.199 10.8 16.199 C 10.391 16.199 10.034 15.925 9.93 15.53 L 8.869 11.52 L 5.85 9.779 C 5.572 9.619 5.4 9.322 5.4 9 C 5.4 8.678 5.572 8.381 5.85 8.221 L 8.869 6.479 L 9.931 2.47 C 10.035 2.075 10.392 1.8 10.8 1.8 Z"
            fill="hsl(0, 0%, 100%)"
          />
          <path
            d="M 4.5 1.8 C 4.997 1.8 5.4 2.203 5.4 2.7 L 5.4 3.6 L 6.3 3.6 C 6.797 3.6 7.2 4.003 7.2 4.5 C 7.2 4.997 6.797 5.4 6.3 5.4 L 5.4 5.4 L 5.4 6.3 C 5.4 6.797 4.997 7.2 4.5 7.2 C 4.003 7.2 3.6 6.797 3.6 6.3 L 3.6 5.4 L 2.7 5.4 C 2.203 5.4 1.8 4.997 1.8 4.5 C 1.8 4.003 2.203 3.6 2.7 3.6 L 3.6 3.6 L 3.6 2.7 C 3.6 2.203 4.003 1.8 4.5 1.8 Z"
            fill="rgba(255, 255, 255, 0.54)"
          />
          <path
            d="M 4.5 10.8 C 4.997 10.8 5.4 11.203 5.4 11.7 L 5.4 12.6 L 6.3 12.6 C 6.797 12.6 7.2 13.003 7.2 13.5 C 7.2 13.997 6.797 14.4 6.3 14.4 L 5.4 14.4 L 5.4 15.3 C 5.4 15.797 4.997 16.2 4.5 16.2 C 4.003 16.2 3.6 15.797 3.6 15.3 L 3.6 14.4 L 2.7 14.4 C 2.203 14.4 1.8 13.997 1.8 13.5 C 1.8 13.003 2.203 12.6 2.7 12.6 L 3.6 12.6 L 3.6 11.7 C 3.6 11.203 4.003 10.8 4.5 10.8 Z"
            fill="rgba(255, 255, 255, 0.54)"
          />
        </svg>
      </IconContainer>
    ),
    description: {
      [AccessLevel.NoAccess]: "Member won't have access to marketing section.",
      [AccessLevel.Viewer]: "Member can see marketing section but can't do actions.",
      [AccessLevel.Contributor]: 'Member can see marketing section and do actions.',
    },
  },
  {
    key: 'automation',
    title: 'Automation',
    icon: (
      <IconContainer $bgColor="#db4dab">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
          <path
            d="M 7.2 4.5 C 6.703 4.5 6.3 4.903 6.3 5.4 C 6.3 5.897 6.703 6.3 7.2 6.3 L 12.227 6.3 L 11.064 7.464 C 10.723 7.817 10.728 8.378 11.075 8.725 C 11.422 9.072 11.983 9.077 12.336 8.736 L 15.036 6.036 C 15.388 5.685 15.388 5.115 15.036 4.764 L 12.336 2.064 C 12.11 1.83 11.776 1.736 11.461 1.818 C 11.146 1.901 10.901 2.146 10.818 2.461 C 10.736 2.776 10.83 3.11 11.064 3.336 L 12.227 4.5 Z"
            fill="rgb(255, 255, 255)"
          />
          <path
            d="M 10.8 13.5 C 11.297 13.5 11.7 13.097 11.7 12.6 C 11.7 12.103 11.297 11.7 10.8 11.7 L 5.773 11.7 L 6.936 10.536 C 7.17 10.31 7.264 9.976 7.182 9.661 C 7.099 9.346 6.854 9.101 6.539 9.018 C 6.224 8.936 5.89 9.03 5.664 9.264 L 2.964 11.964 C 2.612 12.315 2.612 12.885 2.964 13.236 L 5.664 15.936 C 6.017 16.277 6.578 16.272 6.925 15.925 C 7.272 15.578 7.277 15.017 6.936 14.664 L 5.773 13.5 Z"
            fill="hsla(0, 0%, 100%, 0.54)"
          />
        </svg>
      </IconContainer>
    ),
    description: {
      [AccessLevel.NoAccess]: "Member won't have access to automation section.",
      [AccessLevel.Viewer]: "Member can see automation section but can't do actions.",
      [AccessLevel.Contributor]: 'Member can see automation section and do actions.',
    },
  },
];
