import { Button, ModalActions, Modal, Img } from '@layerise/design-core';
import { IconInfo } from '@layerise/design-icon';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { useContext } from 'react';
import { LearnMore, Content, Body, Title, UpdateTag, ModalHero } from './SharedStyles';
import { useMutation } from '@apollo/client';
import { SetMemberOnboardingDocument } from 'types/typed-document-nodes';
import { NORMAL_MODAL_Z_INDEX, FeatureModalProps } from './constants';

export const ArticlesUpdate = (props: FeatureModalProps) => {
  const [setOnboarding] = useMutation(SetMemberOnboardingDocument);

  const { refetch } = useContext(OrganisationContext);

  const handleClick = async () => {
    props.onRequestClose();
    await setOnboarding({
      variables: {
        discoverArticle: true,
      },
    });
    await refetch();
  };

  return (
    <Modal
      onClick={props.onRequestClose}
      modalContainerStyle={{ zIndex: NORMAL_MODAL_Z_INDEX + 1 }}
      containerStyle={{ zIndex: NORMAL_MODAL_Z_INDEX + 2 }}
      padding="0px 0px 0px 0px"
      height="620px"
      width="520px">
      <ModalHero>
        <UpdateTag>UPDATE</UpdateTag>
        <Img alt="articles" src="WWW/productUpdates/articles" height={776} width={1040} quality={80} />
      </ModalHero>
      <Content>
        <Title>Articles with smart commands </Title>
        <Body>
          Each product requires documentation and information, and Articles are perfect for that. Use the smart commands
          and admonitions to build customer friendly and reusable content. All Articles in the library will be reusable
          in any Assistant.
        </Body>
        <ModalActions
          negative={
            <LearnMore
              href={`${process.env.URL_WWW}/help/assistant/creating-an-assistant/create-an-article`}
              target="_blank"
              rel="noreferrer">
              Learn more <IconInfo />
            </LearnMore>
          }>
          <Button size="lg" variant="primary" onClick={handleClick}>
            Close
          </Button>
        </ModalActions>
      </Content>
    </Modal>
  );
};
