import { IconContainer } from './IconContainer';

export const IconAutomationRules = () => (
  <IconContainer bgColor="#db4dab">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
      <path
        d="M 5.6 3.5 C 5.213 3.5 4.9 3.813 4.9 4.2 C 4.9 4.587 5.213 4.9 5.6 4.9 L 9.51 4.9 L 8.605 5.805 C 8.34 6.08 8.344 6.516 8.614 6.786 C 8.884 7.056 9.32 7.06 9.595 6.795 L 11.695 4.695 C 11.968 4.422 11.968 3.978 11.695 3.705 L 9.595 1.605 C 9.419 1.423 9.159 1.35 8.914 1.414 C 8.669 1.478 8.478 1.669 8.414 1.914 C 8.35 2.159 8.423 2.419 8.605 2.595 L 9.51 3.5 Z"
        fill="rgb(255, 255, 255)"
      />
      <path
        d="M 8.4 10.5 C 8.787 10.5 9.1 10.187 9.1 9.8 C 9.1 9.413 8.787 9.1 8.4 9.1 L 4.49 9.1 L 5.395 8.195 C 5.577 8.019 5.65 7.759 5.586 7.514 C 5.522 7.269 5.331 7.078 5.086 7.014 C 4.841 6.95 4.581 7.023 4.405 7.205 L 2.305 9.305 C 2.032 9.578 2.032 10.022 2.305 10.295 L 4.405 12.395 C 4.68 12.66 5.116 12.656 5.386 12.386 C 5.656 12.116 5.66 11.68 5.395 11.405 L 4.49 10.5 Z"
        fill="hsla(0, 0%, 100%, 0.54)"
      />
    </svg>
  </IconContainer>
);
