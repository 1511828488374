import React from 'react';
import { Table } from 'components/table/Table';
import {
  ContentLibraryQuery,
  ProductListingQuery,
  WorkspacesQuery,
  WorkspaceFormsQuery,
  CustomerListsQuery,
} from 'types/typed-document-nodes';
import {
  PickerType,
  ProductType,
  WorkspaceType,
  FormsType,
  CustomersListType,
  WarrantyListType,
  LibraryItemsType,
} from '../hooks/useData';
import { productColumns, productHeaderItems, productLoadingView } from './ColumnsProduct';
import { libraryColumns, libraryHeaderItems, libraryLoadingView } from './ColumnsLibraryItems';
import { workspacesColumns, workspacesHeaderItems, workspacesLoadingView } from './ColumnsWorkspaces';
import { formsColumns, formsHeaderItems, formsLoadingView } from './ColumnsForms';
import { customersListColumns, customersListHeaderItems, customersListLoadingView } from './ColumnsCustomersList';
import { warrantiesHeaderItems, warrantiesLoadingView, warrantyColumns } from './ColumnsWarranty';
import { useGeofencingEnabled } from 'hooks/data/useGeofencingEnabled';

type TableItems =
  | ContentLibraryQuery['library']['items']
  | ProductListingQuery['products']
  | NonNullable<WorkspacesQuery['workspaces']>
  | WorkspaceFormsQuery['forms']
  | CustomerListsQuery['customerLists'];

interface TableWrapperProps<T extends TableItems> {
  types: PickerType[];
  selectedItemIds?: string[];
  items: T | undefined;
  loading?: boolean;
  selectedAll?: boolean;
  multi?: boolean;
  onAllItemsSelect?: (selected: boolean) => void;
  onItemSelect: (itemId: string) => void;
  openConnectionsSidebar: React.Dispatch<React.SetStateAction<string>>;
}

interface HeaderItemProps {
  onSelectAll: () => void;
  selected: boolean;
}

const loadingView = (types: PickerType[], multi?: boolean) => {
  if (types[0] === ProductType.Product) {
    return productLoadingView;
  }
  if (types[0] === WorkspaceType.Workspace) {
    return workspacesLoadingView;
  }
  if (types[0] === FormsType.Form) {
    return formsLoadingView(multi);
  }
  if (types[0] === CustomersListType.CustomersList) {
    return customersListLoadingView;
  }
  if (types[0] === WarrantyListType.Warranty) {
    return warrantiesLoadingView;
  }
  return libraryLoadingView(types, multi);
};

const headerItems = (
  types: PickerType[],
  headerProps: HeaderItemProps,
  geoFencingEnabled: boolean,
  multi?: boolean
) => {
  if (types[0] === ProductType.Product) {
    return productHeaderItems(headerProps);
  }
  if (types[0] === WorkspaceType.Workspace) {
    return workspacesHeaderItems;
  }
  if (types[0] === FormsType.Form) {
    return formsHeaderItems({
      ...headerProps,
      multi,
    });
  }
  if (types[0] === CustomersListType.CustomersList) {
    return customersListHeaderItems;
  }
  if (types[0] === WarrantyListType.Warranty) {
    return warrantiesHeaderItems;
  }
  return libraryHeaderItems({
    types,
    geoFencingEnabled,
    multi,
    ...headerProps,
  });
};

const TableWrapper = <T extends TableItems>({
  types,
  selectedItemIds = [],
  multi,
  items,
  loading,
  selectedAll,
  onAllItemsSelect,
  onItemSelect,
  openConnectionsSidebar,
}: TableWrapperProps<T>) => {
  const geoFencingEnabled = useGeofencingEnabled();
  return (
    <Table
      headerItems={headerItems(
        types,
        {
          onSelectAll: () => {
            if (typeof onAllItemsSelect === 'undefined') {
              return;
            }
            onAllItemsSelect(!selectedAll);
          },
          selected: typeof selectedAll !== 'undefined' ? selectedAll : false,
        },
        geoFencingEnabled,
        multi
      )}
      items={
        loading
          ? loadingView(types)
          : (items || []).map(item => {
              if (!item) return { id: '', columns: [] };

              const selected = selectedItemIds.includes(item.id);

              return {
                id: item?.id,
                selected,
                columns:
                  item.__typename === 'Product'
                    ? productColumns({
                        product: item,
                        selected,
                        onClick: onItemSelect,
                        geoFencingEnabled,
                      })
                    : item.__typename === 'Workspace'
                    ? workspacesColumns({
                        item,
                        onClick: onItemSelect,
                        openConnectionsSidebar: openConnectionsSidebar,
                      })
                    : item.__typename === 'Form'
                    ? formsColumns({
                        item,
                        onClick: onItemSelect,
                        selected,
                        openConnectionsSidebar: openConnectionsSidebar,
                        multi,
                      })
                    : item.__typename === 'CustomerList'
                    ? customersListColumns({
                        item,
                        onClick: onItemSelect,
                        selected,
                      })
                    : item.__typename === 'Warranty'
                    ? warrantyColumns({
                        selected,
                        warranty: item,
                      })
                    : libraryColumns({
                        item: item as LibraryItemsType,
                        selected,
                        onClick: onItemSelect,
                        openConnectionsSidebar: openConnectionsSidebar,
                        multi,
                      }),
              };
            })
      }
    />
  );
};

export default TableWrapper;
