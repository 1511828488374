import { useState } from 'react';
import styled from 'styled-components';
import { Button, Input, useForm, ModalActions, Modal } from '@layerise/design-core';
import { useMutation } from '@apollo/client';
import { SendTwoFactorCodeDocument, ValidateTwoFactorCodeByPhoneDocument } from 'types/typed-document-nodes';
import { useApiErrorHandler } from 'hooks/useApiErrorHandler';

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 12px;
`;

const ModalDescription = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
`;

type Props = {
  onRequestClose(): void;
  countryCode: string;
  nationalPhone: string;
  onValidated(): void;
  zIndex?: number;
};

export const PhoneVerificationModal = ({ countryCode, nationalPhone, onValidated, onRequestClose, zIndex }: Props) => {
  const [step, setStep] = useState(0);
  const { inputs, handleChange } = useForm({
    code: countryCode,
    phone: nationalPhone,
    activationCode: '',
  });

  const [sendCode, sendCodeMutation] = useMutation(SendTwoFactorCodeDocument, {
    variables: {
      phoneNumber: `${inputs.phone}`,
      countryCode: `${inputs.code}`,
    },
    onError: useApiErrorHandler(),
  });

  const [validateCode, validateCodeMutation] = useMutation(ValidateTwoFactorCodeByPhoneDocument, {
    variables: {
      activationCode: `${inputs.activationCode}`,
      phoneNumber: `${inputs.phone}`,
      countryCode: `${inputs.code}`,
    },
    onError: useApiErrorHandler(),
  });

  const validateInput = (step === 0 && inputs.code && inputs.phone) || (step === 1 && inputs.activationCode);

  return (
    <Modal
      modalContainerStyle={{ zIndex }}
      containerStyle={{ zIndex: typeof zIndex === 'undefined' ? undefined : zIndex + 1 }}
      header={step === 0 ? 'Add phone number' : 'Activation code'}
      onClick={onRequestClose}>
      <ModalDescription>
        {step === 0
          ? 'Add a phone number used to occasionally verify your identity. This number will never be used for marketing or advertising purposes'
          : `Enter the 4-digit code sent to the phone number +${inputs.code}${inputs.phone}`}
      </ModalDescription>
      {step === 0 ? (
        <FormGrid>
          <Input
            type="number"
            label="Country code"
            value={inputs.code}
            onChange={handleChange}
            required
            name="code"
            placeholder="00"
          />
          <Input
            type="tel"
            label="Phone number"
            value={inputs.phone}
            onChange={handleChange}
            required
            name="phone"
            placeholder="00 00 00 00"
          />{' '}
        </FormGrid>
      ) : (
        <Input
          type="tel"
          label="Verification code"
          value={inputs.activationCode}
          required
          placeholder="0 0 0 0"
          name="activationCode"
          onChange={handleChange}
        />
      )}

      <ModalActions negative={step === 1 && <Button onClick={() => setStep(0)}>Back</Button>}>
        <Button size="lg" type="button" onClick={onRequestClose}>
          Cancel
        </Button>
        <Button
          size="lg"
          variant="primary"
          onClick={async () => {
            if (step === 0) {
              await sendCode();
              setStep(1);
            }
            if (step === 1) {
              await validateCode();
              onRequestClose();
              onValidated();
            }
          }}
          disabled={!validateInput || sendCodeMutation.loading || validateCodeMutation.loading}
          loading={sendCodeMutation.loading || validateCodeMutation.loading}
          type="submit">
          {step === 0 ? 'Continue' : 'Verify'}
        </Button>
      </ModalActions>
    </Modal>
  );
};
