import styled from 'styled-components';

export const EmptyConnectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  svg {
    margin-bottom: 13px;
  }
`;

export const EmptyHeader = styled.span`
  font-weight: 599;
  color: #140038;
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 8px;
`;

export const EmptyInfo = styled.span`
  font-weight: 499;
  color: rgba(20, 0, 56, 0.54);
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 18px;
`;

export const EmptyLink = styled.a`
  color: rgba(20, 0, 56);
`;

export const Container = styled.div`
  box-shadow: var(--depth-Z100);
  border-radius: var(--radius-large);
  max-height: calc(100vh - 80px);
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
