declare global {
  interface Window {
    HubSpotConversations?: {
      widget: {
        load(): void;
        close(): void;
        status(): {
          loaded: boolean;
        };
        open(): void;
        remove(): void;
      };
    };
  }
}

type ChatWidgetAction = 'load' | 'hide' | 'show' | 'remove';

export const chatWidget = (action: ChatWidgetAction) => {
  const hubspotChat = typeof window !== 'undefined' && document.getElementById('hubspot-messages-iframe-container');

  if (hubspotChat && window.HubSpotConversations) {
    if (action === 'load') {
      window.HubSpotConversations.widget.load();
    }
    if (action === 'hide') {
      window.HubSpotConversations.widget.close();
    }

    if (action === 'show') {
      const status = window.HubSpotConversations.widget.status();

      if (status.loaded) {
        window.HubSpotConversations.widget.open();
      } else {
        window.HubSpotConversations.widget.load();
      }
    }
    if (action === 'remove') {
      window.HubSpotConversations.widget.remove();
    }
  }
};
