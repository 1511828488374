import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { IconConnection } from 'components/icons/IconConnection';

interface BadgeButtonProps extends PropsWithChildren {
  number?: number;
  name?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Container = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background-color: #7a52d6;
  border-radius: 3px;
  line-height: 22px;
  height: 22px;
  padding: 2px 6px 2px 4px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;

const ConnectionContainer = styled.span`
  width: auto;
  height: auto;
  white-space: pre;
  font-weight: 699;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
`;

export const CONNECTION_BUTTON_CLASS_NAME = 'connection-button';

export const ConnectionsButton = ({ number, name, onClick }: BadgeButtonProps) => {
  const handleOnClick: React.MouseEventHandler<HTMLDivElement> = e => {
    onClick?.(e);
  };

  return (
    <Container onClick={handleOnClick} className={CONNECTION_BUTTON_CLASS_NAME}>
      <IconContainer>
        <IconConnection />
      </IconContainer>
      <ConnectionContainer>{number !== undefined ? number : name}</ConnectionContainer>
    </Container>
  );
};
