import { IconContainer } from './IconContainer';

export const IconInsights = () => (
  <IconContainer bgColor="#062af3">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
      <path
        d="M 1.4 7 C 1.4 3.907 3.907 1.4 7 1.4 L 7 7 L 12.6 7 C 12.6 10.093 10.093 12.6 7 12.6 C 3.907 12.6 1.4 10.093 1.4 7 Z"
        fill="hsl(0, 0%, 100%)"
      />
      <path d="M 8.4 1.576 C 10.372 2.087 11.913 3.628 12.424 5.6 L 8.4 5.6 Z" fill="rgba(255, 255, 255, 0.54)" />
    </svg>
  </IconContainer>
);
