import { SVGProps } from 'react';

export const IconEmptyAssistantsList = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={88} height={80} {...props}>
    <path
      d="M43.762 68.703c17.557 0 31.789-14.283 31.789-31.902S61.319 4.899 43.762 4.899c-17.557 0-31.789 14.283-31.789 31.902s14.232 31.902 31.789 31.902Zm36.984-40.315a2.446 2.446 0 0 0 .155-3.447 2.423 2.423 0 0 0-3.434-.155 2.446 2.446 0 0 0-.155 3.446 2.426 2.426 0 0 0 3.434.155ZM25.385 7.649a1.606 1.606 0 0 0 .234-2.955 1.602 1.602 0 0 0-1.222-.097 1.606 1.606 0 0 0 .988 3.053Zm3.491-5.236a1.093 1.093 0 0 0 .159-2.01 1.09 1.09 0 0 0-.831-.066 1.09 1.09 0 0 0 .672 2.076Zm52.05 15.13a1.03 1.03 0 0 0 .065-1.451 1.02 1.02 0 0 0-1.446-.066 1.03 1.03 0 0 0-.065 1.452 1.022 1.022 0 0 0 1.446.065ZM55.108 2.534a.708.708 0 0 0-.443-1.228.708.708 0 0 0-.064 1.412.702.702 0 0 0 .507-.184Z"
      fill="#EAEEF9"
    />
    <g transform="translate(13.49 14.599)">
      <defs>
        <linearGradient id="c" gradientTransform="rotate(90 .5 .5)">
          <stop offset={0} stopColor="#FDFEFF" />
          <stop offset={1} stopColor="#ECF0F5" />
        </linearGradient>
        <filter id="a" x="-27.4%" y="-43.5%" width="154.6%" height="186.7%" filterUnits="objectBoundingBox">
          <feOffset dy={3} in="SourceAlpha" result="a1020z" />
          <feGaussianBlur stdDeviation={4} in="a1020z" result="a1021z" />
          <feColorMatrix
            colorInterpolationFilters="sRGB"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
            in="a1021z"
            result="a1022z"
          />
        </filter>
        <path
          d="M57.779 45.454H3.499c-1.974 0-3.499-1.521-3.499-3.4V3.401C0 1.521 1.525 0 3.499 0h54.28c1.974 0 3.499 1.521 3.499 3.401v38.653c-.09 1.879-1.615 3.4-3.499 3.4Z"
          id="b"
        />
      </defs>
      <g filter="url(#a)">
        <use stroke="#000" strokeOpacity={0} strokeWidth={0} xlinkHref="#b" clipPath="url(#a1017z)" />
      </g>
      <use xlinkHref="#b" fill="url(#c)" clipPath="url(#a1017z)" />
    </g>
    <path
      d="M44.321 14.599h27.185c1.975 0 3.499 1.521 3.499 3.4v38.654c0 1.878-1.524 3.4-3.499 3.4H44.322V14.599Z"
      fill="#FFF"
    />
    <path
      d="M74.83 17.833v3.66H13.769v-3.66c0-1.936 1.356-3.505 3.048-3.505h54.981c1.675 0 3.032 1.569 3.032 3.505Z"
      fill="#D5DDEA"
    />
    <path
      d="M17.024 18.779c.495 0 .896-.4.896-.893a.895.895 0 0 0-1.791 0c0 .493.401.893.895.893Zm2.687 0c.495 0 .896-.4.896-.893a.895.895 0 0 0-1.791 0c0 .493.401.893.895.893Zm2.67 0c.494 0 .895-.4.895-.893a.895.895 0 0 0-1.791 0c0 .493.401.893.896.893Z"
      fill="#989FB0"
    />
    <path
      d="M27.486 29.362h-8.613c-.539 0-.987-.447-.987-.805 0-.448.449-.805.987-.805h8.613c.538 0 .986.447.986.806 0 .447-.448.804-.986.804Zm-2.064 13.869h-6.459c-.539 0-.988-.448-.988-.805 0-.447.449-.806.988-.806h6.459c.539 0 .988.448.988.806-.09.358-.539.805-.988.805Z"
      fill="#AAB2C5"
    />
    <path
      d="M33.317 47.795H22.372c-.539 0-.987-.448-.987-.806 0-.448.449-.805.987-.805h10.945c.539 0 .987.448.987.805-.089.448-.538.806-.987.806Zm10.767-1.701v1.701h-6.549c-.539 0-.988-.448-.988-.806 0-.268.09-.448.359-.626.18-.09.359-.269.718-.269Z"
      fill="#D6DCE8"
    />
    <path
      d="M31.523 52.269h-12.65c-.539 0-.987-.448-.987-.806 0-.447.449-.805.987-.805h12.65c.539 0 .987.447.987.805 0 .447-.359.806-.987.806Z"
      fill="#AAB2C5"
    />
    <path
      d="M24.525 56.921h-5.383c-.539 0-.987-.448-.987-.806 0-.447.449-.805.987-.805h5.383c.539 0 .987.448.987.806 0 .447-.359.805-.987.805Zm4.576 0h-1.167c-.538 0-.986-.448-.986-.806 0-.447.448-.805.986-.805h1.167c.538 0 .987.448.987.806 0 .447-.449.805-.987.805Zm-7.627-18.163h-2.601c-.539 0-.987-.448-.987-.806 0-.448.449-.805.987-.805h2.601c.539 0 .988.448.988.805 0 .448-.449.806-.988.806Zm10.049-4.743h-12.65c-.539 0-.987-.447-.987-.805 0-.448.449-.805.987-.805h12.65c.539 0 .987.447.987.805 0 .447-.359.805-.987.805Z"
      fill="#D6DCE8"
    />
    <path
      d="M44.084 32.315v1.701H35.74c-.538 0-.987-.448-.987-.806 0-.269.09-.448.36-.626.089-.179.358-.269.717-.269Z"
      fill="#AAB2C5"
    />
    <defs>
      <linearGradient id="d">
        <stop offset={0} stopColor="#B0BACC" />
        <stop offset={1} stopColor="#969EAE" />
      </linearGradient>
    </defs>
    <path
      d="M71.089 39.741c-2.602 5.458-8.254 9.217-14.893 9.217a16.573 16.573 0 0 1-14.893-9.217c2.601-5.457 8.254-9.215 14.893-9.215 6.639 0 12.291 3.758 14.893 9.215Z"
      fill="url(#d)"
    />
    <path
      d="M54.581 39.831c1.525 0 2.781-1.252 2.781-2.684 0-1.006-.552-1.856-1.359-2.327h.192c2.782-.089 5.025 2.148 5.025 4.921 0 2.774-2.333 4.922-5.025 4.922-2.691 0-5.023-2.148-5.023-4.832v-.09c0-.536.089-1.073.269-1.52.089-.269.179-.537.359-.806l.009-.016c.21 1.395 1.361 2.432 2.772 2.432Z"
      fill="#FFF"
    />
  </svg>
);
