import React from 'react';
import styled from 'styled-components';
import { Avatar, Tooltip } from '@layerise/design-core';
import { getFullName, TransactionLog } from 'lib/utils';
import { format } from 'date-fns';

interface MetaInfoProps {
  transactionLogs?: TransactionLog[];
  member?: TransactionLog['member'];
  updatedAt?: string;
  align?: 'left' | 'center' | 'right';
  selected?: boolean;
  actionLabel?: string;
}

const Wrapper = styled.div<{ align: MetaInfoProps['align'] }>`
  display: flex;
  width: 90px;
  height: 20px;
  justify-content: ${props => props.align};
  align-items: center;
`;

const DateWrapper = styled.span`
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 16px;
  margin-left: 8px;
`;

export const MetaInfo = ({
  transactionLogs,
  member,
  updatedAt,
  align = 'left',
  actionLabel = 'Updated',
  selected = false,
}: MetaInfoProps) => {
  if (!transactionLogs?.[0] && !member) {
    return <></>;
  }
  const user = transactionLogs?.[0]?.member || member;
  const updatedBy = user ? getFullName(user) : '';
  const updateDateObject = new Date(transactionLogs?.[0]?.createdAt || updatedAt);
  const date = format(
    updateDateObject,
    `MMM ${updateDateObject.getFullYear() == new Date().getFullYear() ? 'dd' : 'yyyy'}`
  );
  const updateTime = format(updateDateObject, 'HH:mm');
  const updateDate = format(updateDateObject, 'MMMM dd, yyyy');
  const tooltipText = `${actionLabel} ${updateDate} ${updatedBy ? `by ${updatedBy}` : ''} at ${updateTime}`;
  return (
    <Tooltip width={200} placement="top" tooltip={tooltipText}>
      <Wrapper align={align}>
        <Avatar
          src={user?.avatarImg?.dynamic || undefined}
          height={20}
          bgColor={selected ? 'white' : undefined}
          fill={selected ? 'var(--color-B500)' : undefined}
        />
        <DateWrapper>{date}</DateWrapper>
      </Wrapper>
    </Tooltip>
  );
};
