import { SVGProps } from 'react';

export function IconShield(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={12} {...props}>
      <path
        fill="#cbf4c9"
        d="M8.495 20c.208 0 .486-.089.744-.217C15.005 16.93 17 15.283 17 11.76V4.618c0-1.421-.466-2.023-1.737-2.546C14.32 1.667 10.827.503 9.934.227A5.21 5.21 0 0 0 8.495 0c-.437 0-.992.099-1.439.227-.893.266-4.376 1.45-5.33 1.845C.457 2.595 0 3.197 0 4.618v7.143c0 3.523 2.034 5.082 7.75 8.022.259.138.537.217.745.217Z"
      />
      <path
        fill="#0e6245"
        d="M7.403 14.406c-.446 0-.813-.208-1.121-.592L3.96 11.07c-.238-.277-.328-.523-.328-.82 0-.63.506-1.124 1.132-1.124.367 0 .645.148.903.464l1.717 2.072 3.84-6.059c.268-.424.596-.631.993-.631.615 0 1.17.444 1.17 1.065 0 .247-.099.523-.277.78l-4.615 6.976c-.258.385-.655.612-1.092.612Z"
      />
    </svg>
  );
}
