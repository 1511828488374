import * as React from 'react';
import { SVGProps } from 'react';

const IconUpsellWarranty = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={16} height={16} {...props}>
    <defs>
      <filter id="a" x="-169.9%" y="-408.1%" width="436.8%" height="910.2%" filterUnits="objectBoundingBox">
        <feOffset dy={1.33} in="SourceAlpha" result="a1010z" />
        <feGaussianBlur stdDeviation={1.335} in="a1010z" result="a1011z" />
        <feColorMatrix
          colorInterpolationFilters="sRGB"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          in="a1011z"
          result="a1012z"
        />
      </filter>
      <path d="M7.2 1.6a.8.8 0 1 0 0 1.6h1.6a.8.8 0 1 0 0-1.6Z" id="b" />
    </defs>
    <g filter="url(#a)">
      <use stroke="#000" strokeOpacity={0} strokeWidth={0} xlinkHref="#b" clipPath="url(#a1007z)" />
    </g>
    <use xlinkHref="#b" fill="rgba(255, 255, 255, 0.5)" clipPath="url(#a1007z)" />
    <path
      d="M3.2 4a1.6 1.6 0 0 1 1.6-1.6 2.4 2.4 0 0 0 2.4 2.4h1.6a2.4 2.4 0 0 0 2.4-2.4A1.6 1.6 0 0 1 12.8 4v8.8a1.6 1.6 0 0 1-1.6 1.6H4.8a1.6 1.6 0 0 1-1.6-1.6Zm2.4 3.2a.8.8 0 1 0 0 1.6h.008a.8.8 0 1 0 0-1.6Zm2.4 0a.8.8 0 1 0 0 1.6h2.4a.8.8 0 1 0 0-1.6Zm-2.4 3.2a.8.8 0 1 0 0 1.6h.008a.8.8 0 1 0 0-1.6Zm2.4 0A.8.8 0 1 0 8 12h2.4a.8.8 0 1 0 0-1.6Z"
      fill="#fff"
    />
  </svg>
);

export default IconUpsellWarranty;
