import React, { useState, useContext } from 'react';
import { ModalActions, Modal, Button } from '@layerise/design-core';
import ToastContext from 'contexts/ToastContext';
import { DeleteMemberDocument, Member, OrganisationMembersDocument } from 'types/typed-document-nodes';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { useMutation } from '@apollo/client';
import { isPowerUser } from 'lib/utils';

interface DeleteMemberModalProps {
  onRequestClose(): void;
  member: Pick<Member, 'id' | 'email' | 'role'>;
  scope: 'workspace' | 'organisation';
  zIndex?: number;
  onCompleted?(): void;
}

export const DeleteMemberModal: React.FC<DeleteMemberModalProps> = ({
  member,
  scope,
  zIndex,
  onCompleted,
  onRequestClose,
}) => {
  const { setToast } = useContext(ToastContext);
  const { workspace, organisation, refetch } = useContext(OrganisationContext);
  const [deleteMember] = useMutation(DeleteMemberDocument, {
    variables: {
      id: member.id,
      workspaceId: workspace.id,
    },
    onError(error) {
      setToast({ type: 'warning', message: error.message });
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: OrganisationMembersDocument,
        variables: {
          id: organisation.id,
        },
      },
    ],
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const modalHeader = `Delete ${isPowerUser(member.role) ? 'admin' : 'member'}`;

  return (
    <Modal
      header={modalHeader}
      modalContainerStyle={{ zIndex }}
      containerStyle={{ zIndex: typeof zIndex === 'undefined' ? undefined : zIndex + 1 }}
      onClick={onRequestClose}>
      {member.email} will be deleted immediately from the {scope}. You can&rsquo;t undo this action.
      <ModalActions>
        <Button size="lg" onClick={onRequestClose}>
          Cancel
        </Button>
        <Button
          size="lg"
          variant="warning"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={async () => {
            setSubmitting(true);
            await deleteMember();
            await Promise.all([refetch(), onCompleted?.()]);
            setSubmitting(false);
            onRequestClose();
          }}>
          {isSubmitting ? 'Deleting...' : modalHeader}
        </Button>
      </ModalActions>
    </Modal>
  );
};
