import { IconContainer } from './IconContainer';

export const IconLibAssistants = () => (
  <IconContainer key="assistantIcon" bgColor="#fe871a">
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={14} height={14}>
      <defs>
        <filter id="1RTACZM9nj" x="-68.9%" y="-71.3%" width="236.4%" height="241.5%" filterUnits="objectBoundingBox">
          <feOffset dy={1.17} in="SourceAlpha" result="a1004z" />
          <feGaussianBlur stdDeviation={1.165} in="a1004z" result="a1005z" />
          <feColorMatrix
            colorInterpolationFilters="sRGB"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            in="a1005z"
            result="a1006z"
          />
        </filter>
        <path
          d="M6.3 1.4a1.4 1.4 0 0 0-1.4 1.4v5.6a1.4 1.4 0 0 0 1.4 1.4h4.2a1.4 1.4 0 0 0 1.4-1.4V4.49c0-.371-.148-.728-.41-.99L9.8 1.81a1.402 1.402 0 0 0-.99-.41Z"
          id="rXgjklkUSP"
        />
      </defs>
      <g filter="url(#1RTACZM9nj)">
        <use stroke="#000" strokeOpacity={0} strokeWidth={0} xlinkHref="#rXgjklkUSP" clipPath="url(#a1001z)" />
      </g>
      <use xlinkHref="#rXgjklkUSP" fill="hsl(0, 0%, 100%)" clipPath="url(#a1001z)" />
      <defs>
        <filter id="2mzmiu7nlG" x="-68.9%" y="-71.3%" width="236.4%" height="241.5%" filterUnits="objectBoundingBox">
          <feOffset dy={1.17} in="SourceAlpha" result="a1011z" />
          <feGaussianBlur stdDeviation={1.165} in="a1011z" result="a1012z" />
          <feColorMatrix
            colorInterpolationFilters="sRGB"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            in="a1012z"
            result="a1013z"
          />
        </filter>
        <path
          d="M2.1 5.6a1.4 1.4 0 0 1 1.4-1.4v7h5.6a1.4 1.4 0 0 1-1.4 1.4H3.5a1.4 1.4 0 0 1-1.4-1.4Z"
          id="UVQWi4EImt"
        />
      </defs>
      <g filter="url(#2mzmiu7nlG)">
        <use stroke="#000" strokeOpacity={0} strokeWidth={0} xlinkHref="#UVQWi4EImt" clipPath="url(#a1008z)" />
      </g>
      <use xlinkHref="#UVQWi4EImt" fill="rgba(255, 255, 255, 0.5)" clipPath="url(#a1008z)" />
    </svg>
  </IconContainer>
);
