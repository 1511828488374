export const IconProducts = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14}>
      <path
        d="M10.648 4.393a.699.699 0 0 0 0-1.251L7.313 1.474a.703.703 0 0 0-.626 0L3.352 3.142a.698.698 0 0 0 0 1.251l3.335 1.668a.703.703 0 0 0 .626 0ZM3.113 5.839a.703.703 0 0 0-1.013.626V10.5a.7.7 0 0 0 .387.626l2.8 1.4A.7.7 0 0 0 6.3 11.9V7.865a.7.7 0 0 0-.387-.626Z"
        fill="hsl(0, 0%, 100%)"
      />
      <path
        d="M7.7 11.9a.699.699 0 0 0 1.013.626l2.8-1.4a.7.7 0 0 0 .387-.626V6.465a.701.701 0 0 0-1.013-.626l-2.8 1.4a.7.7 0 0 0-.387.626Z"
        fill="rgba(255, 255, 255, 0.54)"
      />
    </svg>
  );
};
