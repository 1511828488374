import { SVGProps } from 'react';

const IconUpsellUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 14" {...props}>
    <path
      d="M 7.200000190737276 2.7000000053084667 C 7.200000190737276 4.191168829851952 5.991169015277832 5.400000005309027 4.500000190735208 5.400000005309027 C 3.0088313661908614 5.400000005309027 1.8000001907348633 4.191168829851952 1.8000001907348633 2.7000000053084667 C 1.8000001907348633 1.2088311807658436 3.0088313661908614 5.308768535883246e-9 4.500000190735208 5.308768535883246e-9 C 5.991169015277832 5.308768535883246e-9 7.200000190737276 1.2088311807658436 7.200000190737276 2.7000000053084667 Z M 14.400000000002413 2.7000000053084667 C 14.400000000002413 4.191168829851952 13.191168824544691 5.400000005309027 11.700000000002069 5.400000005309027 C 10.208831175456 5.400000005309027 9 4.191168829851952 9 2.7000000053084667 C 9 1.2088311807658436 10.208831175456 5.308768535883246e-9 11.700000000002069 5.308768535883246e-9 C 13.191168824544691 5.308768535883246e-9 14.400000000002413 1.2088311807658436 14.400000000002413 2.7000000053084667 Z M 10.737000762939715 12.600000887666027 C 10.778400762939878 12.30570088766591 10.80000076293704 12.006000887665643 10.80000076293704 11.70000088766577 C 10.80208391159383 10.28597335664049 10.326360347932342 8.912718002878457 9.450000762939453 7.803000887665621 C 10.842287923549998 6.999163520005641 12.55765389199089 6.999152625409648 13.94995126312087 7.80297230767484 C 15.342248634250849 8.606791989940032 16.199959922216195 10.092326153901908 16.200000762939453 11.70000088766577 L 16.200000762939453 12.600000887666027 Z M 4.500000000868901 7.199999809265137 C 6.985281375110054 7.199999809265137 9.000000000868901 9.21471843502657 9.000000000868901 11.699999809265137 L 9.000000000868901 12.599999809265395 L 8.689022834573734e-10 12.599999809265395 L 8.689022834573734e-10 11.699999809265137 C 8.689022834573734e-10 9.21471843502657 2.014718626627749 7.199999809265137 4.500000000868901 7.199999809265137 Z"
      transform="translate(0.9 0.7)"
      fill="rgb(255, 255, 255)"
    />
  </svg>
);

export default IconUpsellUsers;
