import React, { ComponentProps } from 'react';
import { Filter as ListFilter } from 'components/filter';
import { Input } from '@layerise/design-core';
import styled from 'styled-components';
import { useMembersFilterOptions } from 'hooks/data/useWorkspaceMembers';
import { useProductFilterOptions } from 'hooks/data/useWorkspaceProducts';
import { ContentItemStatus, LibraryItemType } from 'types/typed-document-nodes';
import { useTagsFilterOptions } from 'hooks/data/useWorkspaceTags';
import { useProductCategoryFilterOptions } from 'hooks/data/useProductCategories';
import { useLocalesFilterOptions } from 'hooks/data/useLocales';
import { useAssistantFilterOptions } from 'hooks/data/useWorkspaceAssistants';
import { defaultLanguageFilterOptionSelector } from 'lib/filters';

type Filters = Partial<{
  createdAt: string[];
  status: ContentItemStatus;
  query: string;
  productId: string;
  createdById: string;
  allowedSubtype: LibraryItemType.Image | LibraryItemType.Video;
  tagId: string;
  categoryId: string;
  localeIds: string[];
  connections: string[];
  assistantIds: string[];
}>;

interface FilterProps {
  isMedia?: boolean;
  isProduct?: boolean;
  isForm?: boolean;
  showSearchInput?: boolean;
  showFilters?: boolean;
  activeFilters: Filters;
  showLibraryFilters?: boolean;
  handleFiltersChange: (props: Filters) => void;
  hiddenFilters?: Array<keyof Filters>;
}

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

const SearchWrapper = styled.div`
  input {
    width: 228px;
  }
`;

const Filter = ({
  showFilters = true,
  isMedia,
  isProduct,
  isForm,
  showSearchInput,
  activeFilters,
  handleFiltersChange,
  hiddenFilters,
  showLibraryFilters,
}: FilterProps) => {
  const membersFilterOptions = useMembersFilterOptions();
  const productsFilterOptions = useProductFilterOptions();
  const tagsFilterOptions = useTagsFilterOptions();
  const categoriesOptions = useProductCategoryFilterOptions();
  const localeFilterOptions = useLocalesFilterOptions();
  const assistantFilterOptions = useAssistantFilterOptions();

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFiltersChange({
      ...activeFilters,
      [e.target.name]: e.target.value,
    });
  };

  const filters: Array<Omit<ComponentProps<typeof ListFilter>['filters'][number], 'id'> & { id: keyof Filters }> = [];
  if (showFilters) {
    if (isMedia) {
      filters.push(
        {
          id: 'createdById',
          title: 'Creator',
          options: membersFilterOptions,
          placeholder: 'Select creator',
          value: activeFilters.createdById,
        },
        {
          id: 'createdAt',
          title: 'Create date',
          isDatePicker: true,
          value: activeFilters.createdAt,
        },
        {
          id: 'tagId',
          title: 'Tags',
          options: tagsFilterOptions,
          value: activeFilters.tagId,
          isMultiSelect: true,
        },
        {
          id: 'allowedSubtype',
          title: 'Type',
          options: [
            { id: LibraryItemType.Video, title: 'Video' },
            { id: LibraryItemType.Image, title: 'Image' },
          ],
          value: activeFilters.allowedSubtype,
        }
      );
    } else {
      filters.push({
        id: 'createdAt',
        title: 'Created At',
        isDatePicker: true,
        value: activeFilters.createdAt,
      });
      if (isForm) {
        filters.push(
          {
            id: 'productId',
            title: 'Product',
            options: productsFilterOptions,
            value: activeFilters.productId,
          },
          {
            id: 'createdById',
            title: 'Creator',
            options: membersFilterOptions,
            placeholder: 'Select creator',
            value: activeFilters.createdById,
          }
        );
      }
      filters.push({
        id: 'status',
        title: 'Status',
        options: isProduct
          ? [
              { id: ContentItemStatus.Active, title: 'Active' },
              { id: ContentItemStatus.Draft, title: 'Draft' },
            ]
          : [
              { id: ContentItemStatus.Active, title: 'Active' },
              { id: ContentItemStatus.Draft, title: 'Draft' },
              { id: ContentItemStatus.Archived, title: 'Archived' },
            ],
        value: activeFilters.status,
      });
      if (isProduct) {
        filters.push({
          id: 'tagId',
          title: 'Tags',
          options: tagsFilterOptions,
          value: activeFilters.tagId,
          isMultiSelect: true,
        });
        filters.push({
          id: 'categoryId',
          title: 'Category',
          options: categoriesOptions.map(i => ({ id: i.id, title: i.title })),
          value: activeFilters.categoryId,
        });
      }
      if (showLibraryFilters) {
        filters.push({
          id: 'tagId',
          title: 'Tags',
          options: tagsFilterOptions,
          value: activeFilters.tagId,
          emptyOptionsPlaceHolder: 'No tags',
          isMultiSelect: true,
          placeholder: 'Select tags',
        });
        filters.push({
          id: 'localeIds',
          title: 'Languages',
          options: localeFilterOptions,
          placeholder: 'Select languages',
          isMultiSelect: true,
          value: activeFilters.localeIds,
          preselectedOption: defaultLanguageFilterOptionSelector,
        });
        filters.push({
          id: 'connections',
          title: 'Connections',
          isRange: true,
          value: activeFilters.connections,
        });
        filters.push({
          id: 'createdById',
          title: 'Creator',
          options: membersFilterOptions,
          placeholder: 'Select creator',
          value: activeFilters.createdById,
        });
        filters.push({
          id: 'assistantIds',
          title: 'Connected Assistants',
          options: assistantFilterOptions,
          isMultiSelect: true,
          placeholder: 'Select assistants',
          value: activeFilters.assistantIds,
        });
      }
    }
  }

  return (
    <Container>
      {showSearchInput && (
        <SearchWrapper>
          <Input name="query" value={activeFilters.query} onChange={handleQueryChange} placeholder="Search..." />
        </SearchWrapper>
      )}
      {showFilters && (
        <ListFilter onSubmit={handleFiltersChange} filters={filters.filter(f => !hiddenFilters?.includes(f.id))} />
      )}
    </Container>
  );
};

export default Filter;
