import { useEffect, useState, ReactNode, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Portal } from '@layerise/design-core';
import { IconClose } from '@layerise/design-icon';
import { AnimatePresence, motion } from 'framer-motion';
import { useAnimationSettings } from './useAnimationSettings';

const SideBarContainer = styled(motion.section)<{ $width?: number | `${number}`; $withControls?: boolean }>`
  position: fixed;
  right: 0;
  z-index: 1051;
  width: ${props => props.$width ?? 304}px;
  height: 100%;
  padding: ${props => (props.$withControls ? '24px 16px 0' : '24px 16px')};
  box-shadow: 0px 0px 0px 1px rgba(1, 0, 50, 0.1), 0px 2px 14px 4px rgba(1, 0, 50, 0.2);
  background-color: #f7f7fa;
  ${props =>
    props.$withControls &&
    css`
      display: grid;
      grid-template-rows: auto 1fr auto;
    `}
`;

const Top = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 599;
  color: #140038;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: left;
`;

const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 1px 1px 0px rgba(0, 0, 0, 0.12),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 2px 5px 0px rgba(60, 66, 87, 0.08);
  background-color: #ffffff;
  overflow: visible;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
`;

const Content = styled.div<{ $scrollable?: boolean }>`
  margin-top: 16px;
  ${props =>
    props.$scrollable &&
    css`
      overflow-y: auto;
      padding: 0 1px;
      &::-webkit-scrollbar {
        display: none;
      }
    `}
`;

const Controls = styled.footer`
  border-top: 1px solid #e8e8ec;
  margin-left: -16px;
  margin-right: -16px;
  padding: 16px;
  display: flex;
  gap: 24px;
  justify-content: end;
`;

interface ConnectionsSidebarProps {
  title?: string;
  open?: boolean;
  onExitComplete?(): void;
  onAnimationComplete?(): void;
  onClose?: () => void;
  children?: ReactNode;
  width?: number | `${number}`;
  controls?: ReactNode;
}

export const SideDrawer = forwardRef<HTMLElement, ConnectionsSidebarProps>(
  ({ title, open, onClose, onAnimationComplete, onExitComplete, children, controls, width = 304 }, ref) => {
    const [expanded, setExpanded] = useState(false);
    const animationSettings = useAnimationSettings({
      open,
      expanded,
      width,
    });

    useEffect(() => {
      setExpanded(true);
    }, []);

    return (
      <Portal selector="#modal">
        <AnimatePresence onExitComplete={onExitComplete}>
          {open && (
            <SideBarContainer
              ref={ref}
              $withControls={!!controls}
              $width={width}
              {...animationSettings}
              onAnimationComplete={onAnimationComplete}>
              <Top>
                <Title>{title}</Title>
                <IconContainer onClick={onClose}>
                  <IconClose width="10px" height="10px" />
                </IconContainer>
              </Top>
              {children && <Content $scrollable={!!controls}>{children}</Content>}
              {controls ? <Controls>{controls}</Controls> : null}
            </SideBarContainer>
          )}
        </AnimatePresence>
      </Portal>
    );
  }
);

SideDrawer.displayName = 'SideDrawer';
