import { OperationVariables, TypedDocumentNode, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Button } from '@layerise/design-core';
import { ConnectedEntitesDocument, ConnectedEntitesQuery } from 'types/typed-document-nodes';
import { ConnectionHeader, ContentItemType, ResponseType, getEntityName, getResponseType } from './ConnectionHeader';
import { ConnectionListItem, ConnectionItemType } from './ConnectionListItem';
import IconEmptyConnections from 'components/icons/IconEmptyConnections';
import { EmptyConnectionsWrapper, EmptyHeader, EmptyInfo, EmptyLink, Container } from './styles';

interface ConnectionListProps {
  connectionsNumber?: number;
  reference: string;
  workspaceId: string;
  contentItemType: ContentItemType;
  animationFinished?: boolean;
  onClose?: () => void;
  skipRefetchOnMount?: boolean;
  query?: {
    document: TypedDocumentNode<ConnectedEntitesQuery>;
    variables: OperationVariables;
  };
}

export const ConnectionList = ({
  connectionsNumber,
  reference,
  workspaceId,
  contentItemType,
  animationFinished,
  onClose,
  skipRefetchOnMount = true,
  query,
}: ConnectionListProps) => {
  const { data, loading } = useQuery(query?.document || ConnectedEntitesDocument, {
    variables: {
      reference,
      workspaceId,
      ...query?.variables,
    },
    fetchPolicy: skipRefetchOnMount ? 'cache-first' : 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const responseType = getResponseType(contentItemType);

  const getTitle = (context: ResponseType, no?: number) => {
    const contextMap = {
      products: {
        singular: 'Product',
        plural: 'Products',
      },
      assistants: {
        singular: 'Assistant',
        plural: 'Assistants',
      },
    };

    if (no) {
      if (no === 1) {
        return `1 ${contextMap[context].singular}`;
      }
      return `${no} ${contextMap[context].plural}`;
    }
    return `No ${contextMap[context].plural}`;
  };

  const formattedTitles = useMemo(() => {
    return {
      productHeaderTitle: getTitle(ResponseType.products, data?.connectedEntities.products?.length),
      assistantHeaderTitle: getTitle(ResponseType.assistants, data?.connectedEntities.assistants?.length),
      loadingTitle: 'Loading...',
    };
  }, [data?.connectedEntities.assistants?.length, data?.connectedEntities.products?.length]);

  const showEmptyState =
    (responseType === ResponseType.products &&
      data?.connectedEntities.products &&
      data?.connectedEntities.products.length === 0) ||
    (responseType === ResponseType.assistants &&
      data?.connectedEntities.assistants &&
      data?.connectedEntities.assistants.length === 0);

  if (loading) {
    return (
      <Container>
        <ConnectionHeader
          animationFinished={animationFinished}
          title={formattedTitles.loadingTitle}
          contentItemType={contentItemType}
          hasEntities={typeof connectionsNumber === 'undefined' ? false : connectionsNumber > 0}
        />
        {[...Array(connectionsNumber)].map((_, idx) => {
          return <ConnectionListItem key={idx} loading />;
        })}
      </Container>
    );
  }

  return (
    <>
      {responseType === ResponseType.assistants &&
        data?.connectedEntities.assistants &&
        data?.connectedEntities.assistants.length > 0 && (
          <Container>
            <ConnectionHeader
              animationFinished={animationFinished}
              title={formattedTitles.assistantHeaderTitle}
              contentItemType={contentItemType}
            />
            {data?.connectedEntities.assistants
              .filter(i => !i.metaInfo?.archived)
              .sort((a, b) => {
                const aActive = a.metaInfo?.published;
                const bActive = b.metaInfo?.published;
                if (aActive && !bActive) {
                  return -1;
                } else if (!aActive && bActive) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .map(assistant => {
                return (
                  <ConnectionListItem
                    key={assistant.id}
                    type={ConnectionItemType.Assistant}
                    name={assistant.name}
                    tag={assistant?.metaInfo?.reference}
                    active={assistant.metaInfo?.published && !assistant.metaInfo?.archived}
                    id={assistant.id}
                    languageId={assistant.languages?.[0]?.id}
                  />
                );
              })}
          </Container>
        )}

      {responseType === ResponseType.products &&
        data?.connectedEntities.products &&
        data?.connectedEntities.products.length > 0 && (
          <Container>
            <ConnectionHeader
              animationFinished={animationFinished}
              title={formattedTitles.productHeaderTitle}
              contentItemType={contentItemType}
            />
            {[...data?.connectedEntities.products]
              .filter(i => !i.archived)
              .sort((a, b) => {
                const aActive = a.published;
                const bActive = b.published;
                if (aActive && !bActive) {
                  return -1;
                } else if (!aActive && bActive) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .map(product => {
                return (
                  <ConnectionListItem
                    key={product.id}
                    type={ConnectionItemType.Product}
                    name={product.title}
                    active={product.published && !product.archived}
                    id={product.id}
                  />
                );
              })}
          </Container>
        )}

      {showEmptyState && (
        <EmptyConnectionsWrapper>
          <IconEmptyConnections />
          <EmptyHeader>No connections</EmptyHeader>
          <EmptyInfo>
            This {getEntityName(contentItemType)} does not have any connections.
            <br /> Click{' '}
            <EmptyLink
              href={`${process.env.URL_WWW}/help/assistant/managing-library/understand-connections`}
              target="_blank">
              here
            </EmptyLink>{' '}
            to learn how to connect.
          </EmptyInfo>
          <Button variant="primary" onClick={onClose}>
            Close window
          </Button>
        </EmptyConnectionsWrapper>
      )}
    </>
  );
};
