import { Tag, Tooltip } from '@layerise/design-core';
import styled from 'styled-components';

interface TagOverfowProps {
  count: number;
  tooltip: string;
}

const TagContainer = styled.div`
  line-height: 0;

  span {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 23px;
  }
`;

const TooltipText = styled.div<{ width: number }>`
  width: ${props => props.width}px;
`;

export const TagOverfow = ({ count, tooltip }: TagOverfowProps) => {
  return (
    <TagContainer>
      <Tooltip tooltip={<TooltipText width={Math.min(tooltip.length * 6.5, 200)}>{tooltip}</TooltipText>}>
        <Tag bgColor="#140038" variant="custom">
          + {count}
        </Tag>
      </Tooltip>
    </TagContainer>
  );
};
