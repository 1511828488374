import React, { createContext, PropsWithChildren } from 'react';
import { FeatureFlagsDocument, FeatureFlagsQuery } from '../types/typed-document-nodes';
import { useQuery } from '@apollo/client';

interface IFeatureFlagContext {
  flags: FeatureFlagsQuery['featureFlags'] | undefined;
}

export const FeatureFlagContext = createContext<IFeatureFlagContext>(undefined!);

export const FeatureFlagProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data } = useQuery(FeatureFlagsDocument);
  const flags = data?.featureFlags;

  return (
    <FeatureFlagContext.Provider
      value={{
        flags,
      }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
