import { useContext, useMemo } from 'react';
import { CurrentMemberQuery } from 'types/typed-document-nodes';
import { OrganisationContext } from 'contexts/OrganisationContext';

type Member = NonNullable<NonNullable<CurrentMemberQuery['workspace']>['workspaceProducts']>[number];

export const useWorkspaceProducts = (): Member[] => {
  const { workspace } = useContext(OrganisationContext);
  return workspace.workspaceProducts || [];
};

export const useProductFilterOptions = (identifier: 'id' | 'sku' = 'id') => {
  const products = useWorkspaceProducts();

  return useMemo(() => {
    return products
      .filter(product => !!product[identifier])
      .map(product => ({
        id: product[identifier] || '',
        title: product.title,
      }));
  }, [products, identifier]);
};
