import { Tooltip } from '@layerise/design-core';
import styled, { css } from 'styled-components';
import { IconCheck, IconDraft, IconArchived } from '@layerise/design-icon';
import { ContentItemStatus } from 'types/typed-document-nodes';
import { PropsWithChildren } from 'react';
import { ReactNode } from 'react-markdown';

type Props = (
  | {
      archived?: boolean;
      published?: boolean;
    }
  | { status: ContentItemStatus | undefined }
) & { size?: 'sm' | 'md'; noTooltip?: boolean };

const Wrapper = styled.div<Pick<Props, 'size'>>`
  display: flex;
  height: ${props => (props.size === 'sm' ? 10 : 14)}px;
  width: ${props => (props.size === 'sm' ? 10 : 14)}px;
`;

const IconActiveWrapper = styled.div<Pick<Props, 'size'>>`
  width: ${props => (props.size === 'sm' ? 10 : 14)}px;
  height: ${props => (props.size === 'sm' ? 10 : 14)}px;
  background-color: #cbf4c9;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    ${props =>
      props.size === 'sm' &&
      css`
        width: 6px;
        height: 6px;
      `}
    ${props =>
      props.size !== 'sm' &&
      css`
        width: 8px;
        height: 8px;
      `}
    path {
      fill: #0e6245;
    }
  }
`;

const IconWrapper = styled.div<Pick<Props, 'size'> & { isDraft?: boolean }>`
  width: ${props => (props.size === 'sm' ? 12 : 16)}px;
  height: ${props => (props.size === 'sm' ? 12 : 16)}px;
  display: flex;
  svg {
    width: ${props => (props.size === 'sm' ? 12 : 16)}px;
    height: ${props => (props.size === 'sm' ? 12 : 16)}px;
    ${props =>
      props.size === 'sm' &&
      css`
        display: inherit;
      `}
    path {
      fill: ${props => (props.isDraft ? '#8a809c' : '#140038')};
    }
  }
`;

const TooltipText = styled.span`
  text-transform: capitalize;
`;

type WrapperProps = PropsWithChildren & { variant?: ReactNode };

const FragmentWrapper = ({ children }: WrapperProps) => <>{children}</>;
const TooltipWrapper = ({ children, variant }: WrapperProps) => (
  <Tooltip placement="top" tooltip={<TooltipText>{variant}</TooltipText>}>
    {children}
  </Tooltip>
);

export const StatusTag = (props: Props) => {
  let variant: ContentItemStatus | undefined;

  if ('status' in props) {
    variant = props.status;
  } else {
    if (props.archived) {
      variant = ContentItemStatus.Archived;
    } else if (props.published) {
      variant = ContentItemStatus.Active;
    }
  }

  variant ||= ContentItemStatus.Draft;

  const ContentWrapper = props.noTooltip ? FragmentWrapper : TooltipWrapper;

  return (
    <Wrapper size={props.size}>
      <ContentWrapper variant={variant}>
        {variant === ContentItemStatus.Archived && (
          <IconWrapper size={props.size}>
            <IconArchived />
          </IconWrapper>
        )}
        {variant === ContentItemStatus.Draft && (
          <IconWrapper size={props.size} isDraft>
            <IconDraft />
          </IconWrapper>
        )}
        {variant === ContentItemStatus.Active && (
          <IconActiveWrapper size={props.size}>
            <IconCheck />
          </IconActiveWrapper>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
