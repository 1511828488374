import { Button, ModalActions, Modal, Img } from '@layerise/design-core';
import { IconInfo } from '@layerise/design-icon';
import { LearnMore, Content, Body, Title, UpdateTag, ModalHero } from './SharedStyles';
import { useMutation } from '@apollo/client';
import { SetMemberOnboardingDocument } from 'types/typed-document-nodes';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { useContext } from 'react';
import { NORMAL_MODAL_Z_INDEX, FeatureModalProps } from './constants';

export const ProductUpdate = (props: FeatureModalProps) => {
  const [setOnboarding] = useMutation(SetMemberOnboardingDocument);

  const { refetch } = useContext(OrganisationContext);

  const handleClick = async () => {
    props.onRequestClose();
    await setOnboarding({
      variables: {
        discoverProduct: true,
      },
    });
    await refetch();
  };

  return (
    <Modal
      onClick={props.onRequestClose}
      modalContainerStyle={{ zIndex: NORMAL_MODAL_Z_INDEX + 1 }}
      containerStyle={{ zIndex: NORMAL_MODAL_Z_INDEX + 2 }}
      padding="0px 0px 0px 0px"
      height="590px"
      width="520px">
      <ModalHero>
        <UpdateTag>UPDATE</UpdateTag>
        <Img alt="product" src="WWW/productUpdates/productListing" height={776} width={1040} quality={80} />
      </ModalHero>
      <Content>
        <Title>All of your products in one place </Title>
        <Body>
          Here you can add, organize and update your products. You are also able to make registration flow changes, and
          easily connect a product to an Assistant.
        </Body>
        <ModalActions
          negative={
            <LearnMore
              href={`${process.env.URL_WWW}/help/assistant/creating-a-product`}
              target="_blank"
              rel="noreferrer">
              Learn more <IconInfo />
            </LearnMore>
          }>
          <Button size="lg" variant="primary" onClick={handleClick}>
            Close
          </Button>
        </ModalActions>
      </Content>
    </Modal>
  );
};
