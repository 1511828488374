import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useMemo, useState, PropsWithChildren } from 'react';

type Path = {
  href: string;
  as: string;
};

interface HValidation {
  history: Path[];
  setHistory(data: Path[]): void;
  back(): void;
}

const HistoryContext = createContext<HValidation>({} as HValidation);
export const HistoryProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { push, asPath, pathname } = useRouter();
  const [history, setHistory] = useState<Path[]>([]);
  const path = useMemo(
    () => ({
      href: pathname,
      as: asPath,
    }),
    [pathname, asPath]
  );

  function back() {
    for (let i = history.length - 2; i >= 0; i--) {
      const route = history[i];
      if (!route.href.includes('#') && route.href !== pathname) {
        push(route);

        // if you want to pop history on back
        const newHistory = history.slice(0, i);
        setHistory(newHistory);

        break;
      }
    }
  }

  useEffect(() => {
    setHistory(previous => [...previous, path]);
  }, [path]);

  return (
    <HistoryContext.Provider
      value={{
        back,
        history,
        setHistory,
      }}>
      {children}
    </HistoryContext.Provider>
  );
};

export function useHistory(): HValidation {
  const context = useContext(HistoryContext);
  return context;
}
