import { IconContainer } from './IconContainer';

export const IconCustomers = () => (
  <IconContainer bgColor="#062af3">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
      <path
        d="M 6.3 4.2 C 6.3 5.36 5.36 6.3 4.2 6.3 C 3.04 6.3 2.1 5.36 2.1 4.2 C 2.1 3.04 3.04 2.1 4.2 2.1 C 5.36 2.1 6.3 3.04 6.3 4.2 Z M 4.2 7.7 C 6.133 7.7 7.7 9.267 7.7 11.2 L 7.7 11.9 L 0.7 11.9 L 0.7 11.2 C 0.7 9.267 2.267 7.7 4.2 7.7 Z"
        fill="hsl(0, 0%, 100%)"
      />
      <path
        d="M 11.9 4.2 C 11.9 5.36 10.96 6.3 9.8 6.3 C 8.64 6.3 7.7 5.36 7.7 4.2 C 7.7 3.04 8.64 2.1 9.8 2.1 C 10.96 2.1 11.9 3.04 11.9 4.2 Z M 9.051 11.9 C 9.083 11.671 9.1 11.438 9.1 11.2 C 9.102 10.1 8.732 9.032 8.05 8.169 C 9.133 7.544 10.467 7.544 11.55 8.169 C 12.633 8.794 13.3 9.95 13.3 11.2 L 13.3 11.9 Z"
        fill="rgba(255, 255, 255, 0.54)"
      />
    </svg>
  </IconContainer>
);
