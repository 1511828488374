import { IconContainer } from './IconContainer';

export const IconLibMedia = () => (
  <IconContainer bgColor="#fe871a">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
      <path
        d="M 1.4 4.2 C 1.4 3.427 2.027 2.8 2.8 2.8 L 5.6 2.8 L 7 4.2 L 9.8 4.2 C 10.573 4.2 11.2 4.827 11.2 5.6 L 11.2 6.3 L 5.6 6.3 C 4.44 6.3 3.5 7.24 3.5 8.4 L 3.5 9.45 C 3.5 10.03 3.03 10.5 2.45 10.5 C 1.87 10.5 1.4 10.03 1.4 9.45 Z"
        fill="hsl(0, 0%, 100%)"
      />
      <path
        d="M 4.2 8.4 C 4.2 7.627 4.827 7 5.6 7 L 11.2 7 C 11.973 7 12.6 7.627 12.6 8.4 L 12.6 9.8 C 12.6 10.573 11.973 11.2 11.2 11.2 L 1.4 11.2 L 2.8 11.2 C 3.573 11.2 4.2 10.573 4.2 9.8 Z"
        fill="rgba(255, 255, 255, 0.54)"
      />
    </svg>
  </IconContainer>
);
