import styled from 'styled-components';

export const Save = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 18px;
`;

export const Item = styled.div<{ alignItems?: string; noBorder?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 24px;
  border-bottom: ${props => (props.noBorder ? 'none' : '1px solid #e4e4e9')};
  padding: 18px;

  &:last-child {
    border-bottom: none;
  }
`;

export const FieldTitle = styled.div`
  color: #010032;
  font-size: 16px;
  letter-spacing: 0em;
`;

export const FieldDescription = styled.div`
  color: rgba(1, 0, 50, 0.74);
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 1.4;
`;
