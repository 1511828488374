import { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ContentItemStatus } from 'types/typed-document-nodes';
import { AssistantConnectionListItem } from './ConnectionListItem';
import { Container } from './styles';
import { AssistantConnectionHeader } from './ConnectionHeader';
import { SideDrawerContext } from 'contexts/SideDrawerContext';
import { CONNECTION_BUTTON_CLASS_NAME } from './ConnectionsButton';
import { AssistantTag as AssistantTagBase } from 'components/tag/AssistantTag';

const AssistantTag = styled(AssistantTagBase)`
  span {
    padding-left: 6px;
    > svg {
      margin-right: 6px;
    }
  }
`;

type Props = {
  assistants:
    | {
        id: string;
        name: string;
        languages: {
          id: string;
        }[];
        metaInfo: {
          status: ContentItemStatus;
          reference: string;
        } | null;
      }[]
    | undefined;
  entityName: string;
};

export function AssistantConnectionsTag({ assistants, entityName }: Props) {
  const { openDrawer, updateContent, animationFinished } = useContext(SideDrawerContext);
  const [opened, setOpened] = useState(false);
  const sortedAssistants = useMemo(() => {
    return [...(assistants ?? [])].sort((a, b) => a.name.localeCompare(b.name));
  }, [assistants]);

  const content = useMemo(
    () => (
      <Container>
        <AssistantConnectionHeader
          entityName={entityName}
          animationFinished={animationFinished}
          total={sortedAssistants?.length}
        />
        {sortedAssistants.map(a => (
          <AssistantConnectionListItem
            key={a.id}
            id={a.id}
            name={a.name}
            status={a.metaInfo?.status}
            reference={a.metaInfo?.reference}
            languageId={a.languages[0]?.id}
          />
        ))}
      </Container>
    ),
    [sortedAssistants, entityName, animationFinished]
  );

  useEffect(() => {
    if (opened) {
      updateContent({ children: content });
    }
  }, [opened, updateContent, content]);

  useEffect(() => {
    if (!animationFinished) {
      setOpened(false);
    }
  }, [animationFinished]);

  return (
    <AssistantTag
      className={CONNECTION_BUTTON_CLASS_NAME}
      name={`${sortedAssistants?.length ?? 0}`}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        openDrawer({
          title: 'Assistants',
          children: content,
        });
        setOpened(true);
      }}
    />
  );
}
