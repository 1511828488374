import { SVGProps } from 'react';

const IconUpsellCustomFont = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" {...props}>
    <path
      d="M 2.8 1.4 C 2.027 1.4 1.4 2.027 1.4 2.8 L 1.4 10.5 C 1.4 11.66 2.34 12.6 3.5 12.6 C 4.66 12.6 5.6 11.66 5.6 10.5 L 5.6 2.8 C 5.6 2.027 4.973 1.4 4.2 1.4 Z M 3.5 11.2 C 3.887 11.2 4.2 10.887 4.2 10.5 C 4.2 10.113 3.887 9.8 3.5 9.8 C 3.113 9.8 2.8 10.113 2.8 10.5 C 2.8 10.887 3.113 11.2 3.5 11.2 Z M 7 9.97 L 10.43 6.54 C 10.977 5.993 10.977 5.107 10.43 4.56 L 9.439 3.57 C 8.893 3.023 8.007 3.023 7.46 3.57 L 7 4.03 Z M 11.2 12.6 L 6.35 12.6 L 10.55 8.4 L 11.2 8.4 C 11.973 8.4 12.6 9.027 12.6 9.8 L 12.6 11.2 C 12.6 11.973 11.973 12.6 11.2 12.6 Z"
      fill="hsl(0, 0%, 100%)"
    />
  </svg>
);

export default IconUpsellCustomFont;
