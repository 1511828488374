import React, { useEffect, useRef, useState } from 'react';
import { Portal } from '@layerise/design-core';
import { IconClose } from '@layerise/design-icon';
import { useOuterClick } from '@layerise/utils';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { useAnimationSettings } from './hooks/useAnimationSettings';
import { CONNECTION_BUTTON_CLASS_NAME } from './ConnectionsButton';
import { ContentItemType, ResponseType, getResponseType } from './ConnectionHeader';
import { getFirstParentMatch } from 'lib/utils';

const SideBarContainer = styled(motion.div)`
  position: fixed;
  right: 0;
  z-index: 1051;
  width: 304px;
  height: 100%;
  padding: 24px 16px;
  box-shadow: 0px 0px 0px 1px rgba(1, 0, 50, 0.1), 0px 2px 14px 4px rgba(1, 0, 50, 0.2);
  background-color: #f7f7fa;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 599;
  color: #140038;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: left;
`;

const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 1px 1px 0px rgba(0, 0, 0, 0.12),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 2px 5px 0px rgba(60, 66, 87, 0.08);
  background-color: #ffffff;
  overflow: visible;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
`;

const Content = styled.div`
  margin-top: 16px;
`;

interface ConnectionsSidebarProps {
  title?: string;
  open?: boolean;
  skipRefetchOnMount?: boolean;
  onClose?: () => void;
  children?: React.ReactElement;
  contentItemType: ContentItemType;
}

export const ConnectionsSidebar = ({
  title,
  open,
  skipRefetchOnMount,
  onClose,
  children,
  contentItemType,
}: ConnectionsSidebarProps) => {
  const [animationFinished, setAnimationFinished] = useState(false);
  const responseType = getResponseType(contentItemType);

  const filterRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const animationSettings = useAnimationSettings({
    open,
    expanded,
  });

  useEffect(() => {
    setExpanded(true);
  }, []);

  useOuterClick(filterRef, (e: React.MouseEvent & { target: Element }) => {
    if (
      e.target.classList.contains(CONNECTION_BUTTON_CLASS_NAME) ||
      getFirstParentMatch(e.target, `.${CONNECTION_BUTTON_CLASS_NAME}`)
    )
      return;

    setExpanded(false);
    onClose?.();
  });

  return (
    <Portal selector="#modal">
      <AnimatePresence onExitComplete={() => setAnimationFinished(false)}>
        {open && (
          <SideBarContainer
            ref={filterRef}
            {...animationSettings}
            onAnimationComplete={() => setAnimationFinished(true)}>
            <Top>
              <Title>{title ? title : responseType === ResponseType.products ? 'Products' : 'Connections'}</Title>
              <IconContainer onClick={onClose}>
                <IconClose width="10px" height="10px" />
              </IconContainer>
            </Top>
            {children && (
              <Content>{React.cloneElement(children, { onClose, animationFinished, skipRefetchOnMount })}</Content>
            )}
          </SideBarContainer>
        )}
      </AnimatePresence>
    </Portal>
  );
};
