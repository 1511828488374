import styled from 'styled-components';

export const Hero = styled.div`
  display: flex;
  padding-bottom: 24px;
  h2 {
    font-weight: 599;
    color: #010032;
    font-size: 28px;
    letter-spacing: 0px;
    line-height: 1;
    flex: 1;
    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const ImageWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  height: 32px;
  width: 32px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;

  img {
    height: 32px;
    width: 32px;
    object-fit: cover;
  }
`;

export const TableContainer = styled.div`
  width: 1105px;
  height: 544px;
  max-height: 100%;

  #tableContainer {
    height: 100%;
  }
`;

export const FooterWrapper = styled.div`
  flex: 1;
  display: flex;
  gap: 16px;
`;

export const FooterText = styled.span`
  padding-top: 4px;

  color: #72778c;
  font-weight: 499;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0px;
`;

export const CheckboxWrapper = styled.span`
  label {
    padding-left: 4px;
    display: block;
  }
  svg {
    visibility: initial;
  }
`;

export const ModalTitle = styled.h2`
  text-transform: lowercase;
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const SelectedItemsCount = styled.span`
  color: #72778c;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 30px;
`;

export const CheckboxMock = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  &::before {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
`;
