import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  background-color: ${props => props.bgColor};
  border-radius: 4px;
  font-size: 10px;
  font-weight: 700;
`;

export const IconContainer: React.FC<PropsWithChildren<{ bgColor: string }>> = ({ bgColor, children }) => {
  return <Container bgColor={bgColor}>{children}</Container>;
};
