import styled from 'styled-components';

export const ModalHero = styled.div<{ withBorder?: boolean }>`
  border-bottom: ${props => (props.withBorder ? '1px solid #ECECF3' : 'none')};
  position: relative;
  img {
    border-radius: 7px 7px 0px 0px;
  }
`;

export const UpdateTag = styled.span`
  font-weight: 700;
  color: #186a4c;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 1;
  padding: 8px 12px;
  position: absolute;
  left: 24px;
  top: 24px;
  background-color: #cbf4c9;
  border-radius: 80px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const Title = styled.h1`
  font-weight: 600;
  color: var(--color-N600);
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 24px;
`;

export const Body = styled.p`
  color: var(--color-N500);
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.4;
`;

export const Content = styled.div`
  padding: 24px;
`;

export const LearnMore = styled.a`
  font-weight: 500;
  color: #140068;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 18px;

  cursor: pointer;
  svg {
    width: 12px;
    height: 12px;
    margin-left: 6px;
    margin-top: -2px;
    path {
      fill: #140068;
    }
  }
`;

export const TitleIconContainer = styled.span<{ bgColor?: string }>`
  background: ${props => (props.bgColor ? props.bgColor : '#140068')};
  border-radius: 3px;
  padding: 4px;
  display: inline-flex;
  margin-right: 8px;
  height: 24px;
  align-items: center;
  width: 24px;
  justify-content: center;
  svg {
    path {
      fill: #fff;
    }
  }
`;
