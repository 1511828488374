import styled, { css } from 'styled-components';

export const Actions = styled.div<{ justify?: React.CSSProperties['justifySelf'] }>`
  ${props =>
    props.justify &&
    css`
      justify-self: ${props.justify};
    `}
  a {
    cursor: pointer;
  }
  > span {
    margin-right: 16px;
    display: inline-block;
  }
`;
