import * as React from 'react';
import { SVGProps } from 'react';

const IconUpsellWorkspaces = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      d="M1.6 10.954V12.8a1.6 1.6 0 0 0 1.6 1.6h9.6a1.6 1.6 0 0 0 1.6-1.6v-1.846A19.98 19.98 0 0 1 8 12a19.98 19.98 0 0 1-6.4-1.046ZM4.8 4.8V4a2.4 2.4 0 0 1 2.4-2.4h1.6A2.4 2.4 0 0 1 11.2 4v.8h1.6a1.6 1.6 0 0 1 1.6 1.6v2.856A18.37 18.37 0 0 1 8 10.4a18.37 18.37 0 0 1-6.4-1.144V6.4a1.6 1.6 0 0 1 1.6-1.6Zm1.6 0h3.2V4a.8.8 0 0 0-.8-.8H7.2a.8.8 0 0 0-.8.8Zm1.6 4h.008a.8.8 0 1 0 0-1.6H8a.8.8 0 1 0 0 1.6Z"
      fill="hsl(0, 0%, 100%)"
    />
  </svg>
);

export default IconUpsellWorkspaces;
