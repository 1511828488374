import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function useHubSpot(email?: string) {
  const { events } = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      window._hsq = window._hsq || [];
      window._hsq.push([
        'identify',
        {
          email: `'${email}'`,
        },
      ]);
      window._hsq.push(['trackPageView']);
    };

    events.on('routeChangeComplete', handleRouteChange);
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events, email]);
}
