import * as React from 'react';
import { SVGProps } from 'react';

const IconUpsellAPI = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12.8} height={11.2} {...props}>
    <path
      d="M0 1.6A1.6 1.6 0 0 1 1.6 0h9.6a1.6 1.6 0 0 1 1.6 1.6v8a1.6 1.6 0 0 1-1.6 1.6H1.6A1.6 1.6 0 0 1 0 9.6Zm7.2 5.6a.8.8 0 1 0 0 1.6h2.4a.8.8 0 1 0 0-1.6ZM2.634 3.766 4.469 5.6 2.634 7.434a.801.801 0 0 0 1.132 1.132l2.4-2.4a.802.802 0 0 0 0-1.132l-2.4-2.4a.802.802 0 0 0-1.132 1.132Z"
      fill="hsl(0, 0%, 100%)"
    />
  </svg>
);

export default IconUpsellAPI;
