import { Button, ModalActions, Modal, Img } from '@layerise/design-core';
import { IconInfo } from '@layerise/design-icon';
import { LearnMore, Content, Body, Title, UpdateTag, ModalHero } from './SharedStyles';
import { useMutation } from '@apollo/client';
import { SetMemberOnboardingDocument } from 'types/typed-document-nodes';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { useContext } from 'react';
import { NORMAL_MODAL_Z_INDEX, FeatureModalProps } from './constants';

export const AssistantUpdate = (props: FeatureModalProps) => {
  const [setOnboarding] = useMutation(SetMemberOnboardingDocument);

  const { refetch } = useContext(OrganisationContext);

  const handleClick = async () => {
    props.onRequestClose();
    await setOnboarding({
      variables: {
        discoverAssistant: true,
      },
    });
    await refetch();
  };

  return (
    <Modal
      onClick={props.onRequestClose}
      modalContainerStyle={{ zIndex: NORMAL_MODAL_Z_INDEX + 1 }}
      containerStyle={{ zIndex: NORMAL_MODAL_Z_INDEX + 2 }}
      padding="0px 0px 0px 0px"
      height="610px"
      width="520px">
      <ModalHero>
        <UpdateTag>UPDATE</UpdateTag>
        <Img alt="assistants" src="WWW/productUpdates/assistants" height={776} width={1040} quality={80} />
      </ModalHero>
      <Content>
        <Title>You only need one Assistant</Title>
        <Body>
          An Assistant defines the experience your customers will get, and we have made it easy for you to build an
          Assistant for each product, or use one Assistant for many products. Add your content directly into the
          Assistant, or connect content to any Assistant.
        </Body>
        <ModalActions
          negative={
            <LearnMore
              href={`${process.env.URL_WWW}/help/assistant/creating-an-assistant`}
              target="_blank"
              rel="noreferrer">
              Learn more <IconInfo />
            </LearnMore>
          }>
          <Button size="lg" variant="primary" onClick={handleClick}>
            Close
          </Button>
        </ModalActions>
      </Content>
    </Modal>
  );
};
