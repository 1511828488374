import { forwardRef } from 'react';
import { IconDocuments } from 'components/icons/IconDocuments';
import { EntityTag, Props } from './EntityTag';

export const AssistantTag = forwardRef<HTMLDivElement, Pick<Props, 'name' | 'className' | 'onClick'>>(
  function AssistantTag({ name, className, onClick }, ref) {
    return (
      <EntityTag
        ref={ref}
        onClick={onClick}
        className={className}
        icon={<IconDocuments />}
        bgColor="#fe871a"
        name={name}
      />
    );
  }
);
