import React, { createContext, useCallback, useContext, useEffect, PropsWithChildren } from 'react';
import { OrganisationContext } from './OrganisationContext';
import { isBrowser, isPowerUser } from 'lib/utils';
import { AppSection } from 'types/sections';
import { useApolloClient, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { configureConversationWidget } from 'lib/hubspot';
import { MemberType, Role, SignOutDocument } from 'types/typed-document-nodes';
import { throw404 } from '../lib/errors';
import { hotjar } from 'react-hotjar';
import { useRouter } from 'next/router';
import { BackupCodesAwareness } from 'components/auth/BackupCodesAwareness';

interface IMemberContext {
  isSuperAdmin: boolean;
  isPowerUser: boolean;
  canView(section: AppSection): boolean;
  canContribute(section: AppSection): boolean;
  actions: {
    signOut(): Promise<void>;
  };
}

export const MemberContext = createContext<IMemberContext>(undefined!);

export const MemberProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { me, workspace, permissions } = useContext(OrganisationContext);
  const router = useRouter();
  const apollo = useApolloClient();
  const [signoutMutation] = useMutation(SignOutDocument);

  const signOut = async () => {
    if (isBrowser()) {
      await signoutMutation();
      await apollo.clearStore();
      router.push('/auth');
    }
  };

  useEffect(() => {
    if (me?.deleted) {
      signOut();
    }
  }, [me]);

  useEffect(() => {
    if (!isBrowser()) return;

    Sentry.setUser({ email: me.email, username: me.id });
    if (process.env.HOTJAR_SITE_ID) {
      hotjar.initialize(parseInt(process.env.HOTJAR_SITE_ID), 6);
      if (hotjar.initialized()) {
        hotjar.identify(me.id, { email: me.email, workspace: workspace.slug });
      }
    }
    configureConversationWidget({
      token: me.hubspotToken,
      email: me.email,
    });
  }, [me.email, me.id, me.hubspotToken, workspace.slug, process.env.HOTJAR_SITE_ID]);

  const hasAdminRole = isPowerUser(me.role);
  const isStandardMember = me.type === MemberType.Standard;
  const workspacePermissions = permissions.find(p => p.workspaceId === workspace.id);

  const getAccessCheckerByType = useCallback(
    (accessType: 'read' | 'contribute') => {
      return (section: AppSection): boolean => {
        if (section === AppSection.ORGANISATION_SETTINGS) {
          return hasAdminRole;
        }
        if (section === AppSection.PRODUCTS || section === AppSection.INSIGHTS || section === AppSection.FEEDBACKS) {
          return hasAdminRole || isStandardMember;
        }
        return Boolean(hasAdminRole || workspacePermissions?.permissions?.[section]?.[accessType]);
      };
    },
    [hasAdminRole, isStandardMember, workspacePermissions]
  );

  const canView = getAccessCheckerByType('read');
  const canContribute = getAccessCheckerByType('contribute');

  if (me?.deleted || !workspacePermissions) {
    return throw404();
  }

  return (
    <MemberContext.Provider
      value={{
        isSuperAdmin: me.role === Role.SuperAdmin,
        isPowerUser: hasAdminRole,
        canView,
        canContribute,
        actions: {
          signOut,
        },
      }}>
      <BackupCodesAwareness />
      {children}
    </MemberContext.Provider>
  );
};

export default MemberProvider;
