import styled from 'styled-components';
import { Button, Card, Toggle, useForm, Tag, ModalRoot, ModalProvider, ModalContext } from '@layerise/design-core';
import { Heading, Title } from 'components/settings/elements';
import { Save, Item, FieldTitle, FieldDescription } from '../elements';
import {
  CurrentMemberPhoneDocument,
  EditMemberFragmentFragment,
  ResetTwoFactorAuthDocument,
  UpdateMemberDocument,
} from 'types/typed-document-nodes';
import { MemberPhoneInfo } from 'components/settings/MemberPhoneInfo';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { useApiErrorHandler } from 'hooks/useApiErrorHandler';
import { useContext, useEffect } from 'react';
import ToastContext from 'contexts/ToastContext';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { BackupCodesModal } from 'components/auth/BackupCodesModal';
import { NORMAL_MODAL_Z_INDEX } from 'components/featureModals/constants';
import { PhoneVerificationModal } from 'components/account/PhoneVerificationModal';

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  & > a {
    cursor: pointer;
  }
`;

const TagWrapper = styled.div`
  display: inline-block;
  margin-left: 8px;
`;

const ResetAction = styled.span.attrs({ role: 'button' })`
  color: #ff3333;
  font-size: 14px;
  letter-spacing: 0;
  cursor: pointer;
`;

type Props = {
  member: EditMemberFragmentFragment;
  readonly: boolean;
  refetch(): Promise<ApolloQueryResult<unknown>[]> | Promise<ApolloQueryResult<unknown>>;
};

export function SecurityView({ member, readonly, refetch }: Props) {
  const { me } = useContext(OrganisationContext);
  const isCurrentMember = member.id === me.id;
  const phoneInfoQuery = useQuery(CurrentMemberPhoneDocument, { skip: !isCurrentMember });
  const countryCode = phoneInfoQuery.data?.myPhoneInfo?.countryCode || '';
  const nationalPhone = phoneInfoQuery.data?.myPhoneInfo?.nationalPhone || '';

  const { setToast } = useContext(ToastContext);
  const { inputs, setInputs, handleChange } = useForm({
    phone: member.phone,
    twoFactorAuthEnabled: !!member.twoFactorAuthEnabled,
  });

  const handleError = useApiErrorHandler();
  const [reset2fa, reset2faMutationResult] = useMutation(ResetTwoFactorAuthDocument, {
    variables: { id: member.id },
    refetchQueries: [CurrentMemberPhoneDocument],
  });
  const [updateMember, updateMemberMutationResult] = useMutation(UpdateMemberDocument, {
    variables: {
      id: member.id,
      twoFactorAuthEnabled: inputs.twoFactorAuthEnabled,
    },
    refetchQueries: [CurrentMemberPhoneDocument],
  });

  const loading = reset2faMutationResult.loading || updateMemberMutationResult.loading;

  useEffect(() => {
    setInputs({
      phone: member.phone,
      twoFactorAuthEnabled: !!member.twoFactorAuthEnabled,
    });
  }, [member]);

  const recoveryData = phoneInfoQuery.data?.recoveryData;
  const isChanged = inputs.phone !== member.phone || inputs.twoFactorAuthEnabled !== member.twoFactorAuthEnabled;

  return (
    <section>
      <Heading>Security</Heading>
      <Title>Two-factor authentication</Title>
      <Card none>
        <form
          onSubmit={async e => {
            e.preventDefault();
            let isError = false;
            try {
              await updateMember();

              if (member.phone && !inputs.phone) {
                await reset2fa();
              }
            } catch (e: any) {
              isError = true;
              handleError(e);
            }

            if (!isError) {
              setToast({
                type: 'success',
                message: 'Member security preferences successfully updated',
              });
              refetch();
            }
          }}>
          <Item>
            <div>
              <FieldTitle>Phone number</FieldTitle>
              <FieldDescription>Phone number is used to verify member&rsquo;s identity</FieldDescription>
            </div>
            <ModalProvider>
              <ModalRoot />
              <ModalContext.Consumer>
                {({ showModal }) => {
                  let openModalLabel;
                  if (!member.phone) {
                    openModalLabel = 'Add';
                  } else if (!member.phoneVerified) {
                    openModalLabel = 'Verify';
                  } else {
                    openModalLabel = 'Change';
                  }
                  return (
                    <Actions>
                      {inputs.phone ? (
                        <>
                          <MemberPhoneInfo member={member} />
                          {isCurrentMember ? (
                            <a
                              onClick={() => {
                                showModal(PhoneVerificationModal, {
                                  countryCode,
                                  nationalPhone,
                                  zIndex: NORMAL_MODAL_Z_INDEX + 1,
                                  onValidated: () => {
                                    refetch();
                                    phoneInfoQuery.refetch();
                                  },
                                });
                              }}>
                              {openModalLabel}
                            </a>
                          ) : (
                            <ResetAction
                              onClick={() => {
                                setInputs({ phone: null });
                              }}>
                              Reset
                            </ResetAction>
                          )}
                        </>
                      ) : null}
                    </Actions>
                  );
                }}
              </ModalContext.Consumer>
            </ModalProvider>
          </Item>
          <Item>
            <div>
              <div>
                <FieldTitle>
                  Two-factor authentication{' '}
                  <TagWrapper>
                    {member.phone && member.twoFactorAuthEnabled && <Tag variant="success">Enabled</Tag>}
                    {!member.phone && <Tag variant="pending">Pending</Tag>}
                  </TagWrapper>
                </FieldTitle>
              </div>
              <FieldDescription>For security reasons 2FA cannot be disabled</FieldDescription>
            </div>
            <Actions>
              <Toggle
                value={!!inputs.twoFactorAuthEnabled}
                onChange={handleChange}
                name="twoFactorAuthEnabled"
                locked={!!member.twoFactorAuthEnabled}
                disabled={!!member.twoFactorAuthEnabled}
              />
            </Actions>
          </Item>
          {isCurrentMember && (
            <Item>
              <div>
                <FieldTitle>Backup codes for 2FA</FieldTitle>
                <FieldDescription>
                  Save this code to recover your account if you lose access to your 2FA device.
                </FieldDescription>
              </div>
              <ModalProvider>
                <ModalRoot />
                <ModalContext.Consumer>
                  {({ showModal }) => (
                    <Actions>
                      <a
                        style={{ opacity: recoveryData?.codes.length ? 1 : 0.5 }}
                        onClick={() => {
                          if (!recoveryData) return;
                          showModal(BackupCodesModal, { recoveryData, usage: true, zIndex: NORMAL_MODAL_Z_INDEX + 1 });
                        }}>
                        View codes
                      </a>
                    </Actions>
                  )}
                </ModalContext.Consumer>
              </ModalProvider>
            </Item>
          )}
          <Save>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              loading={loading}
              restricted={readonly}
              disabled={!isChanged || loading}>
              {loading ? 'Saving...' : 'Save changes'}
            </Button>
          </Save>
        </form>
      </Card>
    </section>
  );
}
