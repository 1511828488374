import { formatBytes } from '@layerise/utils';
import { format } from 'date-fns';
import { getFullName } from 'lib/utils';
import { useMemo } from 'react';
import { TransactionLogType } from 'types/typed-document-nodes';

type ActorData = {
  id: string | null;
  label: string;
  imgSrc: string | null;
};

type TransactionLog = {
  type: TransactionLogType;
  createdAt: string;
  member: {
    id: string;
    firstName: string | null;
    lastName: string | null;
    avatarImg: {
      dynamic: string | null;
    } | null;
  } | null;
};

type ItemMetaData = {
  createdAt?: string;
  size?: number | null;
  createdLogItems: TransactionLog[];
  updatedLogItems: TransactionLog[];
};

export const useGetItemMetaData = (entity?: ItemMetaData | null) => {
  const createdTransaction = useMemo(() => {
    return entity?.createdLogItems.find(i => i.type === TransactionLogType.Created);
  }, [entity?.createdLogItems]);

  const updatedTransaction = useMemo(() => {
    return entity?.updatedLogItems.find(i => i.type === TransactionLogType.Updated);
  }, [entity?.updatedLogItems]);

  const createdBy = useMemo<ActorData>(() => {
    if (createdTransaction?.member) {
      return {
        id: createdTransaction.member.id,
        imgSrc: createdTransaction.member.avatarImg?.dynamic || null,
        label: getFullName(createdTransaction.member, { short: true }),
      };
    }
    return { id: null, imgSrc: null, label: 'Unknown' };
  }, [createdTransaction]);

  const updatedBy = useMemo<ActorData | null>(() => {
    if (updatedTransaction?.member) {
      return {
        id: updatedTransaction.member.id,
        imgSrc: updatedTransaction.member.avatarImg?.dynamic || null,
        label: getFullName(updatedTransaction.member, { short: true }),
      };
    }
    return null;
  }, [updatedTransaction]);

  const updatedAt = useMemo(() => {
    if (!updatedTransaction) {
      return '';
    }
    return format(new Date(updatedTransaction.createdAt), 'dd/MM/yyyy');
  }, [updatedTransaction]);

  const createdAt = useMemo(() => {
    const date = createdTransaction?.createdAt || entity?.createdAt;
    if (!date) {
      return '';
    }
    return format(new Date(date), 'dd/MM/yyyy');
  }, [createdTransaction, entity?.createdAt]);

  const size = useMemo(() => {
    if (!entity) {
      return '';
    }
    if ('size' in entity && entity?.size) {
      return formatBytes(entity.size);
    }
    return '';
  }, [entity]);

  return { createdBy, updatedBy, createdAt, updatedAt, size };
};
