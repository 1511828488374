import React, { useRef, memo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { OrganisationProvider } from 'contexts/OrganisationContext';
import { ToastProvider } from 'contexts/ToastContext';
import { useApollo } from 'lib/apollo/client';
import { Global, ModalProvider, ModalRoot, Scrollbar } from '@layerise/design-core';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useRouter } from 'next/router';
import { HistoryProvider } from 'contexts/History';
import { ChatProvider } from '../components/chat/context';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import type { NextPage } from 'next';
import { MemberProvider } from 'contexts/MemberContext';
import { AppPropsWithLayout } from 'types/app';
import { ApolloProvider } from '@apollo/client';
import { FeatureFlagProvider } from '../contexts/FeatureFlagContext';
import { SideDrawerProvider } from 'contexts/SideDrawerContext';
import { NotificationProcessor } from 'components/NotificationProcessor';

const stripePromise = loadStripe(process.env.STRIPE_API_KEY as string);
const LazyMotion = dynamic(() => import('components/LazyMotion').then(m => m.default));
const PAGES_TO_CACHE = ['/w/[id]/library/assistants/[assistantId]/[locale]'];

const HubspotChatStyles = createGlobalStyle`
  html:not(.hs-messages-widget-open) #hubspot-messages-iframe-container {
    display: none !important;
    .hs-shadow-container {
      display: none !important;
    }
  }
`;

type CachedPageRef = {
  pathname: string;
  component: React.ReactNode;
};

const MyApp: NextPage<AppPropsWithLayout> = props => {
  const apolloClient = useApollo(props);
  const router = useRouter();

  const cachedPages = useRef<Record<string, CachedPageRef>>({});
  const { Component, pageProps } = props;
  const getLayout = Component.getLayout ?? (page => page);
  const modifiedPageProps = { ...pageProps };

  const shouldCachePage = PAGES_TO_CACHE.some(path => path === router.pathname);
  const asPathNoQuery = router.asPath.split('?')[0];
  if (shouldCachePage && !cachedPages.current[asPathNoQuery]) {
    const samePathnameCachedPage = Object.entries(cachedPages.current).find(([, c]) => c.pathname === router.pathname);
    if (samePathnameCachedPage) {
      delete cachedPages.current[samePathnameCachedPage[0]];
    }

    const MemoComponent = memo(Component);
    cachedPages.current[asPathNoQuery] = {
      pathname: router.pathname,
      component: getLayout(<MemoComponent {...modifiedPageProps} />),
    };
  }

  cachedPages.current = Object.entries(cachedPages.current).reduce((memo, [path, cache]) => {
    if (router.pathname.includes(cache.pathname)) {
      memo[path] = cache;
      return memo;
    }
    return memo;
  }, {} as Record<string, CachedPageRef>);

  const isAuthPage = router.pathname.startsWith('/auth');

  return (
    <ApolloProvider client={apolloClient}>
      <HubspotChatStyles />
      <LazyMotion>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        {isAuthPage && (
          <ToastProvider>
            <HistoryProvider>
              <Global />
              <div id="popover" />
              <div id="modal" />
              <div id="toast" />
              <Component {...pageProps} />
            </HistoryProvider>
          </ToastProvider>
        )}
        {!isAuthPage && (
          <ModalProvider>
            <OrganisationProvider>
              <FeatureFlagProvider>
                <ToastProvider>
                  <MemberProvider>
                    <ChatProvider>
                      <HistoryProvider>
                        <Elements stripe={stripePromise}>
                          <SideDrawerProvider>
                            <Global />
                            <Scrollbar />
                            <ModalRoot />
                            <div id="modal">
                              <div id="popover" />
                            </div>
                            <div id="guide" />
                            <div id="toast" />

                            <div style={{ display: shouldCachePage ? 'block' : 'none' }}>
                              {Object.entries(cachedPages.current).map(([path, { component }]) => (
                                <div key={path} style={{ display: asPathNoQuery === path ? 'block' : 'none' }}>
                                  {component}
                                </div>
                              ))}
                            </div>
                            {!shouldCachePage && getLayout(<Component {...modifiedPageProps} />)}
                            <NotificationProcessor />
                          </SideDrawerProvider>
                        </Elements>
                      </HistoryProvider>
                    </ChatProvider>
                  </MemberProvider>
                </ToastProvider>
              </FeatureFlagProvider>
            </OrganisationProvider>
          </ModalProvider>
        )}
      </LazyMotion>
    </ApolloProvider>
  );
};

export default MyApp;
