import { MultiSelect } from '@layerise/design-core';
import { ChangeEvent, ChangeEventHandler, useCallback, useMemo, useState } from 'react';

interface IFilterMultiSelect {
  name: string;
  placeholder?: string;
  options: {
    id: string;
    value: string;
    label: string;
  }[];
  value: string[];
  disabled: boolean;
  searchQuery?: string;
  onSearchChange?: (e: any) => void;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const FilterMultiSelect = ({
  name,
  placeholder,
  options,
  value,
  disabled,
  searchQuery,
  onChange,
  onSearchChange,
}: IFilterMultiSelect) => {
  const [localSearchQuery, setLocalSearchQuery] = useState('');

  const searchResult = useMemo(() => {
    const searchQueryString = searchQuery ? searchQuery : localSearchQuery;
    const filteredOptions = !searchQueryString.length
      ? options
      : options.map(t => ({ ...t, hidden: !t.label.toLowerCase().includes(searchQueryString.toLowerCase()) }));
    return filteredOptions.map(i => (i.label.length > 20 ? { ...i, label: `${i.label.substring(0, 20)}...` } : i));
  }, [searchQuery, localSearchQuery, options]);

  const setSearchTerm = useCallback<ChangeEventHandler<HTMLInputElement>>(e => setLocalSearchQuery(e.target.value), []);
  return (
    <MultiSelect
      name={name}
      placeholder={placeholder}
      options={searchResult}
      size="sm"
      width={218}
      value={value}
      searchQuery={searchQuery ? searchQuery : localSearchQuery}
      onSearchChange={onSearchChange ? onSearchChange : setSearchTerm}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default FilterMultiSelect;
